import useMediaQuery from "../hooks/useMediaQuery";

export default function Table({
    itemName,
    rows,
    bp,
    className = "",
    fullWidth,
    firstColWidth,
    lastRow,
    ...rest
}) {
    const bp640px = useMediaQuery(640);

    const header = rows[0];
    const rowsWithoutHeader = rows.filter((r, i) => i > 0);

    const smRows = [];

    rowsWithoutHeader
        .filter((row, i) => lastRow ? i < rowsWithoutHeader.length - 1 : true)
        .forEach((row, i) => {
            smRows.push([`${i + 1}. ${itemName}`]);
            row.forEach((td, j) => {
                smRows.push([header[j], td]);
            });
        });

    return (
        <table className={`border border-black border-opacity-10 mx-auto ${className} ${fullWidth ? "w-full" : ""}`} {...rest}>
            <tbody>
                {(bp !== undefined ? bp : bp640px)
                    ?
                    <>
                        <tr className="shadow-lg bg-white">
                            {header.map((th, i) => {
                                return (
                                    <th key={i.toString()} className={"text-left px-2 py-1 align-top"} style={{ width: firstColWidth || null }}>
                                        {th}
                                    </th>
                                );
                            })}
                        </tr>
                        {rowsWithoutHeader.map((row, i) => {
                            return (
                                <tr key={i.toString()} className={i % 2 === 0 ? "bg-white bg-opacity-50" : "bg-black bg-opacity-5"}>
                                    {row.map((td, j) => {
                                        return <td key={j.toString()} className="px-2 py-1 align-top">{td}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </>
                    :
                    <>
                        {smRows.map((row, i) => {
                            return (
                                <tr
                                    key={i.toString()}
                                    className={Math.floor(i / (header.length + 1)) % 2 === 1 ? "bg-white bg-opacity-50" : "bg-black bg-opacity-5"}
                                >
                                    {row.map((td, j) => {
                                        return (
                                            <td
                                                key={j.toString()}
                                                colSpan={i % (header.length + 1) === 0 ? header.length : 1}
                                                className={`${i % (header.length + 1) === 0 ? "shadow-lg bg-white" : ""} px-2 py-1 align-top`}
                                            >
                                                {j === 0
                                                    ? <strong>{td}</strong>
                                                    : td}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                        {lastRow &&
                            <tr>
                                <td
                                    colSpan={header.length}
                                    className={"shadow-lg bg-white px-2 py-1"}
                                >
                                    <strong>{lastRow}</strong>
                                </td>
                            </tr>
                        }
                    </>
                }
            </tbody>
        </table>
    )
}