import Container from "./Container";
import polygon from "../assets/images/polygon.svg";
import opensea from "../assets/images/opensea.svg";
import dexscreener from "../assets/images/dexscreener.svg";
import quickswap from "../assets/images/quickswap.svg";
import useMediaQuery from "../hooks/useMediaQuery";

const Links = () => {
  const buttons = [
    {
      href: "https://polygonscan.com/address/0x3b43Bf4862D3F65bF696B4FfF05cFb8D46A102a1#writeContract",
      icon: polygon,
      text: "Staking contract",
    },
    {
      href: "https://opensea.io/collection/fastfoodwolfgame",
      icon: opensea,
      text: "Animals",
    },
    {
      href: "https://opensea.io/collection/fast-food-wolf-game-restaurants-ffwg",
      icon: opensea,
      text: "Restaurants",
    },
    {
      href: "https://quickswap.exchange/#/swap?outputCurrency=0x10b1c123183e191e8e2d5b209323de51c655e384",
      icon: quickswap,
      text: "FFWOOL/MATIC",
    },
    {
      href: "https://quickswap.exchange/#/swap?inputCurrency=0x10b1c123183e191e8e2d5b209323de51c655e384&outputCurrency=0x7675fcbf37d060C02781c7dcAC975e44C6CD3054",
      icon: quickswap,
      text: "FASTFOOD/FFWOOL",
    },
    /* {
      href: "https://quickswap.exchange/#/swap?outputCurrency=0x7911ded220490ab4ddd653698d4b06105a2b9a90",
      icon: quickswap,
      text: "FASTFOOD/MATIC",
      disabled: true,
    }, */
    {
      href: "https://dexscreener.com/polygon/0x4ce218db7a6f6be0f8e6467712b0184c53c91fea",
      icon: dexscreener,
      text: "FFWOOL/MATIC",
    },
    {
      href: "https://dexscreener.com/polygon/0x7911ded220490ab4ddd653698d4b06105a2b9a90",
      icon: dexscreener,
      text: "FASTFOOD/FFWOOL",
    },
    /* {
      href: "https://dexscreener.com/polygon/0xd216184b1ba1877f87366c3da5dc981adead3fa5",
      icon: dexscreener,
      text: "FASTFOOD/MATIC",
      disabled: true,
    }, */
  ];

  const bp640px = useMediaQuery(640);

  return (
    <Container>
      <div className="flex flex-col items-center font-pixel gap-4 text-center">
        <div className="text-xl sm:text-3xl font-bold uppercase text-center">Ecosystem</div>
        {[[0, 1, 2], [3, 4], [5, 6]].map((row, i) => {
          return (
            <div key={i.toString()} className="text-center">
              {row.map(index => {
                const button = buttons[index];
                return (
                  <a
                    key={index.toString()}
                    className="btn-frame btn-small"
                    style={{ cursor: button.disabled ? "not-allowed" : null }}
                    href={!button.disabled ? button.href : undefined}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex justify-center items-center">
                      <div className={`overflow-hidden rounded-full flex justify-center items-center w-fit h-fit mr-1`}>
                        <img src={button.icon} alt="" width={bp640px ? 25 : 16.23} height={bp640px ? 25 : 16.23} />
                      </div>
                      <span className="text-sm sm:text-base">{button.text}</span>
                    </div>
                  </a>
                )
              })}
            </div>
          )
        })}
      </div>
    </Container>
  );
};

export default Links;
