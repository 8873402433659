import { switchToMainnet } from "../utils/ethereum"

const MainnetConnect = () => {
  return (
    <div 
      className="cursor-pointer" 
      onClick={switchToMainnet}
    >
      <div className="btn-frame btn-small">
        Switch to Polygon
      </div>
    </div>
  )
}

export default MainnetConnect
