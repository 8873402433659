import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import BARN_ABI from "./abi/barn.abi";
import BARN_ABI_OLD from "./abi/barn.abi_old";

function getContract(abi = BARN_ABI, address) {
  const provider = _getProvider();
  if (!provider) throw new Error("Unable to connect to wallet");

  const signer = provider.getSigner();
  return new Contract(address ? address : process.env.REACT_APP_BARN_V3, abi, signer);
}

const getGasLimit = (gasEstimate) => {
  return gasEstimate.mul(BigNumber.from(14)).div(BigNumber.from(10));
}

const getGasPrice = async () => {
  const provider = _getProvider();
  if (!provider) throw new Error("Unable to connect to wallet");
  return Math.round(await provider.getGasPrice() * 1.25);
}

export const isPaused = async () => {
  const contract = getContract(BARN_ABI, process.env.REACT_APP_STAKING);
  return await contract.paused();
}

export const stake = async (tokenIds) => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.addManyToBarnAndPack(tokenIds).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.addManyToBarnAndPack(tokenIds, {
    gasLimit: getGasLimit(gasEstimate),
    gasPrice: await getGasPrice()
  });
};

// original claim function when no rescue is needed
export const claim = async (tokenIds, unstake) => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.claimManyFromBarnAndPack(tokenIds, unstake).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.claimManyFromBarnAndPack(tokenIds, unstake, {
    gasLimit: getGasLimit(gasEstimate),
    gasPrice: await getGasPrice()
  });
};

export const stakeManyRestaurants = async (tokenIds) => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.addManyRestaurants(tokenIds).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.addManyRestaurants(tokenIds, {
    gasLimit: getGasLimit(gasEstimate),
    gasPrice: await getGasPrice()
  });
}

export const unstakeManyRestaurants = async (tokenIds) => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.claimManyRestaurants(tokenIds, true).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.claimManyRestaurants(
    tokenIds,
    true,
    {
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice()
    }
  );
}

export const claimFastfood = async () => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.claimFastfood().then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.claimFastfood({
    gasLimit: getGasLimit(gasEstimate),
    gasPrice: await getGasPrice()
  });
}

export const feed = async (amount) => {
  const contract = getContract();
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.addFastfoodBalance(amount).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.addFastfoodBalance(
    amount,
    {
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice()
    });
}

export const rescue = async (tokenIds) => {
  const contract = getContract(await isPaused() ? BARN_ABI : BARN_ABI_OLD, process.env.REACT_APP_STAKING);
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.rescue(tokenIds).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.rescue(tokenIds, {
    gasLimit: getGasLimit(gasEstimate),
    gasPrice: await getGasPrice()
  });
};

export const rescueRest = async (tokenIds) => {
  const contract = getContract(BARN_ABI, process.env.REACT_APP_STAKING);
  var gasEstimate;
  var estimateError;
  await contract.estimateGas.rescueRest(tokenIds).then(result => gasEstimate = result, error => estimateError = error);
  if (estimateError) return estimateError;
  return await contract.rescueRest(
    tokenIds,
    {
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice()
    });
}

export const getClaimableBarnFfwool = async (wallet) => {
  const contract = getContract();
  return await contract.getClaimableBarnFfwool(wallet);
}

export const getClaimablePackFfwool = async (wallet) => {
  const contract = getContract();
  return await contract.getClaimablePackFfwool(wallet);
}

export const getClaimableFastfood = async (wallet) => {
  const contract = getContract();
  return await contract.getClaimableFastfood(wallet);
}

export const getFastfoodBalance = async (wallet) => {
  const contract = getContract();
  return await contract.getFastfoodBalance(wallet);
}

export const getFastfoodBalanceEnoughUntil = async (wallet) => {
  const contract = getContract();
  return await contract.getFastfoodBalanceEnoughUntil(wallet);
}

export const parseClaims = (receipt) => {
  const barn = new utils.Interface(BARN_ABI);
  const claims = [];
  receipt.logs.forEach((log) => {
    try {
      const args = barn.parseLog(log).args;
      if (args.tokenId) claims.push(args);
    } catch (error) { }
  });
  return claims;
};
