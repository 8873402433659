import { useEffect, useState } from "react";
import classes from "../design/MintProgress.module.scss";
import useMediaQuery from "../hooks/useMediaQuery";

const MintProgress = ({ minted, maxTokens }) => {

  const bp640px = useMediaQuery(640);
  const bp1024px = useMediaQuery(1024);
  const bp1280px = useMediaQuery(1280);

  const percent = Math.round(minted / maxTokens * 100);
  let barStyle =
    bp640px && !(bp1024px && !bp1280px)
      ? { width: percent > 100 ? "100%" : percent + "%", height: "100%" }
      : { width: "100%", height: percent > 100 ? "100%" : percent + "%" }

  if (minted < maxTokens / 5) {
    barStyle =
      bp640px && !(bp1024px && !bp1280px)
        ? { width: Math.round(minted / (maxTokens / 5) * 100) + "%", height: "100%" }
        : { width: "100%", height: Math.round(minted / (maxTokens / 5) * 100) + "%" }
  }

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s + (s === 1 ? " second" : " seconds");
    return hDisplay + mDisplay + sDisplay;
  }

  // handling of countdown
  const [countdown, setCountdown] = useState({});
  useEffect(() => {
    if (process.env.REACT_APP_GENZERO_COUNTDOWN_MODE === "true") {
      const interval = setInterval(() => {
        const start = 1638997200; // Dec 8 2021, 21:00 UTC
        const stop = 1639530000; // Dec 15 2021, 01:00 UTC
        let now = Math.round(new Date().getTime() / 1000);
        let seconds = stop - now;
        let progress = ((now - start) / (stop - start) * 100).toFixed(2);
        if (progress > 100) progress = 100;
        let barStyle =
          bp640px && !(bp1024px && !bp1280px)
            ? { width: progress + "%", height: "100%" }
            : { width: "100%", height: progress + "%" };
        setCountdown({ seconds: secondsToHms(seconds), progress: progress, barStyle: barStyle })
        if (progress >= 100) {
          clearInterval(interval);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <>
      {process.env.REACT_APP_GENZERO_COUNTDOWN_MODE === "true" ? (
        <>
          {countdown.progress >= 100 ? (
            <>
              <div className={classes.mint + " text-center"}>Countdown for Gen 0 mint finished</div>
              <div className={classes.mint + " text-center"}><strong>Gen 1 mint launching soon!</strong></div>
            </>
          ) : (
            <>
              <div className={classes.mint + " text-center"}><strong>Gen 0 minting expires soon!</strong></div>
              <div className={classes.bar}>
                <div className={classes.indicator} style={countdown.barStyle}></div>
                <div className={`${classes.gen} ${classes.gen0}`} style={{ width: "100%" }}>{countdown.seconds}</div>
              </div>
              <div className={classes.mint + " text-center"}>Only Gen 0 mints can claim a free restaurant.</div>
            </>
          )}
        </>
      ) : (
        <>
          {minted < maxTokens / 5 ?
            <>
              <div className={classes.bar}>
                <div className={classes.indicator} style={barStyle}></div>
                <div className={`${classes.gen} ${classes.gen0}`} style={{ width: "100%" }}>GEN 0</div>
              </div>
              {!isNaN(minted) && !isNaN(maxTokens) && <div className={classes.mint + " text-center"}>{minted} / {maxTokens / 5} minted</div>}
            </>
            :
            <>
              <div className={classes.bar}>
                <div className={classes.indicator} style={barStyle}></div>
                <p className={`${classes.gen} ${classes.gen0}`}>GEN 0</p>
                <p className={`${classes.gen} ${classes.born1}`}>20,000 $FFWOOL</p>
                <p className={`${classes.gen} ${classes.born2}`}>30,000 $FFWOOL</p>
                <p className={`${classes.gen} ${classes.born3}`}>40,000 $FFWOOL</p>
                <p className={`${classes.gen} ${classes.born4}`}>50,000 $FFWOOL</p>
              </div>
              {!isNaN(minted) && !isNaN(maxTokens) && <div className={classes.mint + " text-center"}>{minted} / {maxTokens} minted</div>}
            </>
          }
        </>
      )}
    </>
  );
};

export default MintProgress;
