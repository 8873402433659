const Frame = ({className, children}) => {
    return (
        <>
            <div className={"frame" + (undefined === className ? "" : " " + className)}>
                <div className="mask"></div>
                { children }
            </div>
        </>
    );
}

  export default Frame;