import Header from '../design/Header';
import Frame from '../design/Frame';
import ImagePhaseOne from '../assets/images/ffwg_p1.png';
import ImagePhaseTwo from '../assets/images/ffwg_p2_live.jpeg';
import useMediaQuery from '../hooks/useMediaQuery';
import Table from '../components/Table';

const Whitepaper = () => {
    const bp640px = useMediaQuery(640);
    const bp768px = useMediaQuery(768);

    return (
        <>
            <div className={`container mx-auto p-1 sm:p-4`}>
                <Header />
                <div className="mt-5 mb-5 text-center">
                    <a href="/" className="btn-frame">Home</a>
                    <a href="/game" className="btn-frame">Game</a>
                </div>
                <Frame className="mb-5">
                    <h1 className="text-center mb-5 text-3xl sm:text-5xl">Whitepaper: Phase 2 - The Passage</h1>
                    <div className="text-center">
                        <img className="lg:w-8/12 xl:w-6/12 mx-auto" src={ImagePhaseTwo} alt="" />
                    </div>
                    <p className="mt-5 mb-5">
                        As with every great story told, hope came when it was needed most… The sheep and wolves staring up in disbelief,
                        their eyes focused on first of the sun rays peeking through the dark and gloomy clouds.
                        Feuds between sheep and wolf now but an old memory. Sheep and wolf alike all rejoicing in the sun’s rays
                        and a renewed hope for a better future having formed a brotherhood and comrade for surviving the cold and hopelessness.
                        <br /><br />
                        Today is a new day over the barn. For months the animals only saw darkness and felt desperation, sadness,
                        and hopelessness since the old Farmers destroyed the barn and took almost everything.
                        The little sunshine that fights to reach the green grass of the meadow brings hope.
                        There was one clever and resourceful sheep, angry and tired of feeling abandoned decided to
                        take the rains with new plans for the barn and revitalizing the barn and all the animals within.
                        This very special sheep rallied his mind and fellow comrades and started with his plans for a better
                        future for the barn. The little ray of sunshine brings hope that this all might be over and start a new era.
                        <br /><br />
                        The first of many new ages for the barn has emerged in the form of restaurants owned and run by a
                        class of elite animals providing much needed purpose and fun activity to all those who have been
                        idle and abandoned for so long. Their story and success and will inevitably attract humans… when
                        first they meet, would sheep and humans find a way to cooperate, or will there be a hostile takeover?
                        As for the wolves with their predatory instincts, will they side with the sheep, will they side with
                        the humans… or become lone wolves again.
                        <br /><br />
                        What they do know is that the plan involves 3600 restaurants. Construction starts to tear up all the grass that the sheep eat.
                        They now need to find a new food source. The only option is the restaurants.
                        These restaurants produce FASTFOOD that serves as the food for the sheep and wolves.
                        Restaurants produce FASTFOOD. Sheep and wolves work non-stop, draining their energy.
                        To compensate they need to consume FASTFOOD every day to keep up the good work.
                        Most restaurants will be built using the precious FFWOOL and some unique restaurants will be constructed using MATIC.
                        The first 200 will cost 300,000 FFWOOL to build. After that, the restaurants’ union develops.
                        The restaurant union has a 10% chance to steal newly staked restaurants and a 5% chance of hiring wolves that leave the workforce.
                        The following 200 will cost 200,000 FFWOOL. After that, the next 600 will cost 100,000 FFWOOL.
                        The final 333 restaurants have hidden privileges, so their cost is 50 MATIC.
                        This ensures that the construction company has an incentive to bring more industries to this beautiful land.
                        <br /><br />
                        What does the future hold? Nobody really knows. They heard rumors about casinos and cooking competitions.
                        Who knows what the future holds for the beautiful fast-food universe.
                    </p>
                    <h2 className="text-center text-xl sm:text-3xl mt-5">Contract Addresses</h2>
                    <Table
                        itemName={"Contract Address"}
                        rows={[
                            ["Contract", "Address"],
                            ["Restaurant NFT", <span className={"text-sm sm:text-xl break-all"}>0x3b43Bf4862D3F65bF696B4FfF05cFb8D46A102a1</span>],
                            ["Restaurant Traits", <span className={"text-sm sm:text-xl break-all"}>0xD2fa1f13e98f01de291FE91481aF0faBBE4406D7</span>],
                            ["Restaurant Staking", <span className={"text-sm sm:text-xl"}>{process.env.REACT_APP_BARN_V3}</span>],
                            ["WarChest NFT", <span className={"text-sm sm:text-xl break-all"}>{process.env.REACT_APP_WAR_CHST_NFT}</span>],
                            ["WarChest Traits", <span className={"text-sm sm:text-xl break-all"}>0x115a044ba75C29D0c0Cc22cd6d9253F6877410d2</span>],
                            ["WarChest Mint/Exchange", <span className={"text-sm sm:text-xl break-all"}>{process.env.REACT_APP_WAR_CHST}</span>],
                            ["WarChest Open", <span className={"text-sm sm:text-xl break-all"}>{process.env.REACT_APP_WAR_CHST_OPEN}</span>],
                            ["War Secret", <span className={"text-sm sm:text-xl break-all"}>{process.env.REACT_APP_WAR_SECRET}</span>],
                            ["Fast Food NFT", <span className={"text-sm sm:text-xl"}>TBA</span>],
                            ["Fast Food Fights game", <span className={"text-sm sm:text-xl"}>TBA</span>]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />
                    <p className="mb-5">Stay tuned and follow us on <a href="https://twitter.com/FFWolfGame" target="_blank" rel="noreferrer">Twitter</a> and join our <a href="https://discord.gg/ffwolfgame" target="_blank" rel="noreferrer">Discord</a> for new announcements!</p>
                </Frame>

                <Frame>
                    <h1 className="text-center mb-5 text-3xl sm:text-5xl">Whitepaper: Phase 1 - The Inception</h1>

                    <h2 className="text-center text-xl sm:text-3xl mt-5">Fast Food Wolf Game (FFWG)</h2>
                    <p className="mb-5">Wolf.Game pioneered new types of NFT mechanics BUT Fast Food Wolf Game brings it to Polygon - with a Fast Food twist!</p>
                    <p className="mb-5">Low gas fees at full functionality! FFWG NFT holders are guaranteed to get more value for their coins.</p>
                    <p className="mb-5">FFWG is a risk protocol for NFTs with novel tokenomics on Polygon. It shows what’s possible with interactions between the ERC-20 and ERC-721 protocols. For the very first time, your NFT can steal other NFTs (ERC-721 tokens) for you. The rarer your NFT, the more tokens you'll accumulate probabilistically.</p>
                    <p className="mb-5">No roadmaps or empty promises. Just a game on the Polygon blockchain that’s ready to play at launch.</p>

                    <h2 className="text-center text-xl sm:text-3xl mt-5">The tl;dr:</h2>
                    <div className="text-center">
                        <img className="w-6/12 mx-auto" src={ImagePhaseOne} alt="" />
                    </div>
                    <ul className="list-disc px-5">
                        <li className="mt-3">There will only ever be 10,000 Gen 0, minted for 75 $MATIC each. The 40,000 Gen 1 are minted by farming $FFWOOL</li>
                        <li className="mt-3">Sheep can be staked in the Barn to earn $FFWOOL and pay a tax anytime they claim their $FFWOOL earnings</li>
                        <li className="mt-3">If a Sheep is unstaked from the Barn, the Wolves try to steal all of its accumulated $FFWOOL</li>
                        <li className="mt-3">When a new Sheep is born, the Wolves attempt to kidnap it. If they’re successful, it’s given to a randomly selected Wolf, instead of the owner who minted it</li>
                    </ul>

                    <h2 className="text-center text-xl sm:text-3xl mt-5">Contract Addresses</h2>
                    <Table
                        itemName={"Contract Address"}
                        rows={[
                            ["Contract", "Address"],
                            [
                                "Sheep / Wolf NFT",
                                <span className={`"text-sm sm:text-xl" ${!bp768px ? "break-all" : ""}`}>
                                    <a
                                        href="https://polygonscan.com/address/0xbDC91993Cc370eeD38e59cD1c68B6d2f88508Ce2"
                                        target="_blank" rel="noreferrer"
                                    >
                                        0xbDC91993Cc370eeD38e59cD1c68B6d2f88508Ce2
                                    </a>
                                </span>
                            ],
                            [
                                "Barn / Gang Staking",
                                <span className={`"text-sm sm:text-xl" ${!bp768px ? "break-all" : ""}`}>
                                    <a
                                        href="https://polygonscan.com/address/0x3b43Bf4862D3F65bF696B4FfF05cFb8D46A102a1"
                                        target="_blank" rel="noreferrer"
                                    >
                                        0x3b43Bf4862D3F65bF696B4FfF05cFb8D46A102a1
                                    </a>
                                </span>
                            ],
                            [
                                "$FFWOOL Token",
                                <span className={`"text-sm sm:text-xl" ${!bp768px ? "break-all" : ""}`}>
                                    <a
                                        href="https://polygonscan.com/address/0x10b1c123183E191E8e2d5B209323DE51c655e384"
                                        target="_blank" rel="noreferrer"
                                    >
                                        0x10b1c123183E191E8e2d5B209323DE51c655e384
                                    </a>
                                </span>
                            ]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />

                    <h2 className="text-center text-xl sm:text-3xl mt-5">Minting</h2>
                    <Table
                        itemName={"Minting"}
                        rows={[
                            ["Token ID", "Minting cost"],
                            ["1 to 10,000 (Gen 0)", "75 $MATIC"],
                            ["10,001 to 20,000", "20,000 $FFWOOL"],
                            ["20,001 to 30,000", "30,000 $FFWOOL"],
                            ["30,001 to 40,000", "40,000 $FFWOOL"],
                            ["40,001 to 50,000", "50,000 $FFWOOL"],
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />
                    <p className="mb-5">The total cost to mint all of the Sheep and Wolves in existence will be 1,400,000,000 $FFWOOL.</p>

                    <h2 className="text-center text-xl sm:text-3xl mt-5">Sheep</h2>
                    <p className="mb-5">You have a 90% chance of minting a Sheep, each with unique traits. Here are the actions they can take:</p>
                    <Table
                        itemName={"Action"}
                        rows={[
                            ["Action", "Notes", "Risks"],
                            ["Enter Barn (Stake)", "Accumulate 10,000 $FFWOOL / day (prorated to the second)", "No risk."],
                            [
                                "Shear $FFWOOL (Claim)",
                                "Receive 80% of $FFWOOL accumulated on your Sheep",
                                "Wolves take a guaranteed 20% tax on sheared $FFWOOL in return for not attacking the Barn. Taxed $FFWOOL is split among all the Wolves currently staked in the Barn, proportional to their Alpha scores.",
                            ],
                            [
                                "Leave Barn (Unstake)",
                                "Sheep is removed from the Barn and all $FFWOOL is shorn. Can only be done if the Sheep has accumulated 2 days worth of $FFWOOL to keep it warm.",
                                "50% chance of ALL of your accumulated $FFWOOL being stolen by Wolves. Stolen $FFWOOL is split among all the Wolves currently staked in the Barn, proportional to their Alpha scores.",
                            ]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />

                    <h2 className="text-center text-xl sm:text-3xl mt-5">$FFWOOL</h2>
                    <p className="mb-5">The maximum $FFWOOL <strong>supply is 2,400,000,000 $FFWOOL</strong> and will have the following allocation:</p>
                    <Table
                        itemName={"Allocation"}
                        rows={[
                            ["Allocation", "Description", "$FFWOOL"],
                            ["Faucet", "When supply reaches 1,800,000,000 $FFWOOL earned for staking, the staking “faucet” turns off.", "1,800,000,000"],
                            [
                                "Community Rewards",
                                "paid out linearly over a period of 12 months starting immediately with launch; used to build community and supporting marketing efforts; reward community initiatives",
                                "300,000,000"
                            ],
                            [
                                "Developers",
                                "paid out linearly over a period of 12 months starting immediately with launch",
                                "200,000,000"
                            ],
                            [
                                "Liquidity pool",
                                <>initial LP will be formed when Gen0 mint has completed with <strong>50% of Gen0 proceeds</strong></>,
                                "100,000,000"
                            ],
                            ["", "", <strong>2,400,000,000</strong>]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                        lastRow={"Altogether: 2,400,000,000"}
                    />
                    <p className="mb-5">A more detailed roadmap is in the works and we have big plans for the Fast Food Wolf Game so stay tuned and follow us on <a href="https://twitter.com/FFWolfGame" target="_blank" rel="noreferrer">Twitter</a> and join our <a href="https://discord.gg/ffwolfgame" target="_blank" rel="noreferrer">Discord</a>!</p>

                    <Table
                        itemName={"Action"}
                        rows={[
                            ["Action", "Notes", "Risks"],
                            [
                                "Mint a new Sheep using $FFWOOL",
                                "There is a 10% chance that the NFT is actually a Wolf!",
                                "10% chance of the new Sheep or Wolf being stolen by a staked Wolf. Each Wolf’s chance of success is proportional to their Alpha scores."
                            ]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />

                    <h2 className="text-center text-xl sm:text-3xl mt-5">Wolves</h2>
                    <p className="mb-5">You have a 10% chance of minting a Wolf, each with unique traits, including an Alpha value ranging from 5 to 8. The higher the Alpha value: - The higher the portion of $FFWOOL that the Wolf earns from taxes - The higher chance of stealing a newly minted Sheep or Wolf</p>
                    <h3>Example:</h3>
                    <p className="mb-5">Wolf A has an Alpha of 8 and Wolf B has an Alpha of 6, and they are staked.</p>
                    <ul className="list-disc px-5">
                        <li className="mt-3">If 70,000 $FFWOOL total have been accumulated as taxes, Wolf A will be able to claim 40,000 $FFWOOL and Wolf B will be able to claim 30,000 $FFWOOL</li>
                        <li className="mt-3">If a newly minted Sheep or Wolf is stolen, Wolf A has a 57% chance of receiving it and Wolf B has a 43% chance of receiving it</li>
                    </ul>
                    <p className="mb-5">Only staked Wolves are able to steal a sheep or earn the $FFWOOL tax.</p>
                    <Table
                        itemName={"Action"}
                        rows={[
                            ["Action", "Notes", "Risks"],
                            [
                                "Stake Wolf",
                                "Earn your share of the 20% tax of all $FFWOOL generated by Sheep in the Barn",
                                "No risk."
                            ],
                            [
                                "Claim $FFWOOL",
                                "Receive all $FFWOOL taxes accrued for the staked Wolf",
                                "No risk."
                            ],
                            [
                                "Unstake Wolf",
                                "Receive all $FFWOOL taxes accrued for the staked Wolf",
                                "No risk."
                            ]
                        ]}
                        bp={bp640px}
                        fullWidth
                        className='mt-5 mb-5'
                    />
                </Frame>

            </div>
        </>
    )
}

export default Whitepaper;
