import classes from "../design/MintProgress.module.scss";

const RestMintProgress = ({ minted, maxTokens }) => {

  const percent = Math.round(minted / maxTokens * 100);
  let barStyle = { width: "100%", height: percent > 100 ? "100%" : percent + "%" }

  return (
    <div>
      <div className="flex justify-center">
        <div className={classes.restBar}>
          <div className={classes.restIndicator} style={barStyle}></div>
          <p className={`${classes.gen} ${classes.rest0}`}>300,000 $FFWOOL</p>
          <p className={`${classes.gen} ${classes.rest1}`}>200,000 $FFWOOL</p>
          <p className={`${classes.gen} ${classes.rest2}`}>100,000 $FFWOOL</p>
          <p className={`${classes.gen} ${classes.rest3}`}>50 $MATIC</p>
        </div>
      </div>
      <div className={classes.mint + " text-center mb-2"}>{minted} / {maxTokens} minted</div>
    </div>
  );
};

export default RestMintProgress;
