import { useEffect, useState } from "react";
import Container from "./Container";
import RestList from "./RestList";
import EthereumInteraction from "./EthereumInteraction";
import { parseBigNumber, watchTransaction } from "../utils/ethereum";
import BackgroundGrass from "../assets/images/game-grass.png";
import BackgroundBurgers from "../assets/images/game-burgers.png";
import WoodButton from "./WoodButton";
import LoadingModal from "./LoadingModal";
import OutcomeModal from "./OutcomeModal";
import { isPaused, parseClaims, rescueRest, stakeManyRestaurants, unstakeManyRestaurants } from "../utils/barn";
import { approve, isApproved } from "../utils/rest";
import handleResult from "../utils/handleResult";

const Restaurants = ({
    wallet,
    chain,
    wool,
    tokens,
    pageSize,
    setPageSize,
    pageCount,
    page,
    changePage,
    fetching,
    staked,
    pageSizeStaked,
    setPageSizeStaked,
    pageCountStaked,
    pageStaked,
    changePageStaked,
    fetchingStaked,
    reload,
}) => {
    const [loadingScenes, setLoadingScenes] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [operation, setOperation] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectedAllUnStaked, setSelectedAllUnStaked] = useState(false);
    const [selectedAllStaked, setSelectedAllStaked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [transacting, setTransacting] = useState(false);

    const [oldPaused, setOldPaused] = useState(false);

    useEffect(async () => {
        if (wallet) {
            setOldPaused(await isPaused());
        }
    }, [wallet]);

    const stopLoading = () => {
        setLoading(false);
        setTransacting(false);
    }

    const [approved, setApproved] = useState(false);
    const [intervalIsApproved, setIntervalIsApproved] = useState();

    useEffect(async () => {
        if (wallet) {
            if (intervalIsApproved !== undefined) clearInterval(intervalIsApproved);

            if (!approved) setApproved(await isApproved(wallet));

            const interval = setInterval(async () => {
                if (!approved) setApproved(await isApproved(wallet));
            }, 2500);
            setIntervalIsApproved(interval);
            return () => clearInterval(interval);
        }
    }, [wallet]);

    useEffect(() => {
        if (approved && intervalIsApproved !== undefined) clearInterval(intervalIsApproved);
    }, [approved]);

    const onApprove = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await approve();
            setError(handleResult(result, "Approve"));
            if (!("hash" in result)) { stopLoading(); return; }

            let hash = result.hash;

            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Approve failed. Check transaction.");
                }

                setApproved(true);
                setTimeout(async () => {
                    await presentApproval();
                    setLoading(false);
                    setTransacting(false);
                }, 2000);
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
            setTransacting(false);
        }
    };

    const presentApproval = async () => {
        const o = [];
        o.push({
            message: "Successful Approval",
            source: "/images/claimed-pack.gif",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    useEffect(() => {
        if (selected.length === 0) {
            setOperation(null);
            setSelectedAllStaked(false);
            setSelectedAllUnStaked(false);
        }
    }, [selected]);

    const onStake = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await stakeManyRestaurants(selected.map((x) => x.number));
            setError(handleResult(result, "Stake"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Stake failed. Check transaction.");
                }
                const o = [];
                for (let i = 0; i < selected.length; i++) {
                    const message = `Restaurant #${selected[i].number} staked`;
                    const source = "/images/staked-barn.gif";
                    o.push({ message, source });
                }
                setOutcomes(o);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            /*  console.log(e); */
            setLoading(false);
        }
    };

    const onUnstake = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await unstakeManyRestaurants(selected.map((x) => x.number));
            setError(handleResult(result, "Unstake"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Unstake failed. Check transaction.");
                }
                presentOutcomes(receipt);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onRescue = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await rescueRest(selected.map((x) => x.number));
            setError(handleResult(result, "Recovering restaurants"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Recovering restaurants failed. Check transaction.");
                }
                presentOutcomes(receipt);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const presentOutcomes = (receipt) => {
        const claims = parseClaims(receipt);
        const o = [];
        for (let i = 0; i < claims.length; i++) {
            const token = selected.find((el) => el.number === claims[i]?.tokenId?.toString());
            if (claims[i].unstaked) {
                if (!token) {
                    o.push({
                        message: `The restaurant was acquired by someone else!`,
                        source: "/images/unstaked-notsafe.gif",
                    });
                } else {
                    o.push({
                        message: `Restaurant #${token.number} is open for business, earning ${parseBigNumber(claims[i]?.earned) || ""} $FASTFOOD.`,
                        source: "/images/unstaked-safe.gif",
                    });
                }
            } else {
                o.push({
                    message: `Restaurant #${token.number} was staked${parseBigNumber(claims[i]?.earned) ? ` for ${parseBigNumber(claims[i].earned)} $FASTFOOD.` : "."}`,
                    source: "/images/sheared.gif",
                });
            }
        }
        setOutcomes(o);
    };

    return (
        <Container>
            <div className="">
                <div className="text-center">
                    <div className="uppercase font-bold">In your wallet</div>
                    <div className="text-black text-xl sm:text-3xl">{parseBigNumber(wool, 0)} <small>$FFWOOL</small></div>
                </div>
                {error && <div className="text-xl text-red font-console text-center mt-3">{error}</div>}
                <EthereumInteraction wallet={wallet} chain={chain}>
                    <div className="text-xl sm:text-3xl font-bold uppercase mt-5 text-center">Unstaked Restaurants</div>
                    <RestList
                        fetching={fetching}
                        backgroundImage={BackgroundGrass}
                        active={operation !== "UNSTAKE"}
                        items={tokens}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageCount={pageCount}
                        page={page}
                        changePage={changePage}
                        selected={selected}
                        toggleSelected={(item, select) => {
                            if (operation !== "STAKE" && operation !== null) return;
                            setOperation("STAKE");
                            setSelected((current) => {
                                return select ? current.concat(item) : current.filter((el) => el.id !== item.id);
                            });
                        }}
                    />
                    <div className="text-xl sm:text-3xl font-bold uppercase mt-5 text-center">Staked Restaurants</div>
                    <RestList
                        fetching={fetchingStaked}
                        backgroundImage={BackgroundBurgers}
                        active={operation !== "STAKE"}
                        items={staked}
                        pageSize={pageSizeStaked}
                        setPageSize={setPageSizeStaked}
                        pageCount={pageCountStaked}
                        page={pageStaked}
                        changePage={changePageStaked}
                        selected={selected}
                        toggleSelected={(item, select) => {
                            if (operation !== "UNSTAKE" && operation !== null) return;
                            setOperation("UNSTAKE");
                            setSelected((current) => {
                                return select ? current.concat(item) : current.filter((el) => el.id !== item.id);
                            });
                        }}
                    />
                    <div className="w-full flex flex-col md:flex-row justify-center items-center gap-1">
                        {!fetching &&
                            <div>
                                {approved
                                    ?
                                    <>
                                        {operation === "UNSTAKE" &&
                                            <>
                                                <WoodButton
                                                    size="small"
                                                    title={"UNSTAKE"}
                                                    loading={loading}
                                                    onClick={async () => {
                                                        const scenes = [];
                                                        scenes.push({
                                                            message: "Leaving the barn",
                                                            source: "/images/unstaking-barn.gif",
                                                        });
                                                        setLoadingScenes(scenes);
                                                        await onUnstake();
                                                    }}
                                                />
                                                {oldPaused &&
                                                    <WoodButton
                                                        size="small"
                                                        title={"Recover restaurants"}
                                                        loading={loading}
                                                        onClick={async () => {
                                                            const scenes = [];
                                                            scenes.push({
                                                                message: "Leaving the barn",
                                                                source: "/images/unstaking-barn.gif",
                                                            });
                                                            setLoadingScenes(scenes);
                                                            await onRescue();
                                                        }}
                                                    />
                                                }
                                            </>
                                        }
                                        {operation === "STAKE" &&
                                            <WoodButton
                                                title={"STAKE"}
                                                size="small"
                                                loading={loading}
                                                onClick={async () => {
                                                    const scenes = [];
                                                    scenes.push({
                                                        message: "Entering the Barn",
                                                        source: "/images/staking-barn.gif",
                                                    });
                                                    setLoadingScenes(scenes);
                                                    await onStake(selected);
                                                }}
                                            />
                                        }
                                        <div className="mb-5">
                                            {(operation === null || operation === "STAKE") && tokens.length > 0 &&
                                                <WoodButton
                                                    title={!selectedAllUnStaked ? "Select ALL Unstaked" : "Deselect ALL Unstaked"}
                                                    size="small"
                                                    loading={loading}
                                                    onClick={() => {
                                                        if (!selectedAllUnStaked) {
                                                            setSelected(tokens);
                                                            setSelectedAllUnStaked(true);
                                                            if (operation !== "STAKE" && operation !== null) return;
                                                            setOperation("STAKE");
                                                        } else {
                                                            setSelected([]);
                                                            setSelectedAllUnStaked(false);
                                                        }
                                                    }}
                                                />
                                            }
                                            {(operation === null || operation === "UNSTAKE") && staked.length > 0 &&
                                                <WoodButton
                                                    title={!selectedAllStaked ? "Select ALL Staked" : "Deselect ALL Staked"}
                                                    size="small"
                                                    loading={loading}
                                                    onClick={() => {
                                                        if (!selectedAllStaked) {
                                                            setSelected(staked);
                                                            setSelectedAllStaked(true);
                                                            if (operation !== "UNSTAKE" && operation !== null) return;
                                                            setOperation("UNSTAKE");
                                                        } else {
                                                            setSelected([]);
                                                            setSelectedAllStaked(false);
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </>
                                    :
                                    <div>
                                        <WoodButton
                                            size="small"
                                            title={"Approve"}
                                            loading={loading}
                                            onClick={async () => {
                                                await onApprove();
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </EthereumInteraction>
            </div>
            {error && <div className="text-xl text-red font-console text-center">{error}</div>}
            <LoadingModal loadingScenes={loadingScenes} modalIsOpen={transacting} />
            <OutcomeModal
                outcomes={outcomes}
                modalIsOpen={outcomes.length > 0}
                closeModal={() => {
                    reload();
                    setOutcomes([]);
                    setSelected([]);
                }}
            />
        </Container>
    );
};

export default Restaurants;
