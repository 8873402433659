import Container from "./Container";
import chest1 from "../assets/images/chest1.png";
import chest2 from "../assets/images/chest2.png";
import chest3 from "../assets/images/chest3.png";
import classes from "../design/Minting.module.scss";
import { useEffect, useState } from "react";
import EthereumInteraction from "./EthereumInteraction";
import WoodButton from "./WoodButton";
import { approve } from "../utils/erc20";
import { watchTransaction } from "../utils/ethereum";
import OutcomeModal from "./OutcomeModal";
import { exchangeCommonForEpic, approve as approveOpen, isApproved, mint, parseMint } from "../utils/warChst";
import LoadingModal from "./LoadingModal";
import { openChest, parseLog } from "../utils/warChstOpen";
import { decodeTokenURI } from "../utils/uri";
import { tokenURI as tokenURIRest } from "../utils/rest";
import REST_ABI from "../utils/abi/rest.abi";
import WOOLF_ABI from "../utils/abi/woolf.abi";
import handleResult from "../utils/handleResult";

export default function Chests({
    wallet,
    chain,
    stats,
    warchestTokens,
    warchestCountCommon,
    warchestCountEpic,
    warchestCountLegendary,
    warchestCountCommonOpen,
    warchestCountEpicOpen,
    warchestCountLegendaryOpen,
    warSecretCount,
    approved,
    setApproved,
    reload,
}) {
    const chests = [
        {
            typeIndex: 2,
            name: "common",
            img: chest1,
            title: "War Chest",
            price: 50000,
            currency: "$FFWOOL",
            limit: "100",
            stealChance: "25% chance that wolves steal it",
            burn: "Burn 12 to mint Epic War Chest",
            minted: parseInt(stats?.warchestMintedCommon || "0"),
            tokens: parseInt(warchestCountCommon || "0"),
            tokensOpen: parseInt(warchestCountCommonOpen || "0"),
            maxTokens: parseInt(stats?.maxTokensWarchestCommon || "1555"),
        },
        {
            typeIndex: 1,
            name: "epic",
            img: chest2,
            title: "Epic War Chest",
            price: 500000,
            currency: "$FFWOOL",
            limit: "50",
            stealChance: "15% chance that wolves steal it",
            minted: parseInt(stats?.warchestMintedEpic || "0"),
            tokens: parseInt(warchestCountEpic || "0"),
            tokensOpen: parseInt(warchestCountEpicOpen || "0"),
            maxTokens: parseInt(stats?.maxTokensWarchestEpic || "200"),
        },
        {
            typeIndex: 0,
            name: "legendary",
            img: chest3,
            title: "Legendary War Chest",
            price: 500,
            currency: "$MATIC",
            limit: "25",
            minted: parseInt(stats?.warchestMintedLegendary || "0"),
            tokens: parseInt(warchestCountLegendary || "0"),
            tokensOpen: parseInt(warchestCountLegendaryOpen || "0"),
            maxTokens: parseInt(stats?.maxTokensWarchestLegendary || "20"),
        },
    ];

    const MINTING_PAUSED = true;

    const commonToEpicRate = 12;

    const [amount, setAmount] = useState({
        common: 1,
        epic: 1,
        legendary: 1
    });
    const [loading, setLoading] = useState(false);
    const [transacting, setTransacting] = useState(false);
    const [response, setResponse] = useState();
    const [responseExchange, setResponseExchange] = useState();
    const [outcomes, setOutcomes] = useState([]);
    const [approvedOpen, setApprovedOpen] = useState(false);
    const [intervalIsApprovedOpen, setIntervalIsApprovedOpen] = useState();
    const [justApproved, setJustApproved] = useState(false);
    const [chestType, setChestType] = useState();

    useEffect(async () => {
        if (wallet) {
            if (intervalIsApprovedOpen !== undefined) clearInterval(intervalIsApprovedOpen);

            if (!approvedOpen) setApprovedOpen(await isApproved(wallet));

            const interval = setInterval(async () => {
                if (!approvedOpen) setApprovedOpen(await isApproved(wallet));
            }, 2500);
            setIntervalIsApprovedOpen(interval);
            return () => clearInterval(interval);
        }
    }, [wallet]);

    useEffect(() => {
        if (approvedOpen && intervalIsApprovedOpen !== undefined) clearInterval(intervalIsApprovedOpen);
    }, [approvedOpen]);

    const onApproveOpen = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await approveOpen();
            setResponse(handleResult(result, "Approve"));
            if (!("hash" in result)) { setLoading(false); return; }

            let hash = result.hash;
            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Approve failed. Check transaction.");
                }

                setApprovedOpen(true);
                setTimeout(async () => {
                    await presentApproval();
                    setLoading(false);
                    setTransacting(false);
                }, 2000);
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
            setTransacting(false);
        }
    };

    const onApprove = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await approve(process.env.REACT_APP_WOOL, process.env.REACT_APP_WAR_CHST);
            setResponse(handleResult(result, "Approve"));
            if (!("hash" in result)) { setLoading(false); return; }

            setTransacting(true);
            let hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Approve failed. Check transaction.");
                }

                setJustApproved(true);
                setApproved(true);
                await presentApproval();
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
            setTransacting(false);
        }
    };

    const presentApproval = async () => {
        const o = [];
        o.push({
            message: "Successful Approval",
            source: "/images/claimed-pack.gif",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    const onMint = async (chestName, typeIndex) => {
        setLoading(true);
        setResponse();
        try {
            const result = await mint(amount[chestName], typeIndex);
            setResponse(handleResult(result, "Mint"));
            if (!("hash" in result)) { setLoading(false); return; }

            setTransacting(true);
            let hash = result.hash;

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Mint failed. Check transaction.");
                }

                const results = parseMint(receipt);

                setTimeout(() => {
                    presentOutcomes(results);
                    setLoading(false);
                    setTransacting(false);
                }, 2000);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    };

    const presentOutcomes = (results) => {
        const o = [];
        for (let i in results) {
            const { tokenId } = results[i];
            o.push({
                message: `You are now the owner of Chest #${tokenId.toString()}`,
                source: chest1,
                link: "",
                linkMessage: "",
            });
        }

        o.push({
            message: "To prevent exploits, your NFTs will be revealed after 10 more NFTs are minted (by anyone).",
            source: "",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    const delay = (time) => {
        return new Promise(res => setTimeout(res, time));
    }

    const getTokenUri = async (isRest, tokenId) => {
        try {
            let tokenURI = isRest ? await tokenURIRest(tokenId) : await tokenURI(tokenId);
            while (!tokenURI) {
                await delay(1000);
                tokenURI = isRest ? await tokenURIRest(tokenId) : await tokenURI(tokenId);
            }
            return tokenURI;
        } catch (e) {
            return getTokenUri(tokenId);
        }
    }

    const presentOutcomesFromReceipt = async (receipt) => {
        const o = [];
        var warSecretCount = 0;
        receipt?.logs.forEach(async log => {
            const contract = log?.address;
            const isRest = contract === process.env.REACT_APP_REST;
            const isWoolf = contract === process.env.REACT_APP_WOOLF;
            const isSecret = contract === process.env.REACT_APP_WAR_SECRET;
            const tokenId = parseLog(log, isRest ? REST_ABI : WOOLF_ABI);
            const tokenUri = (isRest || isWoolf) ? await getTokenUri(isRest, tokenId) : "";

            if (isRest || isWoolf)
                o.push({
                    message: `You are now the owner of ${isRest ? "restaurant" : isWoolf ? "sheep or wolf" : "NFT"} #${tokenId.toString()}`,
                    source: decodeTokenURI(tokenUri).image,
                    link: "",
                    linkMessage: "",
                });
            else if (isSecret) warSecretCount++;
        });

        if (warSecretCount > 0)
            o.push({
                message: `You have received ${warSecretCount} war secret${warSecretCount > 1 ? "s" : ""}`,
                source: "",
                link: "",
                linkMessage: "",
            });

        setOutcomes(o);
    };

    const exchangeCommonsForEpic = async () => {
        setLoading(true);
        setResponseExchange();
        try {
            const result = await exchangeCommonForEpic(
                warchestTokens
                    ?.filter(chest => chest.typeIndex === "2")
                    ?.map(chest => chest.id)?.slice(0, commonToEpicRate));
            setResponseExchange(handleResult(result, "Exchange"));
            if (!("hash" in result)) { setLoading(false); return; }

            setTransacting(true);
            let hash = result.hash;

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponseExchange("Exchange failed. Check transaction.");
                }

                const o = [];
                o.push({ message: "Successful exchange! You have received an epic chest.", source: chest2 });
                setOutcomes(o);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    }

    const open = async (typeIndex) => {
        setLoading(true);
        setResponse();
        try {
            const result = await openChest(warchestTokens?.find(chest => chest.typeIndex === typeIndex.toString())?.id);
            setResponse(handleResult(result, "Opening chest"));
            if (!("hash" in result)) { setLoading(false); return; }

            setTransacting(true);
            let hash = result.hash;

            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Opening chest failed. Check transaction.");
                }

                await presentOutcomesFromReceipt(receipt);

                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    }

    const approveBtn = (open, i) => {
        return (
            <WoodButton
                title={"APPROVE"}
                size={"small"}
                loading={loading}
                onClick={open ? async () => {
                    setChestType(i);
                    await onApproveOpen();
                }
                    : async () => {
                        setChestType(i);
                        await onApprove();
                    }}
            />
        )
    }

    return (
        <>
            <Container>
                <p className={`text-xl sm:text-3xl font-bold mt-5 uppercase ${wallet ? "mb-16" : ""} text-center`}>Prepare for war!</p>
                <EthereumInteraction wallet={wallet} chain={chain}>
                    {chests.map((chest, i) => {
                        const percentageCalc = chest.minted / chest.maxTokens * 100;
                        const percentage = isNaN(percentageCalc) ? 0 : percentageCalc > 100 ? 100 : percentageCalc;
                        return (
                            <div key={i.toString()} className={"text-center w-full mb-16"}>
                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1 mb-4">
                                        <p className="uppercase font-bold mb-4 text-xl sm:text-3xl">{chest.title}</p>
                                        <img
                                            src={chest.img}
                                            alt={chest.title}
                                            width={180}
                                            height={180}
                                        />
                                    </div>
                                    <div className={"col-span-3 lg:col-span-1 text-center mb-5"}>
                                        <p className="uppercase font-bold mb-4 text-xl sm:text-3xl">MINTING</p>
                                        <div className="flex justify-center mb-4">
                                            <div className="w-[80%] h-[50px] relative border-2 border-solid border-black flex items-center justify-center">
                                                <div
                                                    className="absolute left-0 top-0 bottom-auto h-full bg-[#B11D18]"
                                                    style={{ width: percentage + "%" }}
                                                />
                                                <p className="text-black z-10">{chest.minted}/{chest.maxTokens} minted</p>
                                            </div>
                                        </div>
                                        {MINTING_PAUSED
                                            ? <p className="text-red font-console text-center">Minting paused</p>
                                            : !approved
                                                ?
                                                <div className="flex justify-center">
                                                    {approveBtn(false, i)}
                                                </div>
                                                :
                                                <>
                                                    <div className={classes.mint}>
                                                        <div className="flex justify-center items-center gap-2" style={{ height: "50px" }}>
                                                            <div className="pt-2 mr-3">Amount:</div>
                                                            <img
                                                                src="/images/arrow-down.svg"
                                                                alt="decrease"
                                                                className="cursor-pointer"
                                                                style={{ minWidth: "25px" }}
                                                                onClick={() => {
                                                                    const newState = { ...amount };
                                                                    newState[chest.name] = Math.max(1, newState[chest.name] - 1);
                                                                    setAmount(newState);
                                                                }}
                                                            />
                                                            <div className="font-console text-red text-xl sm:text-3xl pt-2"><span className={classes.amount}>{amount[chest.name]}</span></div>
                                                            <img
                                                                src="/images/arrow-up.svg"
                                                                alt="increase"
                                                                className="cursor-pointer"
                                                                style={{ minWidth: "25px" }}
                                                                onClick={() => {
                                                                    const newState = { ...amount };
                                                                    newState[chest.name] = Math.min(10, newState[chest.name] + 1);
                                                                    setAmount(newState);
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            Cost: <span className={classes.cost}><span className={classes.value}>{chest.price * amount[chest.name]}</span> {chest.currency}</span>
                                                        </div>
                                                    </div>
                                                    <div className="flex justify-center">
                                                        <div className="w-fit">
                                                            <WoodButton
                                                                title={"MINT"}
                                                                size={"small"}
                                                                loading={loading}
                                                                onClick={async () => {
                                                                    setChestType(i);
                                                                    await onMint(chest.name, chest.typeIndex);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <p className="text-black">{chest.stealChance}</p>
                                    <p className="text-black mb-16">{chest.burn}</p> */}
                                                </>
                                        }
                                    </div>
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="uppercase font-bold mb-4 text-xl sm:text-3xl">Your chests</p>
                                        <p className="mb-1"><span className="text-black">Remaining:</span> {chest.tokens}</p>
                                        <p className="mb-1"><span className="text-black">Opened:</span> {chest.tokensOpen}</p>
                                        <div className="flex justify-center">
                                            <div className="w-fit">
                                                {approvedOpen
                                                    ?
                                                    <WoodButton
                                                        title={"OPEN ONE"}
                                                        size={"small"}
                                                        loading={loading}
                                                        onClick={async () => {
                                                            setChestType(i);
                                                            await open(chest.typeIndex);
                                                        }}
                                                        disabled={chest.tokens === 0}
                                                    />
                                                    : approveBtn(true, i)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {response && chestType === i && <div className="text-red font-console text-center">{response}</div>}
                            </div>
                        )
                    })}
                </EthereumInteraction>
                <LoadingModal
                    loadingScenes={[
                        {
                            message: "Processing...",
                            source: "/images/minting.gif",
                        },
                    ]}
                    modalIsOpen={!!transacting}
                />
                <OutcomeModal
                    outcomes={outcomes}
                    modalIsOpen={outcomes.length > 0}
                    closeModal={() => {
                        setOutcomes([]);
                        if (!justApproved) reload();
                    }}
                />
            </Container>
            <div className="grid grid-cols-2 gap-4">
                <div className="col-span-2 xl:col-span-1 mt-5">
                    <Container>
                        <p className="uppercase font-bold mb-4 text-xl sm:text-3xl text-center">Exchange chests</p>
                        <EthereumInteraction wallet={wallet} chain={chain}>
                            <div className="grid grid-cols-2">
                                <p className="col-span-2 sm:col-span-1 text-center sm:text-right">
                                    <span className="font-bold">12 WAR CHESTS</span>
                                    <br />
                                    for
                                    <br />
                                    <span className="font-bold">1 EPIC WAR CHEST</span>
                                </p>
                                <div className="col-span-2 sm:col-span-1 flex justify-center">
                                    <div className="w-fit">
                                        {approved
                                            ?
                                            <WoodButton
                                                title={"EXChANGE"}
                                                size={"small"}
                                                loading={loading}
                                                onClick={async () => await exchangeCommonsForEpic()}
                                                disabled={warchestCountCommon < commonToEpicRate || isNaN(warchestCountCommon)}
                                            />
                                            : approveBtn(false)
                                        }
                                    </div>
                                </div>
                            </div>
                        </EthereumInteraction>
                        {responseExchange && <div className="text-red font-console text-center">{responseExchange}</div>}
                    </Container>
                </div>
                <div className="col-span-2 xl:col-span-1 mt-5">
                    <Container>
                        <p className="uppercase font-bold mb-4 text-xl sm:text-3xl text-center">War secrets</p>
                        <p className="mb-1 text-center"><span className="text-black">You have:</span> {warSecretCount || 0}</p>
                    </Container>
                </div>
            </div>
        </>
    )
}
