import React from 'react'
import { connectMetamask } from '../utils/ethereum'

const MetamaskConnect = () => {
  return (
    <div 
      className="cursor-pointer" 
      onClick={connectMetamask}
    >
      <div className="btn-frame btn-small">
        Connect Metamask
      </div>
    </div>
  )
}

export default MetamaskConnect
