const WoodButton = ({ title, onClick, size = null, loading = false, disabled = false, className = "", }) => {

  return (
    <div
      className={`relative flex items-center justify-center ${!loading && !disabled && "cursor-pointer"} ${disabled && "cursor-not-allowed"} ${className}`}
      onClick={() => {
        if (loading || disabled) return
        onClick()
      }}
    >
      {!loading ? (
        <div className={size === "small" ? "btn-frame btn-small" : "btn-frame"}>
          {title}
        </div>
      ) : (
        <div className={size === "small" ? "btn-frame btn-small" : "btn-frame"}>
          ...
        </div>
      )}

    </div>
  )

}

export default WoodButton