import useMediaQuery from "../hooks/useMediaQuery";

const Loader = ({ message, source, index }) => {
  const bp360px = useMediaQuery(360);

  return (
    <div className="flex flex-col justify-center items-center" key={index}>
      <img
        src={source} alt=''
        width={bp360px ? 64 * 4 : "100%"}
        height={bp360px ? 64 * 4 : "auto"}
        className="object-contain"
      />
      <div className="text-center font-console text-lg mt-4" style={{ width: bp360px ? 64 * 4 : "100%" }}>
        {message}
      </div>
    </div>
  )

}

export default Loader