import Header from './design/Header';
import RestMinting from "./components/RestMinting"
import Restaurants from "./components/Restaurants"
import Links from './components/Links'
import { useQuery } from '@apollo/client'
import { parseGraphObject, REST_QUERY, REST_QUERY_GLOBAL, REST_QUERY_STAKED, REST_QUERY_STAKED_NEW } from './utils/query'
import { useEffect, useState } from 'react';
import useMediaQuery from './hooks/useMediaQuery';
import testTokens from './testData/tokensRest';
import MigrationModal from './components/MigrationModal';
import { hasClaimed } from './utils/claimV3';
import Container from './components/Container';
import WoodButton from './components/WoodButton';

const Rest = ({ wallet, chain, wool, reload }) => {
  const bp768px = useMediaQuery(768);

  const [windowSizeChanged, setWindowSizeChanged] = useState(false);

  const [tokens, setTokens] = useState([]);
  //az összes nem stakelt éttermem darabszáma
  const [tokensCount, setTokensCount] = useState(0);
  const [pageSize, setPageSize] = useState(bp768px ? 10 : 50);
  const [offset, setOffset] = useState(0);

  const [staked, setStaked] = useState([]);
  //az összes stakelt éttermem darabszáma
  const [stakedCount, setStakedCount] = useState(0);
  const [pageSizeStaked, setPageSizeStaked] = useState(bp768px ? 10 : 50);
  const [offsetStaked, setOffsetStaked] = useState(0);

  const { data, loading, refetch } = useQuery(
    REST_QUERY,
    {
      variables: {
        wallet: wallet || '',
        first: pageSize,
        skip: offset
      },
    }
  );
  const { data: dataStakedOld, loading: loadingStakedOld, refetch: refetchStakedOld } = useQuery(
    REST_QUERY_STAKED,
    {
      variables: {
        wallet: wallet || '',
      },
    }
  );
  const { data: dataStaked, loading: loadingStaked, refetch: refetchStaked } = useQuery(
    REST_QUERY_STAKED_NEW,
    {
      variables: {
        wallet: wallet || '',
        first: pageSizeStaked,
        skip: offsetStaked
      },
    }
  );
  const { data: dataGlobal, refetch: refetchGlobal } = useQuery(
    REST_QUERY_GLOBAL,
    {
      variables: {
        wallet: wallet || '',
      },
      pollInterval: 2500,
    }
  );

  const changePage = (newPage) => setOffset(newPage * pageSize - pageSize);
  const changePageStaked = (newPage) => setOffsetStaked(newPage * pageSizeStaked - pageSizeStaked);

  const placeholderCount = (i, tokensCount, offset, pageSize) => {
    if (tokensCount > 0)
      return (
        offset / pageSize + 1 === Math.ceil(tokensCount / pageSize)
          ? i < tokensCount - (Math.ceil(tokensCount / pageSize) - 1) * pageSize
          : i < pageSize
      );
    else return false;
  }

  const updateTokens = () => {
    setTokens(
      bp768px
        ? testTokens.filter((t, i) => placeholderCount(i, tokensCount, offset, pageSize))
        : tokens.concat(testTokens.filter((t, i) => placeholderCount(i, tokensCount, offset, pageSize))));
  }

  useEffect(() => {
    updateTokens();
  }, [offset]);

  const updateStaked = () => {
    setStaked(
      bp768px
        ? testTokens.filter((t, i) => placeholderCount(i, stakedCount, offsetStaked, pageSizeStaked))
        : staked.concat(testTokens.filter((t, i) => placeholderCount(i, stakedCount, offsetStaked, pageSizeStaked))));
  }

  useEffect(() => {
    updateStaked();
  }, [offsetStaked]);

  useEffect(() => {
    if (bp768px) {
      if (offset === 0) updateTokens();
      else setOffset(0);
    }
  }, [pageSize]);

  useEffect(() => {
    if (bp768px) {
      if (offsetStaked === 0) updateStaked();
      else setOffsetStaked(0);
    }
  }, [pageSizeStaked]);

  useEffect(() => {
    if (tokens.length > 0 || staked.length > 0) {
      setWindowSizeChanged(true);
      setTokens([]);
      setStaked([]);
    }
  }, [bp768px]);

  useEffect(() => {
    if (windowSizeChanged) {
      setWindowSizeChanged(false);
      setPageSize(bp768px ? 10 : 50);
      setPageSizeStaked(bp768px ? 10 : 50);
      setOffset(0);
      setOffsetStaked(0);
    }
  }, [windowSizeChanged]);

  useEffect(() => {
    if (data && data.restaurantTokens) {
      const newTokens = [...data.restaurantTokens.map(x => parseGraphObject(x))];
      setTokens(
        bp768px
          ? newTokens
          : tokens.filter(t => !t.id.includes('T'))
            .concat(newTokens.filter(t1 => !tokens.find(t2 => t2.id === t1.id))));

      // add value walletMintedCount if matches wallet and exists
      if (data.user && data.user.length) {
        if (wallet !== null) {
          const adjustedWallet = wallet.toLowerCase().trim();
          if (adjustedWallet === data.user[0].id.toLowerCase().trim()) {
            const newTokensCount = parseInt(data.user[0].restaurantTokenCount);
            if (newTokensCount > tokensCount) setTokensCount(newTokensCount);
          }
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (dataStaked && dataStaked.newRestaurantStakes) {
      const newTokens = [...dataStaked.newRestaurantStakes.map(x => parseGraphObject(x, true))]
      setStaked(
        bp768px
          ? newTokens
          : staked.filter(t => !t.id.includes('T'))
            .concat(newTokens.filter(t1 => !staked.find(t2 => t2.id === t1.id))));

      setStakedCount(parseInt(data?.user[0]?.newRestaurantStakedCount));
    }
  }, [dataStaked]);


  const [stakedOld, setStakedOld] = useState([]);
  useEffect(() => {
    if (dataStakedOld && dataStakedOld.restaurantStakes) {
      const newTokens = [...dataStakedOld.restaurantStakes.map(x => parseGraphObject(x, true))]
      setStakedOld(newTokens);
    }
  }, [dataStakedOld]);

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="home container mx-auto p-1 sm:p-5">
      <Header />
      <div className={`mt-5 mb-5 text-center sm:flex flex-wrap justify-center`}>
        <a href="/" className="btn-frame">Home</a>
        <a href="/barn" className="btn-frame">Barn</a>
        <a href="/war" className="btn-frame">War</a>
        <a href="/supergrass" className="btn-frame">Supergrass</a>
        <WoodButton
          title={"MIGRATE TO V2"}
          onClick={() => setModalOpen(true)}
        />
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 xl:col-span-1 mt-5">
          <RestMinting
            wallet={wallet}
            chain={chain}
            stats={dataGlobal?.stat}
            reload={() => {
              refetch();
              refetchStaked();
              refetchGlobal();
              reload();
            }}
            woolBalance={wool}
          />

          <div className="mt-5">
            <Links />
          </div>
        </div>
        <div className="col-span-2 xl:col-span-1 mt-5">
          <Restaurants
            wallet={wallet}
            chain={chain}
            wool={wool}
            tokens={tokens}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageCount={Math.ceil(tokensCount / pageSize)}
            page={offset / pageSize + 1}
            changePage={changePage}
            fetching={loading}
            staked={staked}
            pageSizeStaked={pageSizeStaked}
            setPageSizeStaked={setPageSizeStaked}
            pageCountStaked={Math.ceil(stakedCount / pageSizeStaked)}
            pageStaked={offsetStaked / pageSizeStaked + 1}
            changePageStaked={changePageStaked}
            fetchingStaked={loadingStaked}
            reload={() => {
              refetch();
              refetchStaked();
              refetchGlobal();
              reload();
            }}
          />
        </div>
      </div>
      <br />
      <div className="mt-5 mb-5 text-right">
        <a href="/tos" className="btn-frame btn-small">Terms of Service</a>
      </div>
      <MigrationModal
        wallet={wallet}
        chain={chain}
        modalOpen={modalOpen}
        isRest
        ids={tokens?.map(t => t.number)?.concat(stakedOld?.map(s => s.number))}
        noStaked={stakedOld?.length === 0}
      />
    </div>
  )
}

export default Rest
