import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { WalletHook } from "./utils/ethereum";
import { useApollo } from "./utils/apollo";
import Modal from "react-modal";
import War from "./War";
const WarProvider = () => {
    const client = useApollo();

    const { wallet, chain } = WalletHook();

    useEffect(() => {
        Modal.setAppElement("body");
    }, []);

    return (
        <ApolloProvider client={client}>
            <War wallet={wallet} chain={chain} />
        </ApolloProvider>
    );
};

export default WarProvider;
