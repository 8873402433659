import React from 'react'
import RestNft from './RestNft'
import { parseGraphObject } from '../utils/query'
import classes from "../design/Tokenlist.module.scss";
import Pagination from './Pagination';

const RestList = ({
  fetching,
  active,
  items,
  pageSize,
  setPageSize,
  pageCount,
  page,
  changePage,
  selected,
  toggleSelected,
  backgroundImage,
}) => {

  const isItemSelected = (id) => !!selected.find((el) => el.id === id);

  const handleClick = (item) => {
    if (!active || fetching) return
    toggleSelected(item, !isItemSelected(item.id))
  }
  return (
    <div className="w-full" style={{
      opacity: active && !fetching ? '1.0' : '0.5'
    }}>
      <div className={`p-2 bg-black bg-opacity-20 " + ${classes.tokenlist} ${items.length === 0 ? "mb-8" : "mb-4"}`} style={{ backgroundImage: `url("${backgroundImage}")` }}>
        {items.length > 0 ? (
          <div className={classes.list}>
            {items.map((item) => {
              return (
                <RestNft
                  key={item.id}
                  active={active}
                  rest={parseGraphObject(item)}
                  itemId={item.id} // NOTE: itemId is required for track items
                  title={item.id}
                  onClick={() => handleClick(item)}
                  selected={isItemSelected(item.id)}
                />
              )
            })}
          </div>
        ) : (
          <div className="text-center bg-white bg-opacity-50">
            No Tokens
          </div>
        )}
      </div>
      {items.length > 0 &&
        <Pagination
          pageCount={pageCount}
          page={page}
          onChange={changePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          className={"mb-8"}
          isRestaurant
        />
      }
    </div>

  )

}

export default RestList