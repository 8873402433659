import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import useMediaQuery from '../hooks/useMediaQuery'
import Container from './Container'

const OutcomeModal = ({
  modalIsOpen,
  outcomes,
  closeModal
}) => {
  const bp360px = useMediaQuery(360);
  const bp768px = useMediaQuery(768);

  const [outcomeIndex, setOutcomeIndex] = useState(0)

  useEffect(() => {
    setOutcomeIndex(0)
  }, [outcomes])

  const previous = () => {
    setOutcomeIndex(Math.max(outcomeIndex - 1, 0))
  }

  const next = () => {
    setOutcomeIndex(Math.min(outcomeIndex + 1, outcomes.length - 1))
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          background: 'transparent',
        },
        overlay: { zIndex: 1000 }
      }}
      contentLabel="Outcome Modal"
      onRequestClose={closeModal}
    >
      <Container>
        <div className="w-full flex flex-col items-center overflow-hidden" style={{ width: bp768px ? 650 : "auto" }}>
          <div className="font-console text-xl sm:text-2xl drop-text text-center">
            And here's what happened...
          </div>
          <div className="w-full flex items-center justify-around gap-2">
            {outcomeIndex > 0 ? (
              <div
                onClick={() => previous()}
                className="cursor-pointer mr-3"
              >
                <img src="/images/arrow-left.svg" alt="previous" style={{
                  height: '30px',
                  width: '30px',
                }} />
              </div>
            ) : (
              <div style={{ width: '30px' }} className="mr-3"></div>
            )}
            {outcomes.length > 0 && (
              <div className="w-full flex flex-col items-center justify-around">
                {outcomes[outcomeIndex].source && (
                  <img
                    src={outcomes[outcomeIndex].source}
                    alt=''
                    width={bp360px ? 64 * 4 : "100%"}
                    height={bp360px ? 64 * 4 : "auto"}
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </div>
            )}
            {outcomeIndex < outcomes.length - 1 ? (
              <div className="cursor-pointer ml-3"
                onClick={() => next()}
              >
                <img src="/images/arrow-right.svg" alt="next" style={{
                  width: '30px',
                  height: '30px'
                }} />
              </div>
            ) : (
              <div style={{ width: '30px' }} className="ml-3"></div>
            )}
          </div>
          {outcomes.length > 0 &&
            <>
              <div className="text-center font-console text-lg mt-5 break-all sm:break-normal">
                {outcomes[outcomeIndex].message.split(" ").map((word, i) => {
                  return (
                    <span key={i.toString()} className={i === outcomes[outcomeIndex].message.split(" ").length - 1 ? "break-all sm:break-normal" : ""}>
                      {i > 0 && " "}
                      {word}
                    </span>
                  )
                })}
              </div>
              {outcomes[outcomeIndex].link && (
                <a className="underline font-console text-lg text-red" href={outcomes[outcomeIndex].link} target="_blank" rel="noreferrer">
                  {outcomes[outcomeIndex].linkMessage}
                </a>
              )}
            </>
          }
        </div>
      </Container>
    </Modal>
  )
}

export default OutcomeModal
