import React, { useState } from 'react'
import Woolf from './Woolf'
import { parseGraphObject } from '../utils/query'
import classes from "../design/Tokenlist.module.scss";
import Pagination from "../components/Pagination";

const TokenList = ({
  title,
  fetching,
  active,
  items,
  pageSize,
  setPageSize,
  pageCount,
  page,
  changePage,
  selected,
  toggleSelected,
  stats,
  backgroundImage,
}) => {
  const isItemSelected = (id) => !!selected.find((el) => el.id === id);

  const handleClick = (item) => {
    if (!active || fetching) return
    toggleSelected(item, !isItemSelected(item.id))
  }

  return (
    <div className="w-full" style={{
      opacity: active && !fetching ? '1.0' : '0.5'
    }}>
      <div className="text-center text-black">
        {title}
      </div>
      <div
        className={`p-2 bg-black bg-opacity-20 ${classes.tokenlist} ${items.length === 0 ? "mb-8" : "mb-4"}`}
        style={{ backgroundImage: `url("${backgroundImage}")` }}
      >
        {items.length > 0 ? (
          <div className={classes.list}>
            {items.map((item, i) => {
              return (
                <Woolf
                  key={item.id}
                  active={active}
                  woolf={parseGraphObject(item)}
                  itemId={item.id} // NOTE: itemId is required for track items
                  title={item.id}
                  onClick={() => handleClick(item)}
                  selected={isItemSelected(item.id)}
                  stats={stats}
                />
              )
            })}
          </div>
        ) : (
          <div className="text-center bg-white bg-opacity-50">
            No Tokens
          </div>
        )}
      </div>
      {items.length > 0
        && pageCount !== undefined
        && page !== undefined &&
        <Pagination
          pageCount={pageCount}
          page={page}
          onChange={changePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          lastID={items[items.length - 1].id}
          className={"mb-8"}
        />
      }

    </div>
  )

}

export default TokenList