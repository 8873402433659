import Header from './design/Header';
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { QUERY, QUERY_GLOBAL, } from './utils/query'
import { getAllowance } from './utils/erc20';
import { BigNumber, ethers } from 'ethers';
import Chests from './components/Chests';

const War = ({ wallet, chain }) => {
    const { data, loading, refetch } = useQuery(
        QUERY,
        {
            variables: {
                wallet: wallet || '',
            },
        }
    );
    const { data: dataGlobal, refetch: refetchGlobal } = useQuery(
        QUERY_GLOBAL,
        {
            variables: {
                wallet: wallet || '',
            },
            pollInterval: 2500,
        }
    );

    const [approvedChest, setApprovedChest] = useState(false);
    const [allowanceChest, setAllowanceChest] = useState();

    useEffect(() => {
        if (wallet) {
            getAndSetAllowance(process.env.REACT_APP_WOOL, process.env.REACT_APP_WAR_CHST, setAllowanceChest);
        }
    }, [wallet]);

    const getAndSetAllowance = (token, spender, setter) => {
        getAllowance(token, wallet, spender)
            .then(response => {
                setter(response);
            })
            .catch(err => {
                console.error(err);
            });
    }

    useEffect(() => {
        if (allowanceChest && allowanceChest.gt(BigNumber.from(0))) setApprovedChest(true);
        else setApprovedChest(false);
    }, [allowanceChest]);

    const reloadLocal = () => {
        refetch();
        refetchGlobal();
    }

    return (
        <div className="home container mx-auto p-1 sm:p-5">
            <Header />
            <div className="mt-5 mb-5 text-center lg:flex flex-wrap justify-center">
                <a href="/" className="btn-frame">Home</a>
                <a href="/barn" className="btn-frame">Barn</a>
                <a href="/game" className="btn-frame">Restaurants</a>
                <a href="/supergrass" className="btn-frame">Supergrass</a>
            </div>
            <Chests
                wallet={wallet}
                chain={chain}
                stats={dataGlobal?.stat}
                warchestTokens={data?.user[0]?.warchestTokens}
                warchestCountCommon={data?.user[0]?.warchestTokenCountCommon}
                warchestCountEpic={data?.user[0]?.warchestTokenCountEpic}
                warchestCountLegendary={data?.user[0]?.warchestTokenCountLegendary}
                warchestCountCommonOpen={data?.user[0]?.warchestTokenCountCommonOpen}
                warchestCountEpicOpen={data?.user[0]?.warchestTokenCountEpicOpen}
                warchestCountLegendaryOpen={data?.user[0]?.warchestTokenCountLegendaryOpen}
                warSecretCount={data?.user[0]?.warSecretCount}
                approved={approvedChest}
                setApproved={setApprovedChest}
                reload={reloadLocal}
            />
            <br />
            <div className="mt-5 mb-5 text-right">
                <a href="/tos" className="btn-frame btn-small">Terms of Service</a>
            </div>
        </div>
    )
}

export default War;
