const abi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_tokenId",
                "type": "uint256"
            }
        ],
        "name": "openChest",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

export default abi;