import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import WAR_CHST_ABI from "./abi/warChst.abi";

function getContract(abi = WAR_CHST_ABI, address) {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(address ? address : process.env.REACT_APP_WAR_CHST, abi, signer);
}

const getGasLimit = (gasEstimate) => {
    return gasEstimate.mul(BigNumber.from(15)).div(BigNumber.from(10));
}

const getGasPrice = async () => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");
    return Math.round(await provider.getGasPrice() * 1.3);
}

export const approve = async () => {
    const contract = getContract();
    return await contract.setApprovalForAll(process.env.REACT_APP_WAR_CHST_OPEN, true);
};

export const isApproved = async (wallet) => {
    const contract = getContract();
    return await contract.isApprovedForAll(wallet, process.env.REACT_APP_WAR_CHST_OPEN);
};

const MINT_PRICE = "500";

export const mint = async (amount, typeIndex) => {
    const contract = getContract();
    let ethCost = utils.parseUnits(MINT_PRICE, "ether").mul(BigNumber.from(amount));

    var gasEstimate;
    var estimateError;
    await contract.estimateGas.mint(
        amount,
        typeIndex,
        { value: typeIndex === 0 ? ethCost : BigNumber.from(0), })
        .then(result => gasEstimate = result, error => estimateError = error);
    if (estimateError) return estimateError;

    return await contract.mint(
        amount,
        typeIndex,
        {
            gasLimit: getGasLimit(gasEstimate),
            gasPrice: await getGasPrice(),
            value: typeIndex === 0 ? ethCost : BigNumber.from(0),
        });
};

export const parseMint = (receipt) => {
    const warChest = new utils.Interface(WAR_CHST_ABI);

    const results = {};

    for (let x in receipt.logs) {
        try {
            const log = warChest.parseLog(receipt.logs[x]);
            results[log.args.tokenId.toString()] = {
                tokenId: log.args.tokenId,
                recipient: log.args.to,
            };
        } catch (e) { }
    }

    return results;
};

export const exchangeCommonForEpic = async (ids) => {
    const contract = getContract();
    var gasEstimate;
    var estimateError;
    await contract.estimateGas.exchangeCommonForEpic(ids).then(result => gasEstimate = result, error => estimateError = error);
    if (estimateError) return estimateError;
    return await contract.exchangeCommonForEpic(ids, {
        gasLimit: getGasLimit(gasEstimate),
        gasPrice: await getGasPrice()
    });
};