import Header from '../design/Header';
import Frame from '../design/Frame';
import MetaDecorator from '../design/MetaDecorator';
import { TwitterTimelineEmbed, TwitterTweetEmbed } from "react-twitter-embed";
import ImageLicense from '../assets/images/public-domain.png';
import ImageGame from '../assets/images/FFWG_logo.png';
import ImageCatch from '../assets/images/wolves_play_catch.gif';
import ImageSheep from '../assets/images/sheep.png';
import ImageShepherd from '../assets/images/shepherd_smile.gif';
import ImagePhaseTwo from '../assets/images/ffwg_p2_live.jpeg';
import ImageWoolf from '../assets/images/wolf.png';
import ImageSocialDiscord from '../assets/images/social_discord.png';
import ImageSocialTwitter from '../assets/images/social_twitter.png';
import './home.css';

const Home = () => {
    return (
        <div className={`home container mx-auto p-1 sm:p-4`}>
            <MetaDecorator
                description="Fast Food Wolf Game on Polygon"
                title="Fast Food Wolf Game"
                imageUrl="https://www.fastfoodwolfgame.com/banner.jpg"
                imageAlt="Fast Food Wolf Game Banner"
            />

            <Header />

            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12 md:col-span-7">
                    <Frame>
                        <div className="mt-1">
                            <h1 className="mt-2 text-3xl sm:text-5xl">Frens,</h1>
                            <p>Fast Food Wolf Game is currently transitioning to Phase 2. Read all about it in our <a href="/whitepaper">Whitepaper</a>.</p>
                            <div className="text-center mt-2 mb-4">
                                <a href="/whitepaper"><img className="mx-auto" src={ImagePhaseTwo} alt="" /></a>
                                <a href="/whitepaper" className="btn-frame btn-small">Whitepaper: Phase 2</a>
                            </div>
                            <p>Thousands of Sheep and Wolves, soon Restaurants selling Fast Food, compete on-chain for $FFWOOL, with high stakes.
                                Make sure to join the FFWG Gang now to be part of all the exciting things coming to the
                                Fast Food Metaverse.
                            </p>
                            <div className="text-center mb-4">
                                <img className="img-sheep" src={ImageSheep} alt="" />
                                <img className="img-shepherd" src={ImageShepherd} alt="" />
                                <img className="img-woolf" src={ImageWoolf} alt="" />
                            </div>
                            <div className="text-center mb-4">
                                <a href="/game" className="btn-frame">Mint now</a>
                            </div>
                        </div>
                    </Frame>

                    <Frame className="mt-3">
                        <h2>Join the first burgercore community in the Metaverse! 🍔 Aesthetic af</h2>
                        <p>Stay tuned and follow us on <a href="https://twitter.com/FFWolfGame" target="_blank" rel="noreferrer">Twitter</a> and join our <a href="https://discord.gg/ffwolfgame" target="_blank" rel="noreferrer">Discord</a>!</p>
                        <TwitterTweetEmbed
                            tweetId={"1468025196732895232"}
                            placeholder={"Loading tweet..."}
                            options={{
                                align: 'center'
                            }}
                        />
                    </Frame>

                    <Frame className="mt-3">
                        <div className="text-center"><img className="img-game" src={ImageGame} alt="" /></div>
                        <p className="text-center mt-5">
                            <a href="/whitepaper" className="btn-frame">Whitepaper</a>
                            <img className="public-domain" src={ImageLicense} alt="" />
                            <a href="/polygon" className="btn-frame">Polygon</a>
                        </p>
                    </Frame>

                </div>
                <div className="col-span-12 md:col-span-5">

                    <Frame className={"md:p-0 lg:p-[20px]"}>
                        <div className="text-center mb-1">
                            <h2 className="mb-4">Let's GO!</h2>
                            <a href="/barn" className="btn-frame">Barn</a>
                            <a href="/game" className="btn-frame">Restaurants</a>
                            <a href="/war" className="btn-frame">War</a>
                            <a href="/supergrass" className="btn-frame">Supergrass</a>
                        </div>
                    </Frame>
                    <Frame className="mt-3">
                        <h2 className='text-center'>Join our burgercore community</h2>
                        <div className="text-center">
                            <a className="btn-frame btn-small" title="Join us on Discord" href="https://discord.gg/ffwolfgame" target="_blank" rel="noreferrer"><img src={ImageSocialDiscord} alt="" /> ffwolfgame</a>
                            <a className="btn-frame btn-small" title="Follow us on Twitter" href="https://twitter.com/FFWolfGame" target="_blank" rel="noreferrer"><img src={ImageSocialTwitter} alt="" /> @FFWolfGame</a>
                        </div>
                    </Frame>
                    <Frame className="mt-3">
                        <div className="text-center" style={{ marginTop: "-4.2rem" }}><img src={ImageCatch} alt="" /></div>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="FFWolfGame"
                            options={{ height: 700 }}
                            theme="light"
                            noHeader={true}
                            noBorders={true}
                            noFooter={true}
                        ></TwitterTimelineEmbed>
                    </Frame>

                </div>
            </div>
            <div className="mt-5 mb-5 text-right">
                <a href="/tos" className="btn-frame btn-small">Terms of Service</a>
            </div>
        </div>
    )
}

export default Home;
