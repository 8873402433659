import { Contract, utils, BigNumber } from "ethers";
import { parseBigNumber, parseBigNumberRaw, _getProvider } from "./ethereum";
import SUPERGRASS_ABI from "./abi/superGrass.abi";
import WOOL_ABI from "./abi/wool.abi";

function getContract(abi = SUPERGRASS_ABI) {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(process.env.REACT_APP_SUPERGRASS, abi, signer);
}

function getWoolContract(abi = WOOL_ABI) {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(process.env.REACT_APP_WOOL, abi, signer);
}

const getGasLimit = (gasEstimate) => {
    return gasEstimate.mul(BigNumber.from(14)).div(BigNumber.from(10));
}

const getGasPrice = async () => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");
    return Math.round(await provider.getGasPrice() * 1.3);
}

export const buy = async () => {
    const contract = getContract();
    var gasEstimate;
    var estimateError;
    await contract.estimateGas.buy().then(result => gasEstimate = result, error => estimateError = error);
    if (estimateError) return estimateError;

    return await contract.buy({
        gasLimit: getGasLimit(gasEstimate),
        gasPrice: await getGasPrice(),
    });
};

export const claim = async () => {
    const contract = getContract();
    var gasEstimate;
    var estimateError;
    await contract.estimateGas.claim().then(result => gasEstimate = result, error => estimateError = error);
    if (estimateError) return estimateError;

    return await contract.claim({
        gasLimit: getGasLimit(gasEstimate),
        gasPrice: await getGasPrice(),
    });
};

export const getWinner = async () => {
    const contract = getContract();
    return await contract.winner();
};

export const getClaimed = async () => {
    const contract = getContract();
    return await contract.claimed();
};

export const getLastTs = async () => {
    const contract = getContract();
    return await contract.lastTs();
};

export const getClaimPeriod = async () => {
    const contract = getContract();
    return await contract.CLAIM_PERIOD();
};

export const getPeriod = async () => {
    const contract = getContract();
    return await contract.PERIOD();
};

export const getRound = async () => {
    const contract = getContract();
    return await contract.ROUND();
};

export const getTicketSize = async () => {
    const contract = getContract();
    return parseBigNumberRaw(await contract.TICKET_SIZE());
};

export const getRoundReward = async () => {
    const contract = getContract();
    return parseBigNumberRaw(await contract.ROUND_REWARD());
};

export const getBalance = async () => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const wcontract = getWoolContract();
    return await wcontract.balanceOf(process.env.REACT_APP_SUPERGRASS) / 1000 / 1000 / 1000;
}
