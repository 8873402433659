import Header from '../design/Header';
import Frame from '../design/Frame';
import useMediaQuery from '../hooks/useMediaQuery';

const Polygon = () => {
    const bp768px = useMediaQuery(768);

    return (
        <>
            <div className="container mx-auto p-1 sm:p-4">
                <Header />
                <div className="mt-5 mb-5 text-center">
                    <a href="/" className="btn-frame">Home</a>
                </div>
                <Frame>
                    <h1 className="text-center mb-5 text-3xl sm:text-5xl">Polygon</h1>
                    <h2 className="text-center">Blockchain</h2>
                    <p className="mb-5">Wolf.Game pioneered new types of NFT mechanics BUT Fast Food Wolf Game brings it to Polygon.
                        Polygon (formerly known as Matic Network) is a protocol and a framework for building and connecting Ethereum-compatible blockchain networks. It is centered around the MATIC token, which is used for governance, staking, and gas fees.
                        Its goal is to solve several of Ethereums current limitations, e.g. low throughput and high gas fees.</p>
                    <p className="mb-5">Besides its technical advantages as an Ethereum interoperable layer 2 scaling solution there is also a very good integration of Polygon into the existing NFT ecosystem that we can leverage. Many awesome projects are already doing just that - e.g. the prominent Metaverse Decentraland runs a big part of it's wearable market on Polygon and integrates beautifully with OpenSea.</p>
                    <h2 className="text-center text-xl sm:text-3xl mt-5">The tl;dr:</h2>
                    <ul className="list-disc px-5">
                        <li className="mt-3">Launching FFWG on Polygon promises much lower gas fees than launching on Ethereum.</li>
                        <li className="mt-3">We love Etherum and all it has done, but our community is an even bigger fan of getting more value for our coins.</li>
                        <li className="mt-3">People familiar with Ethereum can use their existing apps (e.g. their MetaMask wallet) and basically just switch network to participate.</li>
                        <li className="mt-3">The OG of all NFT marketplaces - OpenSea - natively supports Polygon NFTs and thus provides an instant secondary market once Gen 0 mint starts.</li>
                    </ul>
                    <h2 className="text-center text-xl sm:text-3xl mt-5">How to prepare for mint</h2>
                    <p className="mb-5">A mint on Polygon basically works like any mint on Ethereum. There are a few things to consider though:</p>
                    <ul className="list-disc px-5">
                        <li className="mt-3">
                            <p className="mb-5"><strong>You can use your regular Ethereum wallet, e.g. MetaMask, but you have to switch to the Polygon network.</strong>
                                {" "}See the official polygon documentation on <a href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/" target="_blank" rel="noreferrer">how to configure Polygon on Metamask</a> and this video tutorial for more info (we skipped the intro where the YouTube set up a new MetaMask account, but feel free to go back to the start after hitting the play button):</p>
                            <iframe
                                className="mx-auto mt-5 mb-4"
                                width={bp768px ? "560" : "100%"}
                                height={bp768px ? "315" : "auto"}
                                src="https://www.youtube.com/embed/bEILVGkmDrI?start=149"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                            <p className="mt-3">Your Polygon wallet address will even be the same as your existing Ethereum wallet address - how cool is that?</p>
                            <p className="mb-5">Pro tip: don't be confused when you are searching for Polygon tutorials and they refer to it as Matic, or if an URL uses Matic instead of Polygon. Polygon was formerly known as Matic Network and has rebranded early 2021 to Polygon with MATIC token. All legacy URLs will keep working in the foreseeable future.</p>
                        </li>
                        <li className="mt-3">
                            <p className="mb-5"><strong>Mint fees will be paid in Polygons native token MATIC instead of ETH.</strong>
                                {" "}If you already have Ethereum based funds this means you have to bridge the necessary funds to your Polygon (MATIC) wallet.</p>
                            <p className="mb-5">Bridging basically means you convert Ethereum based funds to MATIC tokens on the Polygon network. You can use the official Polygon (MATIC) Bridge at <a href="https://wallet.polygon.technology/" target="_blank" rel="noreferrer">wallet.polygon.technology</a> (click on "Polygon Bridge"). You can read all about the process in <a href="https://medium.com/stakingbits/guide-to-using-the-matic-bridge-to-bridge-tokens-from-ethereum-to-polygon-c3e66cf3d334" target="_blank" rel="noreferrer">this guide on using the Matic Bridge</a>.</p>
                            <p className="mb-5">If you don't yet have Ethereum based funds or want to directly fund the Polygon (MATIC) wallet using FIAT funds your best way to go about that might be to buy MATIC on your most favorite exchange that supports MATIC. Coingecko provides a list of <a href="https://www.coingecko.com/en/coins/polygon#markets" target="_blank" rel="noreferrer">exchanges that support Polygon/MATIC</a>. You can buy MATIC directly on your preferred exchange and then withdraw directly to your Polygon (MATIC) wallet.</p>
                        </li>
                        <li className="mt-3">
                            <p className="mb-5"><strong>Be prepared and ready!</strong> Please consider that some of the steps might take some time. We strongly advise to start preparing now!</p>
                            <p className="mb-5">Keep your funded wallet ready, stay tuned and follow us on <a href="https://twitter.com/FFWolfGame" target="_blank" rel="noreferrer">Twitter</a> and join our <a href="https://discord.gg/ffwolfgame" target="_blank" rel="noreferrer">Discord</a> to be the first to know when mint happens!</p>
                        </li>
                    </ul>
                </Frame>
            </div>
        </>
    )
}

export default Polygon;
