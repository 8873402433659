import { useEffect, useState } from "react";
import WoodButton from "./WoodButton";
import Container from "./Container";
import TokenList from "./TokenList";
import LoadingModal from "./LoadingModal";
import OutcomeModal from "./OutcomeModal";
import EthereumInteraction from "./EthereumInteraction";
import { parseClaims, stake, claim, rescue } from "../utils/barn";
import { parseBigNumber, watchTransaction } from "../utils/ethereum";
import { BigNumber } from "ethers";
import BackgroundGrass from "../assets/images/game-grass.png";
import BackgroundBurgers from "../assets/images/game-burgers.png";
import BackgroundGold from "../assets/images/game-gold.png";
import useMediaQuery from "../hooks/useMediaQuery";
import { approve } from "../utils/woolf";
import handleResult from "../utils/handleResult";

const Staking = ({
    wallet,
    chain,
    wool,
    unstaked,
    unstakedPageSize,
    setUnstakedPageSize,
    unstakedPageCount,
    unstakedPage,
    changeUnstakedPage,
    stakes,
    stats,
    reload,
    fetching,
    approved,
    setApproved,
    claimableFromBarn,
    claimableFromPack,
}) => {
    const bp450px = useMediaQuery(450);

    const [loadingScenes, setLoadingScenes] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [operation, setOperation] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectedAllUnStaked, setSelectedAllUnStaked] = useState(false);
    const [selectedAllStaked, setSelectedAllStaked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [transacting, setTransacting] = useState(false);

    const stopLoading = () => {
        setLoading(false);
        setTransacting(false);
    }

    const onApprove = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await approve();
            setError(handleResult(result, "Approve"));
            if (!("hash" in result)) { stopLoading(); return; }

            let hash = result.hash;
            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Approve failed. Check transaction.");
                }

                setApproved(true);
                setTimeout(async () => {
                    await presentApproval();
                    setLoading(false);
                    setTransacting(false);
                }, 2000);
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
            setTransacting(false);
        }
    };

    const presentApproval = async () => {
        const o = [];
        o.push({
            message: "Successful Approval",
            source: "/images/claimed-pack.gif",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    useEffect(() => {
        if (selected.length === 0) {
            setOperation(null);
            setSelectedAllStaked(false);
            setSelectedAllUnStaked(false);
        }
    }, [wallet, stakes]);

    const onStake = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await stake(selected.map((x) => x.number));
            setError(handleResult(result, "Stake"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Stake failed. Check transaction.");
                }
                const o = [];
                for (let i = 0; i < selected.length; i++) {
                    const message = selected[i].isSheep ? `Sheep #${selected[i].number} entered the barn` : `Wolf #${selected[i].number} joined the pack`;
                    const source = selected[i].isSheep ? "/images/staked-barn.gif" : "/images/staked-pack.gif";
                    o.push({ message, source });
                }
                setOutcomes(o);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            /*  console.log(e); */
            setLoading(false);
        }
    };

    const onClaim = async (unstake, recover) => {
        setLoading(true);
        setError(null);
        try {
            var result;
            if (recover) result = await rescue(selected.map((x) => x.number));
            else result = await claim(selected.map((x) => x.number), unstake);
            setError(handleResult(result, recover ? "Recovering NFTs" : "Unstake"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError(recover ? "Recovering NFTs failed. Check transaction." : "Unstake failed. Check transaction.");
                }
                presentOutcomes(receipt);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const presentOutcomes = (receipt) => {
        const claims = parseClaims(receipt);
        const o = [];
        for (let i = 0; i < claims.length; i++) {
            const token = selected.find((el) => el.number === claims[i].tokenId.toString());
            if (token.isSheep) {
                if (claims[i].unstaked) {
                    if (claims[i].earned.eq(BigNumber.from(0))) {
                        o.push({
                            //message: `Sheep #${token.number} left the barn, but all its $FFWOOL was stolen by wolves!`,
                            message: `Sheep #${token.number} left the barn`,
                            source: "/images/unstaked-notsafe.gif",
                        });
                    } else {
                        o.push({
                            message: `Sheep #${token.number} left the barn and evaded the Wolves, earning ${parseBigNumber(claims[i].earned)} $FFWOOL`,
                            source: "/images/unstaked-safe.gif",
                        });
                    }
                } else {
                    o.push({
                        message: `Sheep #${token.number} was sheared for ${parseBigNumber(claims[i].earned)} $FFWOOL, after paying a 20% tax to the Wolves.`,
                        source: "/images/sheared.gif",
                    });
                }
            } else {
                if (claims[i].unstaked) {
                    o.push({
                        //message: `Wolf #${token.number} left the pack, and received ${parseBigNumber(claims[i].earned)} $FFWOOL!`, //Commenting our for forcing unstake
                        message: `Wolf #${token.number} left the pack`,
                        source: "/images/unstaked-pack.gif",
                    });
                } else {
                    o.push({
                        message: `Wolf #${token.number} collected a tax of ${parseBigNumber(claims[i].earned)} $FFWOOL!`,
                        source: "/images/claimed-pack.gif",
                    });
                }
            }
        }
        setOutcomes(o);
    };

    const approveBtn = () => {
        return (
            <div className={bp450px ? "flex justify-center" : ""}>
                <WoodButton
                    size="small"
                    title={"Approve"}
                    loading={loading}
                    onClick={async () => {
                        await onApprove();
                    }}
                />
            </div>
        )
    }

    return (
        <Container>
            <div className="">
                <div className="text-center">
                    <div className="uppercase font-bold">In your wallet</div>
                    <div className="text-black text-xl sm:text-3xl">{parseBigNumber(wool, 0)} <small>$FFWOOL</small></div>
                </div>
                <div className="text-center mt-3">
                    <div className="uppercase font-bold text-lg">Claimable</div>
                    <div className="text-black text-sm sm:text-xl">{isNaN(claimableFromBarn + claimableFromPack) ? "?" : (claimableFromBarn + claimableFromPack).toFixed(2)} <small>$FFWOOL</small></div>
                </div>
                <EthereumInteraction wallet={wallet} chain={chain}>
                    {approved
                        ?
                        <>
                            {stakes.length > 0 &&
                                <WoodButton
                                    size="small"
                                    title={"SHEAR ALL $FFWOOL"}
                                    loading={loading}
                                    onClick={() => {
                                        const isClaimingSheep = !!stakes.find((el) => el.isSheep);
                                        const isClaimingWolf = !!stakes.find((el) => !el.isSheep);
                                        const scenes = [];
                                        if (isClaimingSheep)
                                            scenes.push({
                                                message: "Shearing sheep",
                                                source: "/images/shearing.gif",
                                            });
                                        if (isClaimingWolf)
                                            scenes.push({
                                                message: "Collecting 20% tax",
                                                source: "/images/claiming-pack.gif",
                                            });
                                        setLoadingScenes(scenes);
                                        onClaim(false);
                                    }}
                                />
                            }
                        </>
                        : approveBtn()
                    }
                    {error && <div className="text-xl text-red font-console text-center">{error}</div>}
                    <div className="text-xl sm:text-3xl font-bold uppercase mt-5 text-center">Unstaked</div>
                    <TokenList
                        title={"Spectators"}
                        fetching={fetching}
                        backgroundImage={BackgroundGrass}
                        active={operation !== "CLAIM"}
                        items={unstaked}
                        pageSize={unstakedPageSize}
                        setPageSize={setUnstakedPageSize}
                        pageCount={unstakedPageCount}
                        page={unstakedPage}
                        changePage={changeUnstakedPage}
                        selected={selected}
                        toggleSelected={(item, select) => {
                            if (operation !== "STAKE" && operation !== null) return;
                            setOperation("STAKE");
                            setSelected((current) => {
                                return select ? current.concat(item) : current.filter((el) => el.id !== item.id);
                            });
                        }}
                    />
                    <div className="text-xl sm:text-3xl font-bold uppercase mt-2 text-center">Staked</div>
                    {fetching
                        ? <div className="text-center text-red font-console">Fetching...</div>
                        :
                        <div className="w-full flex flex-col justify-center items-center gap-2">
                            <TokenList
                                title={"Wage Slave Barn"}
                                backgroundImage={BackgroundBurgers}
                                active={operation !== "STAKE"}
                                items={stakes?.filter((el) => el.isSheep)}
                                selected={selected}
                                stats={stats}
                                toggleSelected={(item, select) => {
                                    if (operation !== "CLAIM" && operation !== null) return;
                                    setOperation("CLAIM");
                                    setSelected((current) => {
                                        return select ? current.concat(item) : current.filter((el) => el.id !== item.id);
                                    });
                                }}
                            />
                            <TokenList
                                title={"Fast Food Wolf Mafia"}
                                backgroundImage={BackgroundGold}
                                active={operation !== "STAKE"}
                                items={stakes?.filter((el) => !el.isSheep)}
                                selected={selected}
                                stats={stats}
                                toggleSelected={(item, select) => {
                                    if (operation !== "CLAIM" && operation !== null) return;
                                    setOperation("CLAIM");
                                    setSelected((current) => {
                                        return select ? current.concat(item) : current.filter((el) => el.id !== item.id);
                                    });
                                }}
                            />
                            <div className="w-full flex flex-col md:flex-row justify-center items-center gap-1">
                                {!fetching &&
                                    <>
                                        {approved
                                            ?
                                            <div>
                                                {operation === "CLAIM" && (
                                                    <div>
                                                        <div className={bp450px ? "flex justify-center" : ""}>
                                                            <WoodButton
                                                                size="small"
                                                                title={"Unstake"}
                                                                loading={loading}
                                                                onClick={() => {
                                                                    const isUnstakingSheep = !!selected.find((el) => el.isSheep);
                                                                    const isUnstakingWolf = !!selected.find((el) => !el.isSheep);
                                                                    const scenes = [];
                                                                    if (isUnstakingSheep)
                                                                        scenes.push({
                                                                            message: "Leaving the barn",
                                                                            source: "/images/unstaking-barn.gif",
                                                                        });
                                                                    if (isUnstakingWolf)
                                                                        scenes.push({
                                                                            message: "Leaving the pack",
                                                                            source: "/images/unstaking-pack.gif",
                                                                        });
                                                                    setLoadingScenes(scenes);
                                                                    onClaim(true);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={bp450px ? "flex justify-center" : ""}>
                                                            <WoodButton
                                                                size="small"
                                                                title={"Recover staked NFTs"}
                                                                loading={loading}
                                                                onClick={() => {
                                                                    const isUnstakingSheep = !!selected.find((el) => el.isSheep);
                                                                    const isUnstakingWolf = !!selected.find((el) => !el.isSheep);
                                                                    const scenes = [];
                                                                    if (isUnstakingSheep)
                                                                        scenes.push({
                                                                            message: "Leaving the barn",
                                                                            source: "/images/unstaking-barn.gif",
                                                                        });
                                                                    if (isUnstakingWolf)
                                                                        scenes.push({
                                                                            message: "Leaving the pack",
                                                                            source: "/images/unstaking-pack.gif",
                                                                        });
                                                                    setLoadingScenes(scenes);
                                                                    onClaim(true, true);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {operation === "STAKE" && (
                                                    <WoodButton
                                                        size="small"
                                                        title={"STAKE"}
                                                        loading={loading}
                                                        onClick={() => {
                                                            const isStakingSheep = !!selected.find((el) => el.isSheep);
                                                            const isStakingWolf = !!selected.find((el) => !el.isSheep);
                                                            const scenes = [];
                                                            if (isStakingSheep)
                                                                scenes.push({
                                                                    message: "Entering the Barn",
                                                                    source: "/images/staking-barn.gif",
                                                                });
                                                            if (isStakingWolf)
                                                                scenes.push({
                                                                    message: "Joining the Wolfpack",
                                                                    source: "/images/staking-pack.gif",
                                                                });
                                                            setLoadingScenes(scenes);
                                                            onStake(selected);
                                                        }}
                                                    />
                                                )}
                                                <div className="mb-5">
                                                    {(operation === null || operation === "STAKE") && unstaked.length > 0 &&
                                                        <WoodButton
                                                            title={!selectedAllUnStaked ? "Select ALL Unstaked" : "Deselect ALL Unstaked"}
                                                            size="small"
                                                            loading={loading}
                                                            onClick={() => {
                                                                if (!selectedAllUnStaked) {
                                                                    setSelected(unstaked);
                                                                    setSelectedAllUnStaked(true);
                                                                    if (operation !== "STAKE" && operation !== null) return;
                                                                    setOperation("STAKE");
                                                                } else {
                                                                    setSelected([]);
                                                                    setSelectedAllUnStaked(false);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    {(operation === null || operation === "CLAIM") && stakes.length > 0 &&
                                                        <WoodButton
                                                            title={!selectedAllStaked ? "Select ALL Staked" : "Deselect ALL Staked"}
                                                            size="small"
                                                            loading={loading}
                                                            onClick={() => {
                                                                if (!selectedAllStaked) {
                                                                    setSelected(stakes);
                                                                    setSelectedAllStaked(true);
                                                                    if (operation !== "CLAIM" && operation !== null) return;
                                                                    setOperation("CLAIM");
                                                                } else {
                                                                    setSelected([]);
                                                                    setSelectedAllStaked(false);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            : approveBtn()
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    }
                </EthereumInteraction>
            </div>
            {error && <div className="text-xl text-red font-console text-center">{error}</div>}
            <LoadingModal loadingScenes={loadingScenes} modalIsOpen={transacting} />
            <OutcomeModal
                outcomes={outcomes}
                modalIsOpen={outcomes.length > 0}
                closeModal={() => {
                    reload();
                    setOutcomes([]);
                    setSelected([]);
                }}
            />
        </Container>
    );
};

export default Staking;
