import { useEffect, useState } from "react";
import { claimFastfood, feed, } from "../utils/barn";
import { approve, } from "../utils/erc20";
import { watchTransaction } from "../utils/ethereum";
import Container from "./Container";
import EthereumInteraction from "./EthereumInteraction";
import LoadingModal from "./LoadingModal";
import OutcomeModal from "./OutcomeModal";
import WoodButton from "./WoodButton";
import handleResult from "../utils/handleResult";

export default function Feeding({
    wallet,
    chain,
    approved,
    setApproved,
    getAndSetAllowance,
    setAllowanceFastfood,
    fastFoodInWallet,
    fastFood,
    claimableFastFood,
    setFastFoodStates,
    depletionTimestamp,
}) {
    const [amount, setAmount] = useState("");
    const [amountChanged, setAmountChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transacting, setTransacting] = useState(false);
    const [transacting2, setTransacting2] = useState(false);
    const [transacting3, setTransacting3] = useState(false);
    const [response, setResponse] = useState();
    const [outcomes, setOutcomes] = useState([]);

    const stopLoading = () => {
        setLoading(false);
        setTransacting(false);
        setTransacting2(false);
        setTransacting3(false);
    }

    const onApprove = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await approve(process.env.REACT_APP_FAST_FOOD, process.env.REACT_APP_BARN_V3);
            setResponse(handleResult(result, "Approve"));
            if (!("hash" in result)) { stopLoading(); return; }

            let hash = result.hash;
            setTransacting2(true);
            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting2(false);
                    return setResponse("Approve failed. Check transaction.");
                }

                setApproved(true);
                setTimeout(async () => {
                    await presentApproval();
                    setLoading(false);
                    setTransacting2(false);
                    getAndSetAllowance(process.env.REACT_APP_FAST_FOOD, process.env.REACT_APP_BARN_V3, setAllowanceFastfood);
                }, 2000);
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
        }
    };

    const presentApproval = async () => {
        const o = [];
        o.push({
            message: "Successful Approval",
            source: "/images/claimed-pack.gif",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    useEffect(() => {
        if (amount !== "") setAmountChanged(true);
    }, [amount]);

    useEffect(() => {
        if (wallet && amountChanged) {
            if (!amount || amount === "0") setResponse("Please provide an amount greater than zero.");
            /* else if (parseInt(amount) > parseFloat(fastFood)) setResponse("Please provide an amount lesser than your available $FASTFOOD."); */
            else setResponse();
        }
        else setResponse();
    }, [wallet, amount, amountChanged]);

    const onClaim = async () => {
        setLoading(true);
        setResponse(null);
        try {
            const result = await claimFastfood();
            setResponse(handleResult(result, "Claim"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Claim failed. Check transaction.");
                }
                await setFastFoodStates();
                setLoading(false);
                setTransacting(false);
                setResponse("Claim successful.");
            });
        } catch (e) {
            //console.log(e);
            setLoading(false);
        }
    };

    const onFeed = async () => {
        setLoading(true);
        setResponse(null);
        try {
            const result = await feed(`${parseInt(amount)}000000000000000000`);
            setResponse(handleResult(result, "Feeding"));
            if (!("hash" in result)) { stopLoading(); return; }

            const hash = result.hash;
            setTransacting3(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting3(false);
                    return setResponse("Feeding failed. Check transaction.");
                }
                await setFastFoodStates();
                setLoading(false);
                setTransacting3(false);
                setResponse("Feeding successful.");
            });
        } catch (e) {
            /*console.log(e);*/
            setLoading(false);
        }
    };

    const displayRemaingTime = () => {
        const secsInADay = 3600 * 24;
        const secsInAnHour = 3600;
        const secsInAMin = 60;

        const remaningSeconds = depletionTimestamp - Date.now() / 1000;
        const days = depletionTimestamp && !isNaN(remaningSeconds) && remaningSeconds > 0
            ? Math.floor(remaningSeconds / secsInADay) : 0;
        const hours = depletionTimestamp && !isNaN(remaningSeconds) && remaningSeconds > 0
            ? Math.floor((remaningSeconds - days * secsInADay) / secsInAnHour) : 0;
        const mins = depletionTimestamp && !isNaN(remaningSeconds) && remaningSeconds > 0
            ? Math.floor((remaningSeconds - days * secsInADay - hours * secsInAnHour) / secsInAMin) : 0;

        return `${days} day${days !== 1 ? "s" : ""}, ${hours} hour${hours !== 1 ? "s" : ""}, ${mins} min${mins !== 1 ? "s" : ""}`;
    }

    return (
        <Container>
            <div className="uppercase font-bold text-center">In your wallet</div>
            <div className="text-black text-xl sm:text-3xl text-center mb-5">
                <small className="break-all">{fastFoodInWallet || "?"}</small> <small>$FASTFOOD</small>
            </div>
            <div className="uppercase font-bold text-center">Unconsumed fastfood remaining</div>
            <div className="text-black text-xl sm:text-3xl text-center mb-5">
                <small className="break-all">{fastFood || "?"}</small> <small>$FASTFOOD</small>
            </div>
            <div className="uppercase font-bold text-center">Enough for</div>
            <div className="text-black text-xl sm:text-3xl text-center mb-5">{displayRemaingTime()}</div>
            <div className="uppercase font-bold text-center">Claimable</div>
            <div className="text-black text-xl sm:text-3xl text-center">
                <small className="break-all">{claimableFastFood || "?"}</small> <small>$FASTFOOD</small>
            </div>
            <EthereumInteraction wallet={wallet} chain={chain}>
                <>
                    {approved
                        ?
                        <>
                            <div className="mb-3">
                                <WoodButton
                                    title={"CLAIM"}
                                    size="small"
                                    loading={loading}
                                    onClick={async () => await onClaim()}
                                    disabled={!(parseFloat(claimableFastFood) > 0)}
                                />
                            </div>
                            <div className="text-xl sm:text-3xl font-bold mt-5 uppercase mb-5 text-center">Feeding</div>
                            <div className="flex justify-center mb-2 flex-wrap">
                                <label htmlFor="amount" className="text-black mr-3 w-full sm:w-auto">Amount:</label>
                                <input
                                    id="amount"
                                    type="text"
                                    className="outline-none border-black hover:border-[#B11D18] focus:border-[#B11D18] border-solid border-2 px-3 w-full sm:w-auto"
                                    value={amount}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const reg = /^[0-9]*$/;
                                        if (value.match(reg) || value === "") setAmount(value);
                                    }}
                                />
                            </div>
                            {/* <WoodButton
                                title={"MAX"}
                                size="small"
                                loading={loading}
                                onClick={() => setAmount(Math.floor(parseFloat(fastFood)).toString())}
                            /> */}
                            <WoodButton
                                title={"FEED"}
                                size="small"
                                loading={loading}
                                onClick={async () => await onFeed()}
                                disabled={!amount || parseInt(amount) === 0}
                            />
                        </>
                        :
                        <WoodButton
                            title={"Approve"}
                            size="small"
                            loading={loading}
                            onClick={async () => await onApprove()}
                        />
                    }
                </>

            </EthereumInteraction>
            {response && <div className="text-red font-console text-center">{response}</div>}
            <LoadingModal
                loadingScenes={[{
                    message: "Claiming $FASTFOOD",
                    source: "/images/claiming-pack.gif",
                }]}
                modalIsOpen={transacting}
            />
            <LoadingModal
                loadingScenes={[{
                    message: "Approving $FASTFOOD",
                    source: "/images/claiming-pack.gif",
                }]}
                modalIsOpen={transacting2}
            />
            <LoadingModal
                loadingScenes={[{
                    message: "Feeding $FASTFOOD",
                    source: "/images/claiming-pack.gif",
                }]}
                modalIsOpen={transacting3}
            />
            <OutcomeModal
                outcomes={outcomes}
                modalIsOpen={outcomes.length > 0}
                closeModal={() => {
                    setOutcomes([]);
                }}
            />
        </Container>
    )
}
