import MainnetConnect from "./MainnetConnect";
import MetamaskConnect from "./MetamaskConnect";

const EthereumInteraction = ({
    wallet,
    chain,
    children,
    className = "",
}) => {
    return (
        <div className={`w-full h-full flex flex-col justify-center items-center ${className}`}>
            {wallet
                ? chain === process.env.REACT_APP_CHAIN
                    ? children
                    : <MainnetConnect />
                : <MetamaskConnect />
            }
        </div>
    );
};

export default EthereumInteraction;
