import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import useMediaQuery from '../hooks/useMediaQuery'
import { isPaused, rescue, rescueRest, stake, stakeManyRestaurants } from '../utils/barn'
import { claim, hasClaimed } from '../utils/claimV3'
import { watchTransaction } from '../utils/ethereum'
import { isApproved } from '../utils/rest'
import { approve } from '../utils/woolf'
import { approve as approveRest } from '../utils/rest'
import Container from './Container'
import EthereumInteraction from './EthereumInteraction'
import Loader from './Loader'
import WoodButton from './WoodButton';
import handleResult from "../utils/handleResult";

const MigrationModal = ({
    wallet,
    chain,
    modalOpen,
    isRest,
    ids,
    approved,
    setApproved,
    noStaked,
}) => {
    const bp768px = useMediaQuery(768);

    const [loadingMessage, setLoadingMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [oldPaused, setOldPaused] = useState(false);
    const [hasClaimedTokens, setHasClaimedTokens] = useState();
    const [hasJustClaimedTokens, setHasJustClaimedTokens] = useState(false);
    const [approvedRest, setApprovedRest] = useState(false);
    const [intervalData, setIntervalData] = useState();
    const [intervalApprovedRest, setIntervalApprovedRest] = useState();

    useEffect(async () => {
        if (wallet) {
            if (intervalData !== undefined) clearInterval(intervalData);
            if (intervalApprovedRest !== undefined) clearInterval(intervalApprovedRest);

            const initData = async () => {
                setOldPaused(await isPaused());
                setHasClaimedTokens(await hasClaimed(wallet));
            }

            await initData();
            setApprovedRest(await isApproved(wallet));

            const interval1 = setInterval(async () => {
                await initData();
            }, 2500);
            setIntervalData(interval1);

            const interval2 = setInterval(async () => {
                setApprovedRest(await isApproved(wallet));
            }, 2500);
            setIntervalApprovedRest(interval2);

            return () => {
                clearInterval(interval1);
                clearInterval(interval2);
            }
        }
    }, [wallet]);

    useEffect(() => {
        if (approvedRest && intervalApprovedRest !== undefined) clearInterval(intervalApprovedRest);
    }, [approvedRest]);

    const onClaim = async () => {
        setLoadingMessage("Claiming tokens.")
        setLoading(true);
        try {
            const result = await claim();
            setLoadingMessage(handleResult(result, "Claiming"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Claiming failed. Check transaction.");
                }
                setHasJustClaimedTokens(true);
                setLoadingMessage("Successful claim!");
            });
        } catch (e) {
            //console.log(e);
        }
    };

    const onRescue = async () => {
        setLoadingMessage("Recovering NFTs.");
        setLoading(true);
        try {
            const result = await rescue(ids);
            setLoadingMessage(handleResult(result, "Recovering NFTs"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Recovering NFTs failed. Check transaction.");
                }
                setLoadingMessage("NFTs successfully recovered!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onRescueRest = async () => {
        setLoadingMessage("Recovering restaurants.");
        setLoading(true);
        try {
            const result = await rescueRest(ids);
            setLoadingMessage(handleResult(result, "Recovering restaurants"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Recovering restaurants failed. Check transaction.");
                }
                setLoadingMessage("Restaurants successfully recovered!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onStake = async () => {
        setLoadingMessage("Staking NFTs.");
        setLoading(true);
        try {
            const result = await stake(ids);
            setLoadingMessage(handleResult(result, "Staking NFTs"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Staking NFTs failed. Check transaction.");
                }
                setLoadingMessage("NFTs successfully staked!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onStakeRest = async () => {
        setLoadingMessage("Staking restaurants.");
        setLoading(true);
        try {
            const result = await stakeManyRestaurants(ids);
            setLoadingMessage(handleResult(result, "Staking NFTs"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Staking NFTs failed. Check transaction.");
                }
                setLoadingMessage("NFTs successfully staked!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onApprove = async () => {
        setLoadingMessage("Approval of new contract.");
        setLoading(true);
        try {
            const result = await approve();
            setLoadingMessage(handleResult(result, "Approval of new contract"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Approval of new contract failed. Check transaction.");
                }
                setApproved(true);
                setLoadingMessage("Successful approval!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const onApproveRest = async () => {
        setLoadingMessage("Approval of new contract.");
        setLoading(true);
        try {
            const result = await approveRest();
            setLoadingMessage(handleResult(result, "Approval of new contract"));
            if (!("hash" in result)) { setLoading(false); return; }

            const hash = result.hash;
            watchTransaction(hash, async (receipt, success) => {
                setLoading(false);
                if (!success) {
                    setLoadingMessage("Approval of new contract failed. Check transaction.");
                }
                setApprovedRest(true);
                setLoadingMessage("Successful approval!");
            });
        } catch (e) {
            /* console.log(e); */
            setLoading(false);
        }
    };

    const stakeBtn = () => {
        return (
            <WoodButton
                title={`STAKE ALL ${isRest ? "restaurants" : "NFTs"}`}
                size={"small"}
                loading={loading}
                onClick={async () => isRest ? await onStakeRest() : await onStake()}
                className={"w-fit"}
            />
        )
    }

    const approveBtn = () => {
        return (
            <WoodButton
                title={"Approve"}
                size="small"
                loading={loading}
                onClick={async () => isRest ? await onApproveRest() : await onApprove()}
                className={"w-fit"}
            />
        )
    }

    return (
        <Modal
            isOpen={modalOpen
                || (oldPaused
                    && !noStaked
                    && (hasClaimedTokens === false || hasJustClaimedTokens)
                    && localStorage.getItem('closedModal') !== "true")}
            style={{
                content:
                    bp768px
                        ? {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            border: 'none',
                            background: 'transparent',
                        }
                        :
                        {
                            border: 'none',
                            background: 'transparent',
                            marginLeft: '-10%',
                            marginRight: '-10%',
                        },
                overlay: { zIndex: 1000 }
            }}
            contentLabel="Migration modal"
            onRequestClose={() => {
                localStorage.setItem('closedModal', 'true');
                window.location.reload();
            }}
        >
            <Container>
                <div className="w-full flex flex-col items-center">
                    <p className="font-console text-xl sm:text-3xl drop-text">Migration!</p>
                    <p className='font-console text-lg text-center drop-text mb-1 sm:mb-5'>
                        The following process is needed for migration. Do it for your restaurants and animals too.
                    </p>
                    <div className="w-full h-full flex flex-col md:flex-row items-center justify-around gap-2 text-sm sm:text-base">
                        {loading
                            ? <Loader
                                message={loadingMessage}
                                source={"/images/claiming-pack.gif"}
                            />
                            :
                            <EthereumInteraction wallet={wallet} chain={chain}>
                                <div>
                                    {(!hasClaimedTokens && !hasJustClaimedTokens)
                                        ?
                                        <>
                                            <p className='font-console sm:text-lg drop-text text-center'>
                                                Claim:
                                                With the following button you will claim everything that your NFTs earned. If you start migration
                                            </p>
                                            <div className='flex justify-center sm:mb-5'>
                                                <WoodButton
                                                    title={"CLAIM"}
                                                    size={"small"}
                                                    loading={loading}
                                                    onClick={async () => await onClaim()}
                                                    className={"w-fit"}
                                                />
                                            </div>
                                        </>
                                        :
                                        <p className='font-console sm:text-lg drop-text text-center sm:mb-5'>
                                            You have already claimed successfully.
                                        </p>
                                    }
                                    <p className='font-console sm:text-lg drop-text text-center'>
                                        Start migration:
                                        None of your NFTs will be stolen!
                                    </p>
                                    <div className='flex justify-center sm:mb-5'>
                                        <WoodButton
                                            title={`RESCUE ${isRest ? "restaurants" : "NFTs"}`}
                                            size={"small"}
                                            loading={loading}
                                            onClick={async () => isRest ? await onRescueRest() : await onRescue()}
                                            className={"w-fit"}
                                        />
                                    </div>
                                    <p className='font-console sm:text-lg drop-text text-center'>
                                        Restake in new contract:
                                        All previously staked NFTs receive a first time stake protection. The migration process will not harm you.
                                    </p>
                                    <div className='flex justify-center'>
                                        {isRest
                                            ? approvedRest ? stakeBtn() : approveBtn()
                                            : approved ? stakeBtn() : approveBtn()
                                        }
                                    </div>
                                </div>
                            </EthereumInteraction>
                        }
                    </div>
                    {!loading && <p className="font-console sm:text-lg drop-text text-center mt-5">{loadingMessage}</p>}
                </div>

            </Container>
        </Modal>
    )
}

export default MigrationModal
