import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import MINTPARTY_ABI from "./abi/mintParty.abi";

function getContract() {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(process.env.REACT_APP_MINTPARTY, MINTPARTY_ABI, signer);
}

export const getTokenCount = async () => {
    const contract = getContract();
    return (await contract.tokenCount()).toNumber();
};

export const getMinted = async () => {
    const contract = getContract();
    return (await contract.minted()).toNumber();
};

// TODO: keep up to date with Gen 0 mint price
const MINT_PRICE = "75";

export const mint = async (tokens, paid) => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");
    const contract = getContract();
    let ethCost = utils.parseUnits(MINT_PRICE, "ether").mul(BigNumber.from(tokens));

    var gasEstimate;
    var estimateError;
    await contract.estimateGas.mint(tokens, {
        value: paid ? ethCost : BigNumber.from(0),
    }).then(
        result => gasEstimate = result,
        error => estimateError = error);
    if (estimateError) return estimateError;

    return await contract.mint(tokens, {
        gasLimit: gasEstimate.mul(BigNumber.from(12)).div(BigNumber.from(10)),
        gasPrice: await provider.getGasPrice() * 2,
        value: paid ? ethCost : BigNumber.from(0),
    });
};