import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import WAR_CHST_OPEN_ABI from "./abi/warChstOpenParty.abi";

function getContract(abi = WAR_CHST_OPEN_ABI, address) {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(address ? address : process.env.REACT_APP_WAR_CHST_OPEN, abi, signer);
}

const getGasLimit = (gasEstimate) => {
    return gasEstimate.mul(BigNumber.from(15)).div(BigNumber.from(10));
}

const getGasPrice = async () => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");
    return Math.round(await provider.getGasPrice() * 1.3);
}

export const openChest = async (tokenId) => {
    const contract = getContract();
    var gasEstimate;
    var estimateError;
    await contract.estimateGas.openChest(tokenId).then(result => gasEstimate = result, error => estimateError = error);
    if (estimateError) return estimateError;
    return await contract.openChest(tokenId, {
        gasLimit: getGasLimit(gasEstimate),
        gasPrice: await getGasPrice()
    });
};

export const parseLog = (log, abi) => {
    const abiInterface = new utils.Interface(abi);
    const logParsed = abiInterface.parseLog(log);
    return logParsed?.args?.tokenId;
}