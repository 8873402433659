import { useEffect, useRef, useState } from "react";
import arrow_up from "../assets/images/arrow-up.svg";
import classes from "../design/Pagination.module.scss";
import useMediaQuery from "../hooks/useMediaQuery";
import WoodButton from "./WoodButton";

export default function Pagination({
    pageCount,
    page,
    siblingCount = 2,
    onChange,
    pageSize,
    setPageSize,
    className,
    isRestaurant,
    lastID,
}) {
    const bp768px = useMediaQuery(768);

    const ref = useRef();

    const [changedPageSize, setChangedPageSize] = useState(false);
    const [changedPage, setChangedPage] = useState(false);

    const scrollBack = () => {
        if (ref && ref.current) {
            const yOffset = -ref.current.offsetHeight - window.innerHeight / 3;
            const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
        }
    }

    const displayPageSizeBtn = (size) => {
        return (
            <button
                className={size === pageSize ? classes.btnWoodActive : classes.btnWood}
                onClick={() => {
                    setPageSize(size);
                    setChangedPageSize(true);
                }}
            >
                {size}
            </button>
        );
    }

    const displayPageChangeBtn = (number) => {
        return (
            <button
                key={number.toString()}
                className={number === page ? classes.btnWoodActive : classes.btnWood}
                onClick={() => {
                    onChange(number);
                    setChangedPage(true);
                }}
            >
                {number}
            </button>
        );
    }

    const initPrevSiblings = () => {
        const siblings = [];
        if (page >= 3 && siblingCount >= 0) {
            for (var i = page - siblingCount; i < page; i++) {
                if (i > 1) {
                    siblings.push(i);
                }
            }
        }
        return siblings;
    }

    const initNextSiblings = () => {
        const siblings = [];
        if (page < pageCount && siblingCount >= 0) {
            for (var i = page + 1; i <= page + siblingCount; i++) {
                if (i < pageCount) {
                    siblings.push(i);
                }
            }
        }
        return siblings;
    }

    const initPrevDots = () => {
        const dots = prevSiblings.length > 0
            ? prevSiblings[0] > 2 ? "..." : ""
            : pageCount > 2 && page > 2 ? "..." : "";
        return dots;
    }

    const initNextDots = () => {
        const dots = nextSiblings.length > 0
            ? nextSiblings[nextSiblings.length - 1] < pageCount - 1 ? "..." : ""
            : pageCount > page + 1 &&
                page > 1 ? "..." : "";
        return dots;
    }

    const prevSiblings = initPrevSiblings();
    const nextSiblings = initNextSiblings();
    const prevDots = initPrevDots();
    const nextDots = initNextDots();

    useEffect(() => {
        if (changedPageSize) {
            setTimeout(scrollBack, 100);
            setChangedPageSize(false);
        }
    }, [pageSize, changedPageSize]);

    useEffect(() => {
        if (changedPage) {
            setTimeout(scrollBack, 100);
            setChangedPage(false);
        }
    }, [page, changedPage]);

    return (
        <>
            {bp768px
                ?
                <>
                    <div ref={ref} className={`flex justify-center items-center mb-4`}>
                        Page size:
                        {displayPageSizeBtn(isRestaurant ? 10 : 50)}
                        {displayPageSizeBtn(isRestaurant ? 50 : 100)}
                        {displayPageSizeBtn(isRestaurant ? 100 : 200)}
                        {displayPageSizeBtn(300)}
                    </div>
                    <div className={`flex justify-center items-center ${className || ""}`}>
                        <button
                            className="cursor-pointer flex items-center"
                            onClick={() => onChange(page - 1)}
                            disabled={page === 1}
                        >
                            <img
                                src={arrow_up}
                                alt="previous page"
                                className="-rotate-90"
                                style={{ minWidth: "25px" }}

                            />
                        </button>
                        {displayPageChangeBtn(1)}
                        {prevDots}
                        {prevSiblings.map((sibling) => displayPageChangeBtn(sibling))}
                        {page !== 1 && page !== pageCount && displayPageChangeBtn(page)}
                        {nextSiblings.map((sibling) => displayPageChangeBtn(sibling))}
                        {nextDots}
                        {pageCount >= 2 && displayPageChangeBtn(pageCount)}
                        <button
                            className="cursor-pointer flex items-center"
                            onClick={() => onChange(page + 1)}
                            disabled={page === pageCount}
                        >
                            <img
                                src={arrow_up}
                                alt="next page"
                                className="rotate-90"
                                style={{ minWidth: "25px" }}
                                onClick={() => {
                                }}
                            />
                        </button>
                    </div>
                </>
                :
                page < pageCount
                    ?
                    <WoodButton
                        title={"More"}
                        onClick={() => onChange(page + 1)}
                    />
                    : <></>
            }
        </>

    )
}