import Header from '../design/Header';
import Frame from '../design/Frame';

const ToS = () => {

    return (
        <>
            <div className="container mx-auto p-1 sm:p-5">
                <Header />
                <div className="mt-5 mb-5 text-center">
                    <a className="btn-frame" href="/">HOME</a>
                </div>
                <Frame>
                    <h1 className="text-3xl sm:text-5xl text-center">TERMS & CONDITIONS</h1>
                    <div>
                        This Fast Food Wolf Game (FFWG) Terms and Conditions (this “Agreement”) is a legally binding agreement by and between “The FFWG Developers” or “us” or “we” and any owner of any
                        FFWGNFT (defined below) (“you” or “Purchaser”). The FFWG Developers and each Purchaser may be referred to throughout this Agreement collectively as the “Parties” or
                        individually as a “Party”. By purchasing or otherwise owning a FFWGNFT, you acknowledge that you have carefully read and agree to the terms of this Agreement.
                        <br />
                        <br />
                        This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole
                        responsibility for any and all transactions involving FFWG digital collectibles.
                        <br />
                        <br />
                        Your purchase from The FFWG Developers or purchase from any owner of an NFT originally produced by The FFWG Developers does not constitute a financial
                        investment. You are buying a piece of art that lives on the Polygon blockchain.
                        <br />
                        <br />
                        WHEN YOU AGREE TO THESE TERMS, YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND US THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN
                        IN COURT.
                        <br />
                        <br />
                        1. <strong>Agreement to Terms.</strong> “FFWG NFT (FFWGNFT)” refers to a non-fungible token (NFT) (i.e., a controllable electronic record recorded on a blockchain).
                        There are multiple types of FFWGNFTs issued:
                        <br />
                        &emsp;- Woolf
                        <br />
                        &emsp;- Restaurant
                        <br />
                        <br />
                        For a Woolf FFWGNFT, as of its genesis issuance, the art is linked to an image of a wolf or sheep, as applicable. For a Restaurant FFWGNFT, as of its genesis issuance, the
                        art is linked to an image of a Restaurant.
                        <br />
                        <br />
                        “FFWGNFT Art” means the image linked to a particular NFT, which images consists of elements of art compiled by the underlying FFWG smart contract. For avoidance of
                        doubt, the FFWGNFT Art is digital in nature and does not include, is not linked to, and is not sold together with, any items or representations that have physical
                        dimensions such as mass or volume.
                        <br />
                        <br />
                        (a) Additional Terms: FFWGNFTs may be available for purchase on one or more third-party platforms, such as OpenSea (each, a “NFT Marketplace”), which we do not operate.
                        The access and use of the NFT Marketplace are subject to the separate terms of the NFT Marketplace.
                        <br />
                        <br />
                        <br />
                        2. <strong>Ownership of FFWGNFTs.</strong>
                        <br />
                        (a) When Purchaser acquires an FFWGNFT, Purchaser owns all personal property rights to that FFWGNFT (e.g., the right to freely sell, transfer, or otherwise dispose of that
                        FFWGNFT). The FFWGNFT Art, however, is free for use by anyone for any purpose without restriction under copyright law. Public domain is the purest form of open and free,
                        since no one owns or controls the material in any way.
                        <br />
                        <br />
                        (b) Purchaser represents and warrants that it will not transfer a FFWGNFT in any subsequent transaction to a Transferee that is (i) located in a country that is subject
                        to a U.S. Government embargo, or that has been designated by the U.S. Government as a terrorist-supporting country; or is (ii) listed on any U.S. Government list of
                        prohibited or restricted parties (“Prohibited Transferees”).
                        <br />
                        <br />
                        <br />
                        3. <strong>Fees and Payments</strong>
                        <br />
                        (a) If you elect to purchase a FFWGNFT through the website, any financial transactions that you engage in will be conducted solely through the Polygon network. We will
                        have no insight into or control over these payments or transactions, nor do we have the ability to reverse any transactions. We will have no liability to you or to any
                        third party for any claims or damages that may arise as a result of any transactions that you engage or any other transactions that you conduct via the Polygon
                        network.
                        <br />
                        <br />
                        (b) Polygon requires the payment of a transaction fee (a “Gas Fee”) for every transaction that occurs on the Polygon network. The Gas Fee funds the network of
                        computers that run the decentralized Polygon network. This means that you will need to pay a Gas Fee for each transaction.
                        <br />
                        <br />
                        <br />
                        4. Transfers. All subsequent transactions of the FFWGNFTs are subject to the following terms: (a) the FFWGNFT transferee (the “Transferee”) shall, by purchasing, accepting,
                        accessing or otherwise using the FFWGNFT or FFWGNFT Art, be deemed to accept all of the terms of this Agreement as a “Purchaser” hereof; and (b) the FFWGNFT transferor (the
                        “Transferor”) shall provide notice to the Transferee of this Agreement, including a link or other method by which the terms of this Agreement can be accessible by the
                        Transferee. Purchaser further acknowledges and agrees that all subsequent transactions of the FFWGNFT will be effected on the blockchain network governing the FFWGNFT, and
                        Purchaser will be required to make or receive payments exclusively through its cryptocurrency wallet.
                        <br />
                        <br />
                        <br />
                        5. <strong>The FFWG Developers’ Rights and Obligations to the FFWGNFT Art and FFWGNFTs.</strong> The Parties acknowledge and agree that we are not responsible for
                        repairing, supporting, replacing, or maintaining any website.
                        <br />
                        <br />
                        <br />
                        6. <strong>Warranty Disclaimers and Assumption of Risk.</strong> Purchaser represents and warrants that it (a) is the age of majority in Purchaser’s place of residence
                        (which is typically 18 years of age in most U.S. states) and has the legal capacity to enter into this Agreement, (b) that Purchaser will use and interact with the
                        FFWGNFTs and FFWGNFT Art only for lawful purposes and in accordance with this Agreement, and (c) that Purchaser will not use the FFWGNFTs or FFWGNFT Art to violate any law,
                        regulation or ordinance or any right of The FFWG Developers, its licensors or any third party, including without limitation, any right of privacy, publicity,
                        copyright, trademark, or patent. Purchaser further agrees that it will comply with all applicable law.
                        <br />
                        THE FFWGNFTS ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, THE FFWG DEVELOPERS EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON- INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. THE
                        FFWG DEVELOPERS MAKE NO WARRANTY THAT THE FFWGNFTS WILL MEET PURCHASER’S REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. THE FAST FOOD WOLF
                        GAME DEVELOPERS MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY INFORMATION OR CONTENT ON THE FFWGNFTS.
                        <br />
                        <br />
                        THE FFWG DEVELOPERS WILL NOT BE RESPONSIBLE OR LIABLE TO PURCHASER FOR ANY LOSS AND TAKES NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF THE
                        FFWGNFT, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (I) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR
                        MISTYPED WALLET ADDRESSES; (II) SERVER FAILURE OR DATA LOSS; (III) CORRUPTED CRYPTOCURRENCY WALLET FILES; (IV) UNAUTHORIZED ACCESS TO THE FFWGNFT; OR (V) ANY UNAUTHORIZED
                        THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST BLOCKCHAIN NETWORK UNDERLYING THE
                        FFWGNFTS.
                        <br />
                        <br />
                        THE FFWGNFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSFER OF TITLE THAT
                        MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK, WHICH WE DO NOT CONTROL. THE FFWG DEVELOPERS DO NOT
                        GUARANTEE THAT THE FFWG DEVELOPERS CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY FFWGNFT. PURCHASER BEARS FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY,
                        LEGITIMACY, AND AUTHENTICITY OF ASSETS PURCHASER PURCHASES THROUGH THE NFT MARKETPLACE. NOTWITHSTANDING INDICATORS AND MESSAGES THAT SUGGEST VERIFICATION, WE MAKE NO
                        CLAIMS ABOUT THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF ASSETS ON THE NFT MARKETPLACE OR ANY PURPORTED SUBSEQUENT TRANSACTIONS.
                        <br />
                        <br />
                        WE ARE NOT RESPONSIBLE ANY KIND OF FAILURE, ABNORMAL BEHAVIOR OF SOFTWARE (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF THE FFWGNFTS. THE FAST FOOD WOLF
                        GAME DEVELOPERS ARE NOT RESPONSIBLE FOR CASUALTIES DUE TO LATE REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN
                        SUPPORTING THE FFWGNFTS, INCLUDING FORKS, TECHNICAL NODE ISSUES OR ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
                        <br />
                        <br />
                        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
                        <br />
                        <br />
                        <br />
                        7. <strong>Assumption of Risk.</strong> Purchaser accepts and acknowledges all risks associated with the following:
                        <br />
                        (a) Purchaser is solely responsible for determining what, if any, taxes apply to Purchaser’s purchase, sale, or transfer of the FFWGNFTs. The FFWG Developers are
                        not responsible for determining or paying the taxes that apply to such transactions.
                        <br />
                        <br />
                        (b) We do not store, send, or receive cryptocurrency assets. Any transfer of cryptocurrency assets occurs within the supporting blockchain that is not controlled by us.
                        Transactions of the FFWGNFTs may be irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable. Some transactions of the
                        FFWGNFTs shall be deemed to be made when recorded on a public blockchain ledger, which is not necessarily the date or time that Purchaser initiated the transaction.
                        <br />
                        <br />
                        (c) There are risks associated with using an Internet based digital asset, including but not limited to, the risk of hardware, software and Internet connections, the
                        risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to information stored within your Wallet. The FFWG
                        Developers will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when effecting FFWGNFT transactions,
                        however caused.
                        <br />
                        <br />
                        (d) Digital assets, including blockchain based assets such as the FFWGNFTs, are subject to developing laws and regulations throughout the world.
                        <br />
                        <br />
                        <br />
                        8. <strong>Indemnity.</strong> Purchaser shall defend, indemnify, and hold The FFWG Developers, its licensors and affiliates (the “Indemnified Parties”) harmless
                        from and against any and all claims, damages, losses, costs, investigations, liabilities, judgments, fines, penalties, settlements, interest, and expenses (including
                        attorneys’ fees) that directly or indirectly arise from or are related to any claim, suit, action, demand, or proceeding made or brought by a third party (including any
                        person who accesses or transacts using the FFWGNFTs whether or not such person personally purchased the FFWGNFTs) against the Indemnified Parties, or on account of the
                        investigation, defense, or settlement thereof, arising out of or in connection with your breach of this Agreement.
                        <br />
                        <br />
                        <br />
                        9. <strong>Limitation of Liability.</strong>
                        <br />
                        (a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER THE FFWG DEVELOPERS NOR ITS SERVICE PROVIDERS, INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE FFWGNFTs WILL
                        BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF
                        DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE FFWGNFTs OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS
                        OR FROM THE USE OF OR INABILITY TO USE OR INTERACT WITH THE FFWGNFTs OR ACCESS THE FFWGNFT ART, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
                        LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE FFWG DEVELOPERS OR ITS SERVICE PROVIDERS HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF
                        A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                        <br />
                        <br />
                        (b) TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE JURISDICTION, IN NO EVENT WILL THE FFWG DEVELOPERS’ TOTAL LIABILITY ARISING OUT OF OR IN
                        CONNECTION WITH THESE TERMS OR THE USE OF OR INABILITY TO USE OR INTERACT WITH THE FFWGNFTs OR ACCESS THE FFWGNFT ART EXCEED THE GREATER OF (A) THE AMOUNT YOU ACTUALLY PAID
                        US UNDER THESE TERMS IN THE PRIOR 12 MONTHS, OR (B) $500.
                        <br />
                        <br />
                        (c) THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN THE FFWG DEVELOPERS AND PURCHASER.
                        <br />
                        <br />
                        <br />
                        10. <strong>Governing Law and Forum Choice.</strong> This Agreement and any action related thereto will be governed by the laws of the State of Delaware, without regard
                        to its conflict of laws provisions. Except as otherwise expressly set forth in Section 11 “Dispute Resolution,” the exclusive jurisdiction for all Disputes (defined
                        below) that Purchaser and The FFWG Developers are not required to arbitrate will be the courts located in Delaware, and Purchaser and The FFWG Developers
                        each waive any objection to jurisdiction and venue in such courts.
                        <br />
                        <br />
                        <br />
                        11. <strong>Dispute Resolution.</strong>
                        <br />
                        (a) Mandatory A rbitration of Disputes . The Parties each agree that any dispute, claim or controversy arising out of or relating to this Agreement or the breach,
                        termination, enforcement, interpretation or validity thereof of the FFWGNFTs transaction (collectively, “Disputes”) will be resolved solely by binding, individual
                        arbitration and not in a class, representative or consolidated action or proceeding. You and The FFWG Developers are each waiving the right to a trial by jury or
                        to participate in a class action. This arbitration provision shall survive termination of this Agreement.
                        <br />
                        <br />
                        (b) Exceptions . As limited exceptions to Section 11(a) above: (i) both Parties may seek to resolve a Dispute in small claims court if it qualifies; and (ii) both
                        Parties each retain the right to seek injunctive or other equitable relief from a court to prevent (or enjoin) the infringement or misappropriation of our intellectual
                        property rights.
                        <br />
                        <br />
                        (c) Conducting Arbitration and Arbitration Rules . The arbitration will be conducted by JAMS under its JAMS Comprehensive Arbitration Rules and Procedures (the “JAMS
                        Rules”) then in effect, except as modified by this Agreement. The JAMS Rules are available at https://www.jamsadr.com/. A party who wishes to start arbitration must
                        submit a written Demand for Arbitration to JAMS and give notice to the other party as specified in the JAMS Rules. JAMS provides a form Demand for Arbitration at
                        https://www.jamsadr.com/.
                        <br />
                        <br />
                        Any arbitration hearings will take place in the county (or parish) where you live, unless we both agree to a different location but any such arbitration will be
                        conducted remotely to the extent permitted by the JAMS Rules. The Parties agree that the arbitrator shall have exclusive authority to decide all issues relating to the
                        interpretation, applicability, enforceability and scope of this arbitration agreement.
                        <br />
                        <br />
                        (d) Arbitration Costs . Payment of all filing, administration and arbitrator fees will be governed by the JAMS Rules, and The FFWG Developers won’t seek to
                        recover the administration and arbitrator fees The FFWG Developers is responsible for paying, unless the arbitrator finds your Dispute frivolous. If The Fast Food Wolf
                        Game Developers prevail in arbitration it will pay for all of its attorneys’ fees and costs and won’t seek to recover them from you. If you prevail in arbitration you
                        will be entitled to an award of attorneys’ fees and expenses to the extent provided under applicable law.
                        <br />
                        <br />
                        (e) Injunctive and Declaratory Relief . Except as provided in Section 11(b) above, the arbitrator shall determine all issues of liability on the merits of any claim
                        asserted by either party and may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide
                        relief warranted by that party’s individual claim. To the extent that you or The FFWG Developers prevail on a claim and seek public injunctive relief (that is,
                        injunctive relief that has the primary purpose and effect of prohibiting unlawful acts that threaten future injury to the public), the entitlement to and extent of such
                        relief must be litigated in a civil court of competent jurisdiction and not in arbitration. The parties agree that litigation of any issues of public injunctive relief
                        shall be stayed pending the outcome of the merits of any individual claims in arbitration.
                        <br />
                        <br />
                        (f) Class Action Waiver . YOU AND THE FFWG DEVELOPERS AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
                        PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if the parties’ Dispute is resolved through arbitration, the arbitrator may not
                        consolidate another person’s claims with your claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is
                        found to be unenforceable, then the entirety of this Dispute Resolution section shall be null and void.
                        <br />
                        <br />
                        (g) Severability . With the exception of any of the provisions in Section 11(f) of this Agreement (“Class Action Waiver”), if an arbitrator or court of competent
                        jurisdiction decides that any part of this Agreement is invalid or unenforceable, the other parts of this Agreement will still apply.
                        <br />
                        <br />
                        <br />
                        12. FFWGNFTs may accumulate a utility token (“FFWOOL”) when they are staked in the smart contract. FFWOOL is built for one purpose and one purpose only: to enable you to mint
                        more FFWGNFTs within the FFWG ecosystem. FFWOOL has no other functionality other than within the FFWG ecosystem, and it cannot be purchased from The FFWG
                        Developers. It can only be accumulated by staking your FFWGNFTs. The FFWG Developers do not provide or intend to provide a secondary marketplace for FFWOOL.
                        <br />
                        <br />
                        <br />
                        13. <strong>General Terms.</strong> This Agreement will transfer and be binding upon and will inure to the benefit of the parties and their permitted successors and
                        assigns, in particular any Transferee. This Agreement constitutes the entire agreement, and supersedes any and all prior or contemporaneous representations,
                        understandings and agreements, between the Parties with respect to the subject matter of this Agreement, all of which are hereby merged into this Agreement. Without
                        limitation, the terms of any other document, course of dealing, or course of trade will not modify this Agreement, except as expressly provided in this Agreement or as
                        the Parties may agree in writing. No amendment to this Agreement or waiver of any provision hereof will be valid or binding unless reduced to writing and duly executed
                        by the Party or Parties to be bound thereby. Failure to promptly enforce a provision of this Agreement will not be construed as a waiver of such provision. Nothing
                        contained in this Agreement will be deemed to create, or be construed as creating, a joint venture or partnership between the parties. Neither Party is, by virtue of
                        this Agreement or otherwise, authorized as an agent or legal representative of the other Party. Neither Party to this Agreement is granted any right or authority to
                        assume or to create any obligation or responsibility, express or implied, on behalf or in the name of the other party, or to bind such other Party in any manner.
                        Nothing contained in this Agreement will be deemed to create any third-party beneficiary right upon any third party whatsoever. Each of the Parties acknowledges that it
                        has had the opportunity to have this Agreement reviewed or not by independent legal counsel of its choice. If any one or more of the provisions of this Agreement should
                        be ruled wholly or partly invalid or unenforceable, then the provisions held invalid or unenforceable will be deemed amended, and the court or other government body is
                        authorized to reform the provision(s) to the minimum extent necessary to render them valid and enforceable in conformity with the parties’ intent as manifested herein.
                        The headings to Sections of this Agreement are for convenience or reference only and do not form a part of this Agreement and will not in any way affect its
                        interpretation. Neither Party will be afforded or denied preference in the construction of this Agreement, whether by virtue of being the drafter or otherwise. For
                        purposes of this Agreement, the words and phrases “include,” “includes”, “including” and “such as” are deemed to be followed by the words “without limitation”. The
                        Parties have agreed to contract electronically, and accordingly, electronic signatures will be given the same effect and weight as originals.
                    </div>
                </Frame>
            </div>
        </>
    )
}

export default ToS;
