import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Whitepaper from "./pages/Whitepaper";
import Polygon from "./pages/Polygon";
import MetaDecorator from "./design/MetaDecorator";
import GameProvider from "./GameProvider";
import RestaurantProvider from "./RestaurantProvider";
import ToS from "./pages/ToS";
import WarProvider from "./WarProvider";
import SuperGrass from "./SuperGrass";

function App() {
  return (
    <Router>
      <MetaDecorator
        description="Fast Food Wolf Game"
        title="Fast Food Wolf Game"
        imageUrl="https://fastfoodwolfgame/banner.jpg"
        imageAlt="Fast Food Wolf Game Banner"
      />
    <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/whitepaper" exact element={<Whitepaper/>} />
        <Route path="/polygon" exact element={<Polygon/>} />
        <Route path="/barn" exact element={<GameProvider/>} />
        <Route path="/game" exact element={<RestaurantProvider/>} />
        <Route path="/war" exact element={<WarProvider/>} />
        <Route path="/supergrass" exact element={<SuperGrass />} />
        <Route path="/tos" exact element={<ToS/>} />
        <Route path="*" element={<NotFound/>} />
    </Routes>
  </Router>
  );
}

export default App;
