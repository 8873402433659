// TODO: update before whitelist presale begins
// TODO: make sure all entries in whitelisted have lower case and trimmed string keys
const whitelisted = {
	"0x52861184474c51f66fc4ebc4ffcb7f64e14aeb7a": 1,
	"0x87d363f66dd9bb5bec8fbe26df6eb5850085b5eb": 1,
	"0xf65d50b4bb72830ca58de1d353bc9b2a7a3a15fe": 1,
	"0x97d3e27458fd05b968350847a37a494eef650f1a": 1,
	"0x568e142874919c89f35601fa02cc8d32a3ebff63": 1,
	"0xa916113d238bdd2b62716eb251388e0b5746901d": 1,
	"0x2b85075702b5bc4737d8e1560b7efe8535105b47": 1,
	"0xe8289835a8f0153df1844421cf13535935596c18": 1,
	"0xa8afc4f43d0e0f89b0a1a3a3e8a9932f6daae7dc": 1,
	"0xa61145b46baf9bd3039d37a08a3db91b0b9c5ae6": 2,
	"0xb5e1b060bb1dbcf27860a9c123307f91529b7abc": 1,
	"0xa98d6528fe0d2f7832c1d33d442cff7b5495d51b": 1,
	"0xe4e68f43704263caf50808714da38b079809d49a": 1,
	"0xf4900f759038d3fd063fd62c3e45f9600cec47bd": 1,
	"0x49bc5ffa2b78a444f8ed8b413170c6decbe9a3c9": 1,
	"0xcb08b54210d262838eee3b6adc232b08686c363a": 1,
	"0xaa00909f750f7fbbe35bda4cd63d0f8ba71bc010": 1,
	"0xaaff85ef714fa42adacc5e46423e729802a0e352": 1,
	"0x58026da697875ef41ff4bd0257fd3579c6c4f5d8": 1,
	"0x363104d26415efe3276629b82d28c313c0c52d38": 1,
	"0xf7026d059f48ca14949388c4010157022e9075c5": 2,
	"0xedcb273d20b0317b492b81b72eba503f54f04a29": 1,
	"0x12569e01f1f8d64ac0367b5eca6948eaba5d97e2": 1,
	"0x3464b7d65862cff885b904d8801a346b6bed91fc": 1,
	"0xd7c511c9c0e5228f46dd466569c585d273daa36e": 1,
	"0x569373e20f1d0406dddacfda433cdabf94de4082": 1,
	"0x7df8457204eb3de8ae542166585a6f1dbb71d413": 1,
	"0xd5f19dec2fb6ca4a454de138d1d05c7e5f6436a9": 1,
	"0xcc26194ea24b0bd5c08a65c6a7d283a84b42c240": 1,
	"0xd92da91c48cae1d8309da06150751c11d049613e": 1,
	"0x19641b9c90e760c8de1f3bad37a4e98d03d77f99": 1,
	"0x58745fa64df8052da6678d4e1a11840979f11622": 1,
	"0x1a87b061260516256a1e168d92589295d649c7e3": 1,
	"0xb1fc41cbad16cafdfc2ed196c7fe515dfb6a1577": 1,
	"0xc1ec830cb41aa5b57fe439e8beace82ea13a6fde": 1,
	"0x4aac34062ca87e6261a12403f950b9722970c61a": 1,
	"0x932db2ca37f73af3fe0844717ead3d0db4ad3f94": 1,
	"0x9bbbcc07b1c24218c534b8ca308aead63ffe1ce2": 1,
	"0xf343acfc173641a02d476397f31b1a2905eb79ad": 1,
	"0x847918400ef2f6264c1c95c30449a1012a4e373f": 1,
	"0x18e85aead070673cb053559c9a17d4d816dacbb3": 1,
	"0x5e1f2933c4ab569df2b8218cbf01d8c5a40b0f12": 1,
	"0xcfa9f060f544f39181452975b8ffe9b6f28a1679": 1,
	"0xd869b5477b919121d92bdf79f4990a6b3808acdd": 1,
	"0x0ff86f706bce6d1dc98fc58ccf6c39ccb2047af8": 2,
	"0x6556406edf775c60a1cc09d4cc91178fe999feab": 2,
	"0x6c489c95693ed6dcf47b701cc6f0b756f4fe0993": 1,
	"0x7cf6dc2db031b5c0a869738a682b51890756b24d": 1,
	"0xe03917c2adc9a0feed1f4a2b63f5ef2adf7af93e": 1,
	"0xb6574f179d56774bb893324256461fed0055aa86": 1,
	"0xb75df078c0758224247fd7dce10128e1d8172d3a": 2,
	"0x34c53ef47c41da3a393b40125bfa9387763b7b71": 2,
	"0xb9a1869c63427eaf3428a808f21f94e9bc7f44d3": 1,
	"0xd66f03c277f44624d8df4064e137b08f0ac9aa23": 2,
	"0x2d55aceff42907708045baa770ebba8d5a608cfd": 2,
	"0x8091c8a637b75736d13f10459de6687b3788bf0e": 1,
	"0x40deed933b22457ab2ebeeb96aa4fffff972da2e": 1,
	"0x5cf045a517cf47e03d12488543a0be3686fabba4": 1,
	"0xa4be670efab55ae15eaf48252d6bd08ebcdbcc77": 1,
	"0xbdd5d94e6463cf1683083a60774b38e7f3bc42b2": 1,
	"0x705e7a229ee290bef090ddced3338f85819ee67d": 2,
	"0x18a09088032d2c9392d9722ff64d9be6a98fc9ab": 1,
	"0xe639bdc5d9efb8b54f1d9f6304b7a967ab2aa2a6": 1,
	"0x19969b6072733c532f9d6983b79a31ee63c8ee5f": 2,
	"0xaa6b0374f89b06df9dd40d1844f7ec3cb13a2e8a": 1,
	"0xeb9409af612a51e2204f588c24fdb58bbf853e41": 1,
	"0xc0e8e357881b121763c7684424bb5697b9bf29fd": 1,
	"0x3d51e5df85d8ff5f1571c60f2929254d995d970b": 1,
	"0xebec123655ffd36e8e199780b2867e2c17ac87bc": 1,
	"0xeab646310a94bf58b0d6061f90fcfa7d2f4143aa": 1,
	"0xc4522f2c12385fdfaabc3b863d384c394a1574d8": 1,
	"0x75bcf2db7fd46836d5a8595ea0b7878500c51c8b": 1,
	"0x07eaa9e32ba4c69698b8adba66fd6ee63c591894": 1,
	"0x21533035c3f538e9d51737eb853ff4f6201c15aa": 1,
	"0x827f8885af3fd7e06780541e17d006ba95873ab2": 1,
	"0x4b90131ba27f529a4e2831734d43720f197af523": 1,
	"0x6f1f0597b0638a560d0b849d02bc2d4a9677fcb2": 1,
	"0x514a187605c24fd3df1dafd38aaf59bd23bba041": 1,
	"0xbf3ecae667b59b070a4ab69585857af80eb64988": 1,
	"0xb01768ca836f2aa4a5c240a8d3782a61c081bb84": 1,
	"0x6937a767422c879a90ccb1765c0370431a87f70a": 1,
	"0x27cb1f9eb77c236803b0a6226ed32535ba727daa": 1,
	"0x55b70fe01c3062621abc073dda1639d8b6f85731": 2,
	"0x42c1fb87419e4034dacf1231f88aa74b25f723b4": 1,
	"0xd378ca5fffaa6701c2ae9a71120d702cc303c7e5": 1,
	"0x24ea4665b3ddb29fe04687d820f38e24311e01a7": 1,
	"0x7b433234afefab944b3fa2afe010ddf52ddba108": 2,
	"0x1533ef8a7bc85532a8515df6fdfb15d165d0d03c": 1,
	"0x820dc4ce6677ff32a3af41a1ed12ec406f12eb9f": 1,
	"0x184084004843fe8c90e626697bfbf3f5a2c954d8": 1,
	"0x4a4db12afdd90ffa7844ab450c0dd2357d072dc2": 1,
	"0x13d761db77e560043c61bd7549f02c5fc3cb9fc0": 1,
	"0xaa9b8aa8d86e57e2f22c88cf26c159c8c15589cd": 1,
	"0x4e2a7d0e465d8d38aa5a1852d438e60b5832c1b4": 1,
	"0x867ceaad974643d06fc157c48e7d28ee80082db3": 1,
	"0x4c00a988da94b72db4c50a29f6fb095422bee8fd": 1,
	"0x44191d34c5136ac3040f76fa2194617ab68cd6c6": 1,
	"0xbecf29ba92cfdf7f83f5c54a60e7ed5d7ed191d8": 1,
	"0xa9852147ee8bbd4b5bb4cb0bc65d6c1e960e606a": 2,
	"0xe15e365806ed3b2570455782dbec0b450354c233": 1,
	"0x791cfbff03220715c53768e7e2a3b23579ff18a8": 1,
	"0x8d095350c0615c52153372dd45041d6bd5c8b3a7": 1,
	"0x2f691f9192bc8b51bd24723de72b283500f2f3a5": 2,
	"0x83928b915dfcfb32c2f78d04b96affceaec38de5": 1,
	"0x808384d3cea65160c8407fefcfd41248788c5bb2": 1,
	"0xe408aabd33fc315923655e70258cc73fa3cbbd36": 1,
	"0x242f00a9283b0b0c67aa1621c8ec29ad34d127d7": 1,
	"0xebe9f2bbd5e7b3b8099233afff654c6a9bac679c": 1,
	"0xf32ceb90934a6aac91c655da5e703baeeb3f4f0a": 1,
	"0x1eb0473c1f79b2bba32fc052d98370f0bbfd73f2": 1,
	"0x5ec3b1beb99d06d84c481da8fe1dd40132ef579d": 1,
	"0xbba89baa8a5406d8f976ded895d39591fb17aef3": 1,
	"0xd0a29d144378acaf1164d941c28911697142973d": 1,
	"0xeecb779b249a6352d44c522d89c9037f3a2df4d1": 1,
	"0xbdcf5b4fb7a0c0e5dacfce993dd4d7b3b092015a": 1,
	"0x4e8972ea6f20fd792daea9e93123ab29e510cd4b": 1,
	"0x78d8f280917af646ae9cf9b16eb39e203662ad1b": 1,
	"0x0d4a1e556b7ede49978587ef5e56bc28c463d768": 1,
	"0x621c82a583093b202aa0c643bace600601a68a06": 1,
	"0xf7be00339dc6718b3a18f1bf80692f3af03cca79": 1,
	"0xf8da72c3fef696ba01901a0a54053a3aba5b1547": 2,
	"0x0b27925128e85eb639ec4cc3368510e0783efb3d": 1,
	"0x3dccdb9c2c01851a788fbc0678536243838c8353": 1,
	"0x2be2a152a4b263dd60142ca87b23750c9d4df7e0": 1,
	"0x7c7562fbf561859333ed41ddb408b2081279d8b8": 2,
	"0xc1739f576dba4f181e6848e2a6954a585d71650a": 2,
	"0x55901b22436fd946939c1fff46c3827421f624ed": 1,
	"0x2f8186fc9adc62a0044bf14165a6f9f63166ed40": 1,
	"0x53c0a2102b573dc1a99c3f6c94f2e4876afda552": 2,
	"0xddf5afb04abb610d35d49531c79d532084f71489": 1,
	"0x6f73ae3979c3746e5797aae906b9ca338e78bc48": 2,
	"0x698fa8dfa821489cf4b69225347f28a56e3c3129": 2,
	"0x3d128cd0eb80627b8feba990ea02726644e43012": 1,
	"0x38fb205214d0e4d7c55b345279d1bb35cfb35c38": 1,
	"0x2248d562e1a3ea37e57807f85c68fe64028d565b": 1,
	"0xfb464fcd5342d2997a43c6b7bca7615d0fbdeed9": 1,
	"0xa5104d774fea26004b146c4683fb620b969441eb": 1,
	"0xddc1c8530c3633e8b5bc4c513fd94b85ab55a33d": 1,
	"0xd71d45b155210eec1918ec0951d727b781213a6e": 1,
	"0x809cf6946c21d6389996192615646cc3198091c4": 2,
	"0x6f8745515146687acdf1e65de8623d08d4fdf007": 2,
	"0x0e8b1e54d196b675637630bbbf54387b887e4288": 1,
	"0xe4daf4301bdcb8e8067ca2e130fb61c33e4e854c": 1,
	"0x6f9f8f21719190721ffbd398526f331506bd113b": 1,
	"0x676ddb82e7048d008f9a61e02aa3b6dc957904bc": 1,
	"0xc63758e6067a8dc5dcd081105ab9d5194d4188d0": 1,
	"0xc387fd4a69a2788e799772893f88809e618cddf8": 2,
	"0x913748aae3fb55056e7dde6e97bb3b67dd0fcba7": 1,
	"0xc89d096d9652899b3bb0151dcfc6aec14824fc76": 1,
	"0x3be63ed41965e30bd2fec57cae8b975a800548c4": 2,
	"0xc1869901083ce3b7ee6470a24aeee56184b92a8e": 1,
	"0x3e1a0615e055ab938aaf054e27bd5df2deeaf375": 1,
	"0x972a6d9674a261a3c4becb2038b7e5d6ec9b09e9": 1,
	"0xaa2aae919c318ab352185e7332e614e14b3c2b83": 1,
	"0xc473aee143edb8c83074c6f0c92d2288559c5dc7": 1,
	"0x5305dcb6bbaf33cdd256ea5e88775a5280d6f4e0": 1,
	"0x9baba25c8538669b27235ee924bcee4731166433": 2,
	"0x225134bceeaafc6a2f455f82d0f30988e567f090": 1,
	"0x202bf5173d5a85ae614fc8e7da3b24beda2c7f71": 1,
	"0x17b3f4579e5cb1c5e7976078f825cbdbc3424c4b": 1,
	"0x28f293a4da6c383517f553742c47a98b66cae89d": 1,
	"0xbee25c0802735b48cb6694fd30dcf6959728eeb1": 1,
	"0xdc702604a1bd2372333a445bd4cf571e2d050231": 2,
	"0xfba8a31eb5773aaea654d6ae3da6a0dad951af9e": 1,
	"0xb61828a987a0a8b5e1523e38dbbd296b21b39f3b": 1,
	"0x410d1050880024e3af39476980933f0b4ee7959a": 1,
	"0x81a39aa0b0d6fa8513aa79929e7c01dfaa232fce": 1,
	"0xc6fbaf5fb912eed59c56651f0130d9e11e92d5fe": 1,
	"0x912f31f47897ae95a38736df4bb730013a7cfce3": 1,
	"0x0d5e1408575aa7e3725925d3f0b34f30cf7a7b17": 1,
	"0x462872d18db59f13e7a965788a89b0e43469965f": 1,
	"0xcdefef7370958131fec1fbae1f420f0c8997ee7e": 2,
	"0x2a9e15f66b71ecd2fccde3776f27bebed0b3bbe4": 1,
	"0xd95dbdab08a9fed2d71ac9c3028aac40905d8cf3": 1,
	"0xb0848ee9f1c0e3c3114e7214881089eff6690c53": 1,
	"0x6a9df7f165f298d141ec98f41fa88f1ed36b32ab": 1,
	"0x88bc20f647128caf824e92b96ea7788aa22d87e4": 2,
	"0x927b858066eb7efcee43dac3b2fd703d17452fe5": 1,
	"0x84aa7f3453221ff935018512bb67f1c4b0b287ff": 1,
	"0xa9b7aa0e2ee8868648a8bc0a9a1cc913ef39f138": 1,
	"0x10c56758432434d407686e2f8a39dcd1d7066bff": 1,
	"0x21e59051fb9107ca12836f84886afb69a4068662": 1,
	"0xd4400ea773c614a8859463f2e31ec90852d216f5": 1,
	"0x3b942937eb10ae81a92981b93cbf9e7c6004f364": 1,
	"0x3c0b85148cfe2bc1a856d6dd561b62a81bc57d10": 1,
	"0x51c72fd6d1883f880ea59f1fe198202d9a4831f7": 1,
	"0x1df5be0fb3037533e930c14f629194b747c880a8": 1,
	"0x95090693cb286b8eaaec6bcc59079c5662c5a79b": 1,
	"0x3b1896ae9eec640c80dab205856b16efa617eba1": 1,
	"0x469753709133cb92c5effec0780fc19fa9ef5984": 1,
	"0x7e713e6f73b10708a79b6c0fe7c56da2a61810ea": 1,
	"0x89726f7513013660d25410b3e904086960e42559": 1,
	"0xc9a4ddbc437d2dd5ea8a69b1d803122818a39a0a": 1,
	"0x9c7eef51b60b2b6d830f151121eeaf0eb0275446": 1,
	"0x41d4c9431e6c399ccacc1dd78d700806616a3fd1": 1,
	"0xb6e37bad56f61fa3bc05b48d6552222d9d724930": 1,
	"0x497beb148a56f997989c05e7ea2f16eb1537c15e": 1,
	"0x9700b0280f6e36f71271fd4555a50a782cc81402": 1,
	"0x8bbc42da742e9a5e0ee458a63be0bcd23f0b5912": 1,
	"0x18bd34ba9c5187b10c372e81d24525a07f89e51e": 1,
	"0x1eb5497ceaab83941b4ce357318849615e296936": 2,
	"0x659db7f3ea1801dde55259fccbb1e8608f8ebdef": 1,
	"0xb76278881ebaf374d694783f32bd6454fd0ce8dd": 1,
	"0xfa2fb655cd33704475204d37f08bcd5e15289278": 1,
	"0x2408b12eeda918ca676c188b676c3b7d086a18c4": 2,
	"0x47b2597fb65c5518571cdc151b2485dc29f52940": 1,
	"0xb93ba5d87f3eadb4244b319f7ae1f3034f341f22": 2,
	"0x794ee6c5866a9cb7493c21ceb51fd59d5716118f": 1,
	"0xeb8292c049f93eae7931deb68147d6073c41715d": 1,
	"0xd4296e62ee1da9db98a38f3dc3d99e48d40f4042": 1,
	"0x38476bc1deb12f317cabc3f49fbbb80fbab6f3ec": 1,
	"0xfac691f2093290dff2e89ac08a189df479f7b481": 2,
	"0xc67f6302c2f5442f18ad36c46de9b9d7b32a8f86": 2,
	"0xafdf45a1e3517c2b47fcb2785d6bdac21151f7b1": 1,
	"0x8a5097485bb0ff9aa543db7a40571c2afce86f67": 1,
	"0x896d356cf543a49a349781d2a43b9715fc0bb608": 1,
	"0x934c24b05b48f96fa21bb23594cd540439dd3a83": 1,
	"0x5ff51616aca5d3f80e419bdfbd91d81b2a57b51f": 1,
	"0xc8ade2e3dc144edc493c02b238d0e3bdbac7fba9": 1,
	"0x80b3f0d4f6d54ab644d6d5fc2c82722d307cef36": 1,
	"0x0b15dd0ecd4af78ca437c0dfe9a54875b8759ba7": 1,
	"0x72cc8530320faa70de0ae325c1b54d68999ee48a": 1,
	"0x2f0f2e1fd5ea3b78ded02387be5a96d2bc10adee": 1,
	"0xbcb0e147b38e927eecb238a2748177ed64037519": 1,
	"0xc911d42cbb6e01471561fd39d250344774495cbe": 1,
	"0x314175af7f830e83eb5d134ac1cb39a83e722bb4": 1,
	"0x91161a449a6585b595520e68224d0b303062ee56": 2,
	"0x0c3b216ca5bc897205acfc45837cd996ff46e94c": 1,
	"0x62a668edf9829381bbb2ee4d51718c21012c5cc8": 1,
	"0xd1b6f43d73d97b3bdc4a99357abf4145adfd70f7": 1,
	"0x66ed543cdf51c856c1528a2edeb0e38115e0d987": 2,
	"0xd40f0604fd125e3711036a34f7073d365cd842de": 1,
	"0x87a07c2a7f0ee40df010c87cb1ebf45a5168f259": 2,
	"0xb0190f5b1b894e1ba7aa2f26cf3b6c0ae43f6794": 1,
	"0x1d23c7f86e7e646edbc9de431bfb7f0b774d5eb2": 1,
	"0xdf7da93d3d4aad44b3de9872617e27741602d2f7": 1,
	"0x77f84dbb44d51afd5a14bc2b02b804bdafaa5ba8": 1,
	"0xc7c22a54d31e5fcada4544d88f9aa60b6208a617": 1,
	"0x7b6d11aed51c1d520df736121e7a93312fc67468": 1,
	"0xf804b116dcc9e0a086fcfb0c2f2e83498630f0d3": 1,
	"0x06c6ea74b45ac234aff35c972bcbe0dc57f477b4": 1,
	"0xe21834dca31ba47e726f470b5eb2d00d214a8639": 1,
	"0xb06a019f67cb4df16d29ab580020c4ab22a0daa8": 1,
	"0xd3b243788fc0637bd9dda2229beb524741400eb1": 1,
	"0xd7adfd69664814660a1ea2d7c671e41d7edc05ac": 1,
	"0x2b5ad0341367ca7be5b03506b804f0d2780d1581": 1,
	"0x15bf3434f3818647e9648029b3b034a022843460": 1,
	"0xe58113a818e70a995c0ae6ad190dfc3142cbf517": 1,
	"0xe3b2a6f3c4a3abd0d2e1e0e209cfc82c07b4fac8": 1,
	"0xbbd944e53bf27cf15ccddb7acd213e60fe45e3e8": 1,
	"0x898364d971b4827a91de2702b88d7930e0574a38": 2,
	"0x524afce7eaea5cb741f9d4c398ec3872d4e22ae9": 1,
	"0xedaa3121716d80af6433ed9a9eafdb185117e51d": 1,
	"0xc342b4cacddde06bfd62ad0207f9c78eb7cbbbdd": 1,
	"0x0927ecc8ed3a692e8553166aa6a954f2903d0055": 2,
	"0x958d7ee0b2f039d42c83c32c3a4e156fa9dfa5ef": 1,
	"0xaa1efe7707224685e69836fac0eccee89dbc453a": 1,
	"0x30c4c40728cfed7200d348f968c7af9219a84636": 1,
	"0x6e87b9580d7407644311d11e9b3914689ec6e5bb": 1,
	"0xaabd0eceda5f780f0ada6f9c2aeac5bafc394a9b": 1,
	"0xc80e02e88cdb677e61ee296c3188d333541f1d07": 1,
	"0x64a8f204c5f77093d6aa5d8301458df4adca7e42": 1,
	"0xbb4bd53fe4d8c7d73535b34d7d17caec9e5125a5": 1,
	"0x64dcbead3b25b94c1c07158c8a6ad6517b95513e": 1,
	"0xf567a2325429ac5aac4f6fe6aa99e208baab71ff": 2,
	"0x03270912905f63b1b2b99b9a520a86810aa07f89": 1,
	"0xaf819c7d5b9e98afd3b59561a33067a94393e9d2": 2,
	"0x8c815bb2debae39e09d15e5dc523dab7ef7621f4": 1,
	"0xee90784d86322fa0d79b5f43a915851b4fa9f88e": 1,
	"0xf272ba9a2584af2943ec2f72394926449fd12ed4": 1,
	"0xb494548d1cb6a9b3a512f0f47ed073df1ac6fa98": 2,
	"0xf2d1f5dc37770dc17dd4a4be4ed43577d89b1373": 2,
	"0x5de30d3e52a381b615f01377f7357e7447e2dad5": 1,
	"0x5f0974acbdb08f68e97afdc2bedaafacb463a766": 2,
	"0xd78352667839e0de1973560948c06a8ca1aee4f7": 1,
	"0x8da2e5d69b15dc0af69c58713979e9f8189a2da8": 2,
	"0xe60fa550907f7f836773cb839f1809b01119ad72": 1,
	"0x4c5a85a9c350f05662e5ec8df50632af8ac933a7": 1,
	"0x818c37a650affe0f0d86f0c41ff1ae46d99d4830": 1,
	"0xca405705ffc606b437fcffcd85e6b3adfb164216": 1,
	"0xb52ad0fef1b864b03a4f8a4343afbda3488854e5": 1,
	"0x45e294132f31fe2060e2a1266a89a4596e9a19d9": 1,
	"0xdfd4deeb31fa8dd8f3fb6c817bd6e22351ea2da5": 1,
	"0x79eed13cf2d1530e36d07b9a96c17ab795302a54": 2,
	"0xf1496121504a2e33384ab8a34c4f5b996741f7b1": 1,
	"0xc044b6938838b479cb73c410f87e5b4f2c02744f": 1,
	"0x0eb7898422cc8461193f817965ffc222d347c7d3": 1,
	"0x9f62c63a9ea0700b1524a3d5cf0a1b4f56e6d5ed": 1,
	"0x4f080b0a81a8ac976ff06e6d6853ec5dac9ea550": 1,
	"0x36d1c39e212ee67705ee83b575f8e783c0693434": 1,
	"0x939ee6dd05989b698bad2702c2e40572505c638a": 1,
	"0xc966ed9426784c9eea3a518c8dbb30f8896013d2": 1,
	"0xce86d2d1e6a539683b19f02c4668bda23efe7050": 1,
	"0x222a3c8f9c174d3069988c531feea0f266dede27": 1,
	"0x1e399bad818a70d89dd430eaa8e76888f2fca14c": 1,
	"0x0ca725f5156241ee040506c4603b55d5b7586d86": 1,
	"0x0b3bbcffe4bfe08cc923e1da8131abb2de10e0ee": 1,
	"0x7f8666872aaf01792cca0d08feec12d8acb950fc": 1,
	"0xa6f06c58d54392a46ea15d6f80c80f8abd3bce66": 1,
	"0x8f76d8d3c733b02b60521d8181598e4bc1e7dddb": 1,
	"0xb001a29940d2e372c828fddae4d6b66b5d2e740e": 1,
	"0x6bc25bd54e8761690c3d94dd52198216ec965462": 1,
	"0x837810f38097f29f640216415c844dfc61f738ac": 1,
	"0x4ec2dcdfb3c165da62dd1367cb42fe7551524984": 1,
	"0xe9b08f1c84a26cf41512f8d5b505f28b77946a25": 1,
	"0xa383ef38406717edcd595788421a32c54722957b": 1,
	"0x17a0bc4df4805bf5fe542374a6f483cbcacf457c": 2,
	"0x2af08d6ede8e506635e8259293487c972779f086": 1,
	"0x449a3e3bb1f90b53c9825903bcc2cfcc5fce7a47": 1,
	"0x02b79340d53e5443d63e1645feeb3e8d9416e56c": 1,
	"0x5ff4a620fe2e7ab1a947b7b1be5b1719d3c7f4ca": 2,
	"0xe674d76a82808db076258e4cfb1c757f85b5a572": 1,
	"0x49dece7f945f8661f9e77127009af225b6078cd0": 1,
	"0x29a7953eb09efb1f71efe83bf1be18e6c823185e": 1,
	"0xad7775e434c3f263dc08fcf9c73abdbb88aa57a9": 1,
	"0x332460257cef06f02315b2d3e99b086079bbca15": 2,
	"0x31617a069d81f016ff26c5258034659a52992273": 1,
	"0x0a8bb1e5a4888750ad516d59fac535c20a87dcb8": 1,
	"0x77ef6042c83ce72c938d74388f8d47b924abc726": 1,
	"0x3557aae1595e9d55434d948d96cfc3ec968544e7": 1,
	"0xe6fe1582dd3da9f0328702aeeb688b66b4f6212a": 2,
	"0x8a7b713082ac19a4c5bd5084eba5cd825d55c723": 1,
	"0xdd72d0f966b4b422c514911673697e828b58caa2": 2,
	"0x9c6292b26df61da254767549339abb18d277d764": 1,
	"0xa8386423540243bff22820c5b879434695315403": 1,
	"0x904856a03a07e6820c845d9055dee3b369c472f8": 2,
	"0xb6b07b28c90b068f90553463844f21d1f9087f65": 1,
	"0x518d4942cea08fcd9985563aff7b2288ecc5c8d3": 1,
	"0xf7416de86499492ecb021bdc5dd9ac0774e2c3f9": 1,
	"0xf7c4d51f7704cb2d38941dd0287a23107a0c72b9": 1,
	"0xa801582fd467d321f4a94fa7a7b7d763e5b78476": 1,
	"0x1419c549815df4d66627210c5c88fcc98ee07a43": 1,
	"0x58ba15026605bd25d79d1eb2822ded321d7796e1": 1,
	"0x173309061808168ab41179bf117d3dfd59c527e8": 1,
	"0x5d92d031024e368b5543c6da50f0cf392704511b": 1,
	"0x4f209184399bfc7e00f72d43b3c2600a0e46c7f1": 2,
	"0x7ed01de20588ead932b324bd8195d8294094618f": 1,
	"0x454493a5708bc07360cec234d1d67f3d62f775c7": 1,
	"0xa749b4ce79d1c74f7d9e71520cdc0bc6a838d7e2": 1,
	"0x6b52386e10711b8c44b4b8acd023e166f0f503aa": 1,
	"0x41c0098d4106b5cfd7e5ec6fc15b7aecefbd3584": 1,
	"0xbc56711c9ba066c481a7cbb3c9c14cd1ced29630": 1,
	"0x1eb322c016815ee5b29c071586c1b75be5934576": 1,
	"0x62329ac0bbd66f0d0f76048814783c1f3353229e": 1,
	"0x143bfab234e2fb460d50478ef1225519a41dff38": 1,
	"0xf8dc3e8535ef8a2b0a0b04c03b56d3f76521a5f2": 1,
	"0xd76ec40e32c53a46ea39ff28b449e8d63f18ed84": 1,
	"0x89db3072813a9feaa7048500373666dedfa0bb8f": 1,
	"0xe487180770b46df07820f3f6492631e3cd0bc5f1": 2,
	"0x05e1df6888dbefa858a6bd3bbf0229f2adaf8e32": 1,
	"0x7dccfe1649e0c0a7bb6b8f27fc2179dc0affc957": 1,
	"0x11ca77a7ff3a14d992e70bf042f056485a40a4a6": 1,
	"0x6ddce75d3b86be954933dffd80a20a9bb39d1ebf": 1,
	"0xa9cba222a9e8c6d17a7c6459015f0b08a4c2457c": 2,
	"0x08a35efba0772b727cd7216139118df053718568": 1,
	"0xf5fad31d7e48d083afc1448052f13a178dc7030f": 1,
	"0xbe2603a8b623f051f0c53ff2aab2237fc81b6aef": 1,
	"0x62509ddddff46b3e998855c618f22ea03523dab4": 1,
	"0x5a5a3f28685c1309822606348068d3a29f244e1a": 1,
	"0xca704a61c5e1525b418d21579a828fe1cfa725c0": 1,
	"0x54d8812402d097bd65946b4a702586590626f8d0": 1,
	"0xd4468aba62f1b994a04792d50ce72c4fbd46a661": 1,
	"0x037de0016d1141ab6bbe68b586f4644eb1189934": 1,
	"0xf970a8cae45748da65717ad4b3aa93351ebea8b7": 1,
	"0x63d119a61403aa2c8ae5fb0cfcfe1f2c4dab5106": 1,
	"0x8ac603beeda1543422c15d21b246e94be6973527": 1,
	"0xbbf8e770fb0d4e15c6f2459ef8a4ea3c1908fa9c": 1,
	"0x1cc40b6ddba3da13039f64923c2f315b143f6ffc": 2,
	"0xa80217140758bd376037f5fe8e64f3859a1f99c3": 2,
	"0xcb401b1e5e70bbcec47fb857552718047173d3e4": 1,
	"0x814d2e9930503ba0d0939ad06e2be87ee3d0e99e": 1,
	"0x12cba74672205f03ff1176eef4cc2ca3fb60678f": 2,
	"0x34a263293da4a2ca68db5c24891302cfc879b07c": 1,
	"0x7ca24606f740493768216753eb67368e04ce1a75": 1,
	"0xa8d3b9ffcbd1c32faa3c03e4c097a2308d9964ac": 1,
	"0xfac36a189d27b04eb246105081895c61cca6dfd6": 1,
	"0x95644baaa86f31ce2fa34a619b89a9f7cd024589": 1,
	"0x59f06bfc247a8e509771f84852b3ea7e0266b465": 2,
	"0xd413c5f4c0f63232fd6e4edf24ea6fccda070308": 2,
	"0xb57174959a82f4721cfa3a931cc6fc50636b45e4": 2,
	"0x548b218d7d95ddc1fcdbc47b6568d0503b192a78": 1,
	"0x3e76a43c658f34fe5efc665d7546845e15388a80": 1,
	"0x808aea2ad20cd2e82208d877cb02e1e7cac47ca9": 1,
	"0xa522a247a0df5aad799adc8a6093dd2a06442eb3": 1,
	"0xc4bb58addabf3d68f702c0745938aceb020a2594": 1,
	"0xe185b00ee969942ea17794524bb5fd22a418ebe2": 1,
	"0xaf836d10c1c0f20d564bf772d642dbf655757a42": 1,
	"0xd935a8b6a83ffe31d985be4aabf5a52ecc049add": 1,
	"0x19a9afd3278401ec02d0eb837e58fdb6d8a5d0a8": 1,
	"0xfb8335f09904a761a49c9c1c9b1308ac0a3d780d": 1,
	"0x25c80f1b1d5b59e31e9f40fdcce13b1b1f31c970": 1,
	"0xd8be0ff5558a6b4123bce6a3736ae744d0b7d1a2": 1,
	"0x6987ddac1ea3c481b71279eecb0d85815381bee5": 1,
	"0x1cf0487e91b032119ff64f07f9e301ff0d68a418": 1,
	"0x5f9c0a0cae0b6cdc63b906e5d085050e63c86d6f": 1,
	"0x7b75825173b83edfd0a3192e2a5119805879ac52": 1,
	"0xd4a6e87b60b82b5ce4d3aa9f1132c7ac93df1a4b": 1,
	"0xe97176a375c9f9c161733cc5f8f181aad7ec75ab": 1,
	"0x646fd475d8aec8906e17229eacf737e855563fe1": 1,
	"0xf94bbb43e6a411c831674088b3a35a994fb61246": 1,
	"0xbd653ad8b25265f2e7da5cb2108bdd4f756187a0": 1,
	"0x215fff603333eb68650149904df7c3a43be4cabf": 1,
	"0x0d66c4eee0c792b9bd38e97676493ed12a55e656": 1,
	"0x9b9a299aa14e0f9ecf803a21ec2cfb432a9c74b8": 2,
	"0x0f6c8783b59813fe687cbe055940e30d9b5294b9": 2,
	"0x7a8efce6e2c99757a6749d81930a7f70430da12e": 1,
	"0xbde7a00cccfcfb7c1c2dd05d18e51adeba0b6157": 1,
	"0x10b1c123183e191e8e2d5b209323de51c655e384": 1,
	"0xb4e7a9b3b53435568aaa1dc7b8217c332c901bb2": 1,
	"0xfeea6c6167b865782478e87d0944f21d889a1224": 1,
	"0x2b305b98cd476bab8a667376f23dd01a80eeff8c": 1,
	"0x61728ae95d10a34bd53c58ffc3c9c893da8a8892": 1,
	"0x5b26888b2fd5c617e95df5bb7b22644bf2aa5930": 1,
	"0x988f0d43cf5754a1f7b81ab2f6d569ac39127178": 1,
	"0xe33b67862729578522974a5d4d6ef0f21acdadb6": 2,
	"0x86a3719c7682ba9b702e9551a9027fda7da815fa": 2,
	"0xcafba9bd4a2e61a8b197ed77267f81a6abca8714": 1,
	"0xb79e980e8dbfb4204a50b0698337a70148ba7031": 1,
	"0x1a97798d94e9da816b62c5c6cc4b71ae66ab1693": 1,
	"0xe8d5ee67a69394e721cdf2481f633c37ac70ab91": 1,
	"0x0c5428eb5550e031edcad09d55e1c58b003476e2": 1,
	"0x038bba42451025fdb3ec6c4789cdb6491d242e9d": 1,
	"0xa50e725359247be79129015bf2e6eb77e042725f": 1,
	"0xfe07cffe296ece3bba6ae48a62b9ce4768694312": 1,
	"0xf690dbf6db6a0d754711002a05ccd733208277b8": 1,
	"0xb894dd166db4280491fcd9e2244e7d1a80573e98": 1,
	"0x35e257458d97617d80c96ee3b9e7fac589586def": 1,
	"0xce74bea07eebc71d4f90d134806782bcb2d5305a": 1,
	"0x9503aa2b02d3ea301c279556e9933a30e8e50bb8": 1,
	"0xd370d39ef962d504e661d75f28aec670eb703468": 1,
	"0xdc1fa15c96720541ba91cf2843db42732a555812": 2,
	"0xeede6d9bf7e86d07843cdcb030b0eb209d0e21c1": 1,
	"0x6b8af135b822afa19b20affc5b4deef8e94d4ceb": 1,
	"0x0759a632b0e0eeadbb0f03f422d802cc979ddecf": 1,
	"0xb704d04be52eb1a759999ec0e60d97edae9613f3": 1,
	"0xd26f992812eeab5c65eef7bbf5230c2f2e72977d": 1,
	"0x5be4b69db869b83ae8f973943ab0c52269c0f453": 1,
	"0x8ff669dc9d72314f9e5b1020b072c2a70663a6f3": 1,
	"0x2d6a07d48d102e10d511c9b0e14db77ce9c4dd65": 1,
	"0x03f7f1ab10e8ba1f6c3b7ea588087ee12d44dcc8": 1,
	"0x6ecdc36cdceb12c1ea74d11a1e8154629438b3ee": 1,
	"0x48248e89c1abae32bcbcff54564fd4cb6a979723": 1,
	"0x913c54e8bf8034cc07753cfc682cc84a10692dd1": 1,
	"0xbfeae42346c44f25ffabe29cdf0dd80ddfbf67c8": 1,
	"0x3947f1e451c520c7d6e876fdc32bba22c61468e6": 1,
	"0x7022aef9a5e6f76d8a645bd2f16b24e4de5382eb": 1,
	"0xcec07e954f81224414ac0a79249fd64577a0b727": 1,
	"0x862a97babf18cdfa6461219378de33eb937ed1a8": 1,
	"0x91e2945b45621b425cf0e3220d54107c10804852": 1,
	"0xf79568cf869eac49fa85b170717a4b1ab798c2bc": 2,
	"0xac70f4d3da3486a34a6067b78e24df164712c0a0": 1,
	"0xdd49d165409a02258a9f665adb1a3e3acfd87fd9": 1,
	"0x21fca2eaca9be003830b51cee10bac53b256b5d3": 1,
	"0xe76c9900da2923d78097586bed58f802c41188ce": 1,
	"0x44632ff277cb950365d347a8d389edd41456ffc9": 1,
	"0xa18eb8cb4c520b350b8b3b31a6f4cab813ecd37a": 1,
	"0x6841e7796d740f86c672e1a1bad69601d876723f": 1,
	"0x5fe74cf1461dc1e7256b00adfa654a30873efa59": 1,
	"0x7fe3952b37d91f553ceccc9e8b4198ab7db2ab70": 1,
	"0xe39649e045e5a31ffe05b612997b49178973e5f7": 1,
	"0xabfaa77db693326883ebc87fae8ee92affdfc87c": 1,
	"0xb1e9747e8190bc771fd6991da5ff0d06ce864596": 1,
	"0x325fcb94bc1fbcfedd4b5dd1d99f6c58592c7fbe": 1,
	"0xeec695916a7b2e18ee22d5e5fb4969ae50bd2760": 1,
	"0xbc811a7d71018f6056642c2801207b699b1a5469": 1,
	"0x2cc0151ff042d1588efdadb094f9298a8168f81c": 1,
	"0x343da22b8ae74471cd6767a080895e85737913c0": 1,
	"0xa2cfdaa1cb608c777618e3e90819708549cf8ced": 1,
	"0x865f1094df305a8893da4478a4781a5e6fe3b3c1": 1,
	"0x33c2127a98edec03453657e575e67c6eaea6ad25": 1,
	"0x79deb4121f3ef4c19f89ae3ee41e723e7e1f323d": 1,
	"0x60ec8af7ba4e1bf8ae3d3a08c86efec57478c91c": 1,
	"0xca770daf2c9386e963c4a9e0f809b97ce8cfbd8d": 1,
	"0x2c17c733cefc0e618850b546d9df53cdbfa29725": 2,
	"0x27e8cb0983bbe8cd15684157b4476108df65ff5d": 1,
	"0xe9f120bbf6a096b3776fbbda792f27565a2c4a3f": 1,
	"0x6c7408914079353569118eba5bf2fe6cf192134a": 1,
	"0xd344a7aaccc8fdd64e895864ee54517c482a4bcf": 1,
	"0xe105eac05168e75657fb7048b481a17a53af91e8": 1,
	"0xf0a169365b672d899f20a44f01369f99134db3d7": 1,
	"0x04a2b86ec490cf8210f2a34e4c73c1684e1a79d7": 2,
	"0xc634a0475218232b55c94f05bee7b0ff94b676f0": 1,
	"0x0ea2efdbe653856889d56d7a9134ec3400860998": 1,
	"0xc7281596d650800037c372f3eaaa85755013c5eb": 1,
	"0xccae0d294439b73d94980bf5e7d54a5354b031dd": 1,
	"0x6c146ec1fcc756c1244b36bd52001a9a4a65b4da": 1,
	"0x1d2a72dad0fa5d1aab44768752f60822a3e18c47": 1,
	"0xf3a6d7991437629e91f7b84500ddff66296ab261": 1,
	"0x1410da6bd313ef588bb0356457f4bcf4960ace65": 2,
	"0xb7cf718fd4699977d939626b7da7a60045e0e446": 1,
	"0x02b4a7c1cc0a652e639ff110155edea27c2ed29c": 1,
	"0xea66397d88840e4dc1c8d313e696e55e6ca69479": 1,
	"0x41bb1ea2cbd057634cbbd54642ea539c390314a4": 2,
	"0xa7990ca33925283b7e6413f2a21b6cf2077c0d47": 1,
	"0xb71f5a382dbfc1d3093db0f8ef1e9852fe0d07d2": 1,
	"0x797a4cdcd826818327073ed6fb1cd07543ad1769": 1,
	"0x08fac0e72d314ec4415bae8249ae36d976536e2a": 1,
	"0xedd7a2655b26559a63664f062202e1aed70d4a91": 2,
	"0xf6dfcd838cb7d9a46e9523b44d406992db78995c": 1,
	"0x23e817b3982ba0e033dab4fa1b47088418ad6b2a": 1,
	"0xf25287837d5ec09975e5c2a61b5066ead6ca3310": 1,
	"0x698f63228727ecded79e8a13a4ce7bd54a27f2ab": 1,
	"0x6f0ecbe5fcebfecfaf1e55bd1ab274b4272df633": 1,
	"0xa52a05792a01a4c59dffc2e40ba98e0ab889715a": 1,
	"0x31998c146600286de61082d0a8766b238cab93de": 1,
	"0x239dbcddeae1dbb49aee663e786a9c5599031bd3": 1,
	"0xd1c2fe84e8a324104d09097407c2abdb4098f49c": 1,
	"0xfce31ba3a7bf1ccbb0b368e91d8106ef734fc0f2": 1,
	"0x3d4408134cf6f1a630229344720e266aa1cb89c6": 1,
	"0x0bdd47cf543fb4d2e2efc360fa423f736e846fb6": 1,
	"0x0b881031b5982cfbdba7dd27064bcfafeb686be9": 1,
	"0x3a3f538273dd00a8f907d430f0fd478b2e85101f": 1,
	"0x3e36e94d732e21d8e865b611ffdf81a18a554658": 1,
	"0x08a2588cebf09a836f5b83ef386becdaf81fa58a": 1,
	"0xcb724b89c7a910143390c82fce392cc3d5fb9478": 1,
	"0x93a6c71efe9efddae8ea90ef455a20be49c9c4e3": 1,
	"0xdd3da36cca12e431e07a667206b91f4c7641cd05": 1,
	"0x53847c96ab7276497fd11b3a75b68bd873bad7ea": 2,
	"0x11f1df57f9c69c4e37ddd3e5d8eeb7253c0f081b": 1,
	"0x7ca980fd7d54f2c64cc6702d7eb5a1bfb94858bc": 1,
	"0x38d414a1ce4770cfcd2698f3d895b98dcb8bf384": 1,
	"0xe2730ff931f013b013be69007eadd86de8eb4f63": 1,
	"0xf360eb08f2db21fb926ab8b9aed942492dbad6c0": 1,
	"0xea0c2f23562ad3c545334923e4bcc9b9e04617af": 1,
	"0xa2b7ce040e05d122246c732c96bbcec45baa711f": 1,
	"0xd0aedfb8366a3b94c0a1e8431ab3704b0db707f5": 1,
	"0x5668974a526223fff04d8f8e0f603aa75789a07a": 1,
	"0x7f7b4781a2a2d025e269cd51e217cf537626b83f": 1,
	"0x3511950d20eab901343a13bc1263256d8ceee19b": 1,
	"0xd8869aa2f387a8eddd4e38b6a77247a1d0be7d77": 1,
	"0xfbfc95495ea66159e95751d3dded4d404f13cdb1": 1,
	"0x14b84f83eb139a0100952967a9b9549f955684f4": 1,
	"0x3bdb71e27805cb24ae55a251592e6680125e4926": 1,
	"0x4b43f9405d83bacd59b9c96decd363930db22778": 2,
	"0xa7b2ad1509550aafa62347788687c7c426cf0dfb": 1,
	"0x7851196bb63c6218d375e6a0d90b7f68cfce77fd": 1,
	"0xccc9a5bb279cefa2e1e38ba5cfeddb1afdf726ea": 1,
	"0x737254a9c2c1143c9417ea7899a31fb477fee92a": 1,
	"0xecced53f70afa9a884670e72168699f55c06e0c1": 1,
	"0x70f4a2ce11a2524b6686963a5de86e336ee56ebb": 1,
	"0xedf7b585263493c7e5b36c007efbb03d89cbc368": 2,
	"0x9f2a763a3db7b12005157aa9b41894ff96215c3f": 1,
	"0x3786bae29d35fb41ebd66ffdcc55f1ab30aee7b2": 1,
	"0xa06bb43e6082813ee76484a688bdc2c4408a7207": 1,
	"0x79009d48413cf7a660f52bd398b9f75b396b8e40": 1,
	"0xa1dc5122224590445502aea021eb94a370e6720b": 1,
	"0x6913659d52d4a631caae1ec8a101615c73c307c8": 1,
	"0x4959aee7ee78186100f725573703a6a5701f645a": 1,
	"0xd1fda14e0b04777a51f5a1dc86a37f0b0703a9b1": 1,
	"0x48da28b40264d391797dea5a02220e1f0e2efea4": 1,
	"0x3686a4b272c646ef6fbe34377337d95db7356e63": 1,
	"0x0b22861932d2845db1543319b8512604f777c761": 1,
	"0x6c92a763d1ad5fb1cb509e014217816dc5ade475": 1,
	"0x7a13bfc2f21809a0ec1c8f787dfa5136579eb6d1": 1,
	"0xb645c37720733bd890d29ebe5b1caf0821baf859": 2,
	"0x2640c2bc04596de4414180ec309709b1e7459c3b": 1,
	"0x416fcc85c31dd1d44aefdef7d214d06d21ca95a5": 1,
	"0x3f27bb14da63ab205c46db79ae0596fc722f312b": 1,
	"0x6a225890077ed46783a3f9e42303917868168b55": 1,
	"0x65c054647f781e6103746ef5e67aba3e091cf00d": 1,
	"0xea753e4612e2bf9e7458f16025a4638c4d495b91": 1,
	"0xfdaef36efae3f9427693ffae7ca592d9a6598aa0": 1,
	"0x03ea3830138e1a986a8013e180a6de5a8beee6cd": 1,
	"0xc00fcf03fcf2ae8f53ea8ed0303b9a5cb15e0ddd": 1,
	"0x4fd89b53ebe6b44946d66a489f4fa88a5533279a": 1,
	"0xd040de160c8c54ce3b4e940207d533362b4a14b2": 2,
	"0xaf44d3b2f9fdf5527a9b6cf3a35e47009ef7105e": 1,
	"0x670fa3a02c6d034372d6d09abfab095d3e576dd4": 1,
	"0x7b7fd6f4fc92d0174b8d7e2fecdd019b98dda62e": 1,
	"0xa45a3692e37089ce1afec88921650cd1f1c2c6bd": 1,
	"0xdac752245d44f396630c84394e5db9b5de6614dd": 2,
	"0x8c190d32def3c52d087c7e2a650e81517841ec10": 1,
	"0x7b5a56a0b3fae32e84d418e74a0b6b3058392548": 1,
	"0x868d86e7eb6941c92ca24be6fc87440bc4ace434": 1,
	"0xac93fdd8b57659ff628ace8420aa19d9053fbfe9": 2,
	"0xf7dbf20893c572cbf3556c229b0bab11a0f71766": 1,
	"0x891f4f34f5db8e24f57af56609407a83d4fd9840": 1,
	"0x6c2b03a2ffe7c4ff8bac97102c76914ec0a97ab5": 1,
	"0x0d1c262d2e307327b444adb6d3813bed55ccbbc8": 1,
	"0x89059c0a9019f21f9218ac38c25de1dbdaee9c1d": 1,
	"0x74521a24cea3dce3fb448f8d03d7e2c08b524453": 1,
	"0xccab967d7153d39cb5b85d1ec92c8352825857c5": 1,
	"0xb75d50f140eeae953d59c2677a53cdabdaccf337": 1,
	"0xb95a01d3b437c57631231bf995ca65678764b2e8": 1,
	"0xa4758126c2df1d2a3fd462d9f4305c781776156e": 1,
	"0xc12eb97f725c5d16d895c8aabf07672f547c537d": 1,
	"0xcae281f1d1abe7a33cb85787183a02ac71499f26": 1,
	"0xe7166c59437c0133e9fb12fb6d8526c7e079bba9": 1,
	"0x28b54e898edd3e758b9b3cf202b4be2e6a50e9a5": 1,
	"0x8949b4cd9588adcfd8c107489aa24e74e5814e80": 1,
	"0x1e8b35d3833a5b411bda14a55f08e1989666045e": 1,
	"0x1ec01ce7d15d2606ec7407268aad3712982ca7b7": 1,
	"0xbb27ae1a4e393cda8cce7a12605bd3eabf264a22": 1,
	"0x577b64911abfbf32a58511c59c83163582cef314": 1,
	"0xda78b6f1dbde863a54b57b32e34d81e6bff85129": 2,
	"0x9028a83f5b4a16e2f1e6b0beb39e7e433efc3da5": 1,
	"0x4fcd42eb2557e49c0201fb3283cff62c1baf0fb4": 1,
	"0x3533c01997cb5b85335177ab0e1eb905f622b6f6": 1,
	"0x64ffb8f84f666a3e6c31f31b403f9a1d3afc0451": 1,
	"0x8280142f03efe15ac4a84b01c64d4d90abcc5cfc": 1,
	"0xcedb0fd903ae5dc6d7ec633a40dd7ea7833759a0": 1,
	"0x707618a87918157652d887d21c172afd20281a3b": 1,
	"0x72b2b9d02fa390285adce0beb219900cc749c938": 1,
	"0x08656e5ef8b9eced1ff1cee99058a93c603ba3d0": 1,
	"0x526dc23263a5ed4ec20b0944ac1951c348199ef3": 1,
	"0xf93f61f3b13c1939c02c681c0c361a94e8a52c69": 1,
	"0xca62b87082c5d0bb7a6f458aa50aca8767d3b77c": 1,
	"0xc2402ddb90e98a4e2fe8f485ec1e6c5b85aeb41d": 1,
	"0x94d58368400c9eb43a7a8fbc55d637efb010844d": 1,
	"0x62227a504b354877d8b817a5b37426a2869dad67": 1,
	"0x78c17d37f0d78949ff7b66804cf26468af7d20bb": 1,
	"0x0134178fadf8ee733c5061c83ea8713e715327eb": 1,
	"0xbe88d5297338de35ace2562523503bee17e82808": 1,
	"0x94c2d0bb54606161190e7a3e94bd8ac9e24ebd3f": 1,
	"0x19daa43e03c02bc7eb3918a2283138428731e4a1": 1,
	"0x657947fcd6b16bb344c66e083e1b0bc38322946d": 1,
	"0xb880698f12bb817de65a36e02a8906eb0e46130f": 1,
	"0x5f2436e32937c75849907add2d5f5b9f3a2f7e27": 1,
	"0x1c05effea8a7505abfeabc1aa0a8de0436d40e93": 1,
	"0x17ba566c3006e252928ca3c3d9e3e756eef50c02": 1,
	"0xa472ff4e279fd61c03c4032983be8e6b8e34e83a": 1,
	"0x74aff883b64ca8485bf7e071559d467c88788e88": 1,
	"0x6d23e2fe0396703e9bfebd84cc4106bc3b010513": 2,
	"0x76d1b95ef7b01f964d6f6ab28132ad0ad2799d6c": 1,
	"0x44ff333305e2d29a6bbe24d15599be4427f84bca": 1,
	"0x1ee76843d9ed6c58295c88d6f9ed1615413a9b62": 1,
	"0x398738f63ad44302c5c4e718bdab6c1da2a11cf1": 1,
	"0xeb84c0b66832ab3835c1d9df188b15e8f1858381": 1,
	"0x6ceea0fc41387fc6780aa78125ffde3ece0dc103": 1,
	"0x5722d3357bbef01b949a9e2588eb0a2a54802a19": 1,
	"0x74037f22b6fe1682eec9810965dd05ab4fc9ec7e": 2,
	"0x1660f2af4f45f8898d01033b5c6f938a4bfed6ab": 1,
	"0xe12dd116fbc914981b87af179d9664b0e9d6f4df": 1,
	"0x4bf9ecba24bc6c7e116a212d00598b46f8c4effc": 1,
	"0x209ffe17e95af3a05bf26aa3e6254ad03ce8a6cc": 1,
	"0x97919b2f3bc8fe7ea18b6e7bc8b25d55348e5a66": 1,
	"0xd80438e0cf0b60e41ee8b21387b724f6609b5ce4": 1,
	"0x6d9a099c553f843e8839ac26ec1a300a0fa25090": 2,
	"0x2f9bd5877806257e38ccf67e4337da1732815d40": 1,
	"0x8105046a6817ac0251fdc248bb4f53e268b9a318": 1,
	"0xee72b38f98bd65811e26b2d239ec94d30c1f9457": 1,
	"0x741dd79d430a7b7dc4e67b68741294fc96888888": 2,
	"0x795a72371f8199c20cf06dc74309cb19fce220a8": 1,
	"0x6d86e669235bc4098a564acc8e9b42bdd1959332": 1,
	"0x5a6c0ff57d4416bd1158d68ce01973a66a35dd1a": 1,
	"0x2b23e42927e22f74cbc60687af16b31a0bc3a578": 1,
	"0x1721e4489fe2b2a9a8c95256028e7f3025c50569": 1,
	"0x1b8cce2cdeee961ae6e7311e535613c512957ad4": 1,
	"0x02c7f6fdf8bb97f0518dc8c1b9500252dfad58cf": 1,
	"0x4bf0f194ae49cecd55cb74eab956b16bce86da4c": 2,
	"0xd94dbf2c1fc5b4c9ca277efc7ff19da7659d9925": 1,
	"0xd4b41aa155e48ebc36ff4cc630a0d4e1a103f6d5": 1,
	"0x43cf8418ff2ebde978e7bf87a1e771449c5677ec": 1,
	"0x2f47f3c6b6013fa31e089efcd7f97f0e4acc4061": 1,
	"0x47e2aca71bbd528fb804784450a433e6f85c2d92": 2,
	"0xd1d8dd10988e390c7e83b0fde4f000ad6fbfe0f6": 1,
	"0x22af27afa6a6cdd6942df8674ef160b46428978c": 1,
	"0x1c691653ad87ab8e477c95bf865fc49ca2b00549": 1,
	"0x73bf2cc8a3f327fb799422e946ba8ff8f5db48e6": 1,
	"0x23a740e104a947e876946d44e1c5e556c286a227": 1,
	"0xaa0c64a7729cadf94cde9d1a1244d96792d2e9de": 1,
	"0x1678faa643e8f8ce569887cff40ca56961dd7526": 1,
	"0x533a7adcb040d1b160b73da5b31252cb94366548": 1,
	"0xec1aaf5c0ac3599c9fe44e35cf39838d9345d2f3": 1,
	"0x1e10b48d43df8458c8f927ac33a2a53cb752f7e2": 1,
	"0xe80a09b295ca4824cf5990ca0d7af36dfb2defa4": 1,
	"0xf934d60da1f027d0b9b6caa52bde6583eb171f46": 1,
	"0x51597c5ef4b7643245f735558e7f67ceb50a6a7f": 1,
	"0xa69e88ebb618810880179208451585e03c6a878a": 1,
	"0x04cf16087bc3f0a4d0ad80f1c89033440168b846": 1,
	"0x2edf1b3374234d8bf7e95a01c95b47121b75e17f": 1,
	"0xfca2c5658acbd80ee4c4d3aca5ff52dca554f5f4": 1,
	"0x4a95d13f06b0c80b69160a56da31dbf217abbfb3": 1,
	"0xfc1708fe24383cc91c46f9ee360e61335ade5b1a": 2,
	"0x319279214fd95cb31297c72bbb86f4c8d3919a65": 1,
	"0x04b419cddb1782a8e6aab81f346c47c467ac4a6c": 1,
	"0xb79e2602a4f6f9a0cff0353f1942f874fa125771": 1,
	"0xc59f2589afc329bd0008d7ce19348031dffa28aa": 1,
	"0x977223ef93b8490e8e6d2dc28567360f489a3ee1": 2,
	"0x6ad0a737f3c6d16d00f543cb388f322ae4a8bb8a": 2,
	"0xd015af3fbc321051b27af641d66fe9dd1bce4e9a": 2,
	"0x330ad0d38e9c12cbc34d7cffefcd99e5530b6bfb": 2,
	"0x162e8a6213591dd1deffb42b24153cfb98a55299": 2,
	"0x3c6730546829d451fa62dc214693002b4747f162": 2,
	"0x940ebf4da65d5b7a161799857a65485ae3d47be5": 2,
	"0xec0cfe573d7f1234a91e745ef1480d5131a0a610": 1,
	"0xfd44308a89a56c1b5184de866cd43a5627ef8608": 1,
	"0xf12bf636cd288e9abd7d30195fa0c2c0de6c7014": 1
}

if (process.env.NODE_ENV === "development") whitelisted["0xc9c680f1a3dd990787f67cd0adcd2f44e5d32858"] = 1;

// 0 - was never on whitelist
// 1 - is on whitelist
// 2 - is an OG
export const getInitialWhitelistStatus = (walletAddress) => {
	if (typeof (walletAddress) === undefined) {
		return 0;
	}

	if (walletAddress === null) {
		return 0;
	}

	const adjustedWallet = walletAddress?.toLowerCase();

	if (whitelisted[adjustedWallet] === undefined) {
		return 0;
	}

	return whitelisted[adjustedWallet];
}
