import classes from "./Header.module.scss";
import ImageHat from "../assets/images/hat.png";
import useMediaQuery from "../hooks/useMediaQuery";

const Header = () => {
    const bp640px = useMediaQuery(640);

    return (
        <>
            <div className="text-center mb-5">
                <div className={classes.title}>
                    <img
                        src={ImageHat}
                        alt=""
                        width={bp640px ? 125 : 75}
                        height={"auto"}
                    />
                    Fast Food Wolf Game
                </div>
            </div>
        </>
    );
}

export default Header;