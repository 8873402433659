import { useEffect, useState } from "react";

export default function SuperGrassCountdown({
    period,
    start,
    className = "",
    countdownFinished,
    setCountdownFinished,
}) {
    const [countdown, setCountdown] = useState();
    const [countdownInterval, setCoundownInterval] = useState();

    const getRemainingTime = (remainingSeconds) => {
        const h = Math.floor(remainingSeconds / 3600).toString().padStart(2, '0');
        const m = Math.floor(remainingSeconds % 3600 / 60).toString().padStart(2, '0');
        const s = Math.floor(remainingSeconds % 3600 % 60).toString().padStart(2, '0');

        const hDisplay = h === "01" ? "Hour" : "Hours";
        const mDisplay = m === "01" ? "Minute" : "Minutes";
        const sDisplay = s === "01" ? "Second" : "Seconds";
        return {
            hours: { time: h, name: hDisplay },
            minutes: { time: m, name: mDisplay },
            seconds: { time: s, name: sDisplay },
        };
    }

    const getRemainingSeconds = (start) => {
        const stop = start.add(period);
        return stop - Math.round(Date.now() / 1000);
    }

    useEffect(() => {
        if (countdownInterval !== undefined) clearInterval(countdownInterval);

        const interval = setInterval(() => {
            const remainingSeconds = getRemainingSeconds(start);
            if (remainingSeconds <= 0) {
                setCountdown(getRemainingTime(0));
                setCountdownFinished(true);
                clearInterval(interval);
                clearInterval(countdownInterval);
            }
            else {
                setCountdown(getRemainingTime(remainingSeconds));
                if (countdownFinished) setCountdownFinished(false);
            }
        }, 1000);

        setCoundownInterval(interval)

        return () => clearInterval(interval);
    }, [start, period]);

    return (
        <div className={`flex justify-center gap-2 sm:gap-4 ${className}`}>
            {[countdown?.hours, ":", countdown?.minutes, ":", countdown?.seconds].map((item, i) => {
                return (
                    <div key={i.toString()}>
                        {i % 2 === 0
                            ?
                            <>
                                <div className=
                                    {`flex justify-center items-center 
                                    w-[61px] sm:w-[105px] 
                                    h-[73px] sm:h-[110px] 
                                    border-4 sm:border-8 border-solid border-[#B01D18] rounded-[4px]
                                    mb-1`
                                    }
                                >
                                    <span className="text-xl sm:text-3xl font-bold">{isNaN(item?.time) ? '-' : item?.time}</span>
                                </div>
                                <div className="flex justify-center">
                                    <span className={`text-center ${!item && "invisible"}`}>{item?.name || "."}</span>
                                </div>
                            </>
                            :
                            <div className="flex justify-center items-center h-[73px] sm:h-[110px]">
                                <span className="text-xl sm:text-3xl font-bold">:</span>
                            </div>
                        }
                    </div>
                )
            })}
        </div>
    );
}
