import Header from '../design/Header';
import Frame from '../design/Frame';

const NotFound = () => {

    return (
        <>
            <div className="container mx-auto p-1 sm:p-5">
                <Header />

                <Frame>
                    <h1 className="text-3xl sm:text-5xl text-center">Error: Not found.</h1>
                    <div className="mt-5 mb-5 text-center">
                        <a className="btn-frame" href="/">HOME</a>
                    </div>
                </Frame>
            </div>
        </>
    )
}

export default NotFound;
