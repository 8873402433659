import Header from './design/Header';
import { useEffect, useState } from 'react'
import { WalletHook, watchTransaction } from './utils/ethereum';
import Container from './components/Container';
import { buy, claim, getBalance, getClaimed, getClaimPeriod, getLastTs, getPeriod, getTicketSize, getWinner } from './utils/superGrass';
import LoadingModal from './components/LoadingModal';
import OutcomeModal from './components/OutcomeModal';
import EthereumInteraction from './components/EthereumInteraction';
import WoodButton from './components/WoodButton';
import SuperGrassCountdown from './components/SuperGrassCountdown';
import handleResult from './utils/handleResult';
import { approve, getAllowance } from './utils/erc20';
import { BigNumber } from 'ethers';

const SuperGrass = () => {
    const { wallet, chain } = WalletHook();

    const [prevWallet, setPrevWallet] = useState();
    const [approved, setApproved] = useState(false);
    const [loading, setLoading] = useState(false);
    const [transacting, setTransacting] = useState(false);
    const [response, setResponse] = useState("");
    const [outcomes, setOutcomes] = useState([]);
    const [intervalDataPoll, setIntervalDataPoll] = useState();
    const [countdownFinished, setCountdownFinished] = useState(false);

    const [winner, setWinner] = useState();
    const [isClaimed, setIsClaimed] = useState();
    const [lastTs, setLastTs] = useState();
    const [claimPeriod, setClaimPeriod] = useState();
    const [period, setPeriod] = useState();
    const [ticketSize, setTicketSize] = useState(10000);
    const [reward, setReward] = useState(10000000);

    useEffect(async () => {
        if (prevWallet && wallet) {
            if (localStorage.getItem('isApprovedSuperGrass') === "true") localStorage.removeItem('isApprovedSuperGrass');
        }
        setPrevWallet(wallet);

        if (localStorage.getItem('isApprovedSuperGrass') === "true") setApproved(true);
        else if (wallet) {
            getAllowance(process.env.REACT_APP_WOOL, wallet, process.env.REACT_APP_SUPERGRASS)
                .then(allowance => {
                    if (allowance && allowance.gt(BigNumber.from(0))) setApproved(true);
                    else setApproved(false);
                })
                .catch(err => {
                    console.error(err);
                    setApproved(false);
                });
        }
        else setApproved(false);
    }, [wallet]);

    useEffect(() => {
        const initData = async () => {
            setWinner(await getWinner());
            setIsClaimed(await getClaimed());
            setLastTs(await getLastTs());
            setClaimPeriod(await getClaimPeriod());
            setPeriod(await getPeriod());
            setTicketSize(await getTicketSize() || 10000);
            setReward(await getBalance() || 10000000);
        }

        const correctChain = chain === process.env.REACT_APP_CHAIN;
        if (wallet && correctChain) initData();

        if (intervalDataPoll !== undefined) clearInterval(intervalDataPoll);

        const interval = setInterval(async () => {
            if (wallet && correctChain) {
                setWinner(await getWinner());
                setIsClaimed(await getClaimed());
                setLastTs(await getLastTs());
                setReward(await getBalance() || 10000000);
            }
        }, 5000);
        setIntervalDataPoll(interval);
        return () => clearInterval(interval);
    }, [wallet, chain]);

    useEffect(() => {
        if (((Date.now() / 1000 > lastTs?.toNumber() + period?.toNumber()) || !BigNumber.isBigNumber(lastTs) || !BigNumber.isBigNumber(period)) && !countdownFinished)
            setCountdownFinished(true);
    }, [period, lastTs]);

    const onApprove = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await approve(process.env.REACT_APP_WOOL, process.env.REACT_APP_SUPERGRASS);
            setResponse(handleResult(result, "Approve"));
            if (!("hash" in result)) {
                setLoading(false);
                return;
            }

            let hash = result.hash;
            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Approve failed. Check transaction.");
                }

                localStorage.setItem('isApprovedSuperGrass', 'true');
                setApproved(true);
                setTimeout(async () => {
                    await presentApproval();
                    setLoading(false);
                    setTransacting(false);
                }, 2000);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    }

    const presentApproval = async () => {
        const o = [];
        o.push({
            message: "Successful Approval",
            source: "/images/claimed-pack.gif",
            link: "",
            linkMessage: "",
        });
        setOutcomes(o);
    };

    const onBuy = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await buy();
            setResponse(handleResult(result, "Placing sheep on the Supergrass"));
            if (!("hash" in result)) { setLoading(false); return; }

            let hash = result.hash;
            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Placing sheep on the Supergrass failed. Check transaction.");
                }

                setResponse("Placing sheep on the Supergrass has succeeded!")
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    }

    const onClaim = async () => {
        setLoading(true);
        setResponse();
        try {
            const result = await claim();
            setResponse(handleResult(result, "Claiming"));
            if (!("hash" in result)) { setLoading(false); return; }

            let hash = result.hash;
            setTransacting(true);

            watchTransaction(hash, (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setResponse("Claiming failed. Check transaction.");
                }

                setResponse("Claiming has succeeded!")
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            setLoading(false);
            setTransacting(false);
        }
    }
    const transformNumberWithCommas = (x) => x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <div className="home container mx-auto p-1 sm:p-5">
            <Header />
            <div className="mt-5 mb-5 text-center lg:flex flex-wrap justify-center">
                <a href="/" className="btn-frame">Home</a>
                <a href="/barn" className="btn-frame">Barn</a>
                <a href="/game" className="btn-frame">Restaurants</a>
                <a href="/war" className="btn-frame">War</a>
            </div>
            <Container>
                <p className={`text-xl sm:text-3xl font-bold mt-5 uppercase mb-3 text-center`}>Supergrass</p>
                <EthereumInteraction wallet={wallet} chain={chain}>
                    <p className={`text-lg sm:text-2xl text-center ${wallet ? "mb-6" : ""}`}>
                        Invest {transformNumberWithCommas(ticketSize)} ffwool -{">"} Place your sheep on the Supergrass <span className='font-bold'>for {transformNumberWithCommas(ticketSize)} FFWOOL.</span>
                        <br />
                        If your sheep eats for 6 hours on the Supergrass, <span className='font-bold'>you get {transformNumberWithCommas(reward)} FFWOOL</span>
                        <br />
                        <span className='font-bold'>Only the last</span> investor is earning -{">"} Only the last sheep put on the Supergrass is earning.
                        <br />
                        If somebody.... -{">"} If another sheep is placed on the Supergrass after yours, the new sheep will start to earn.
                        <br /> <br />
                        {winner && wallet && winner === wallet
                            ? !countdownFinished
                                ? <>
                                    YESS! <span className='font-bold'>Your sheep is eating</span> on the Supergrass!
                                    <br />
                                    If it stays there until the timer runs out <span className='font-bold'>you will get {transformNumberWithCommas(reward)} FFWOOL</span>
                                </>
                                :
                                <>
                                    YESS! You have <span className='font-bold'>won!</span>
                                    <br />
                                    Claim <span className='font-bold'>your {transformNumberWithCommas(reward)} FFWOOL</span>
                                </>
                            : winner &&
                            (!countdownFinished
                                ?
                                <>
                                    Currently the following address <span className='font-bold'>will win the {transformNumberWithCommas(reward)} FFWOOL</span>:
                                    <br />
                                    <span className='break-all'>{winner}</span>
                                </>
                                :
                                <>
                                    The game is over.
                                    <br />
                                    <span className='break-all'>{winner}</span> has <span className='font-bold'>won {transformNumberWithCommas(reward)} FFWOOL!</span>
                                </>)
                        }
                    </p>
                    <SuperGrassCountdown
                        period={period}
                        start={lastTs}
                        countdownFinished={countdownFinished}
                        setCountdownFinished={setCountdownFinished}
                        className={"mb-3"}
                    />
                    {!approved
                        ?
                        <WoodButton
                            title={"APPROVE"}
                            size={"small"}
                            loading={loading}
                            onClick={async () => await onApprove()}
                        />
                        :
                        !countdownFinished
                            ?
                            <WoodButton
                                title={`Place your sheep on the Supergrass for ${transformNumberWithCommas(ticketSize)} FFWOOL`}
                                size={"small"}
                                loading={loading}
                                onClick={async () => await onBuy()}
                                disabled={loading}
                            />
                            : winner !== wallet
                                ? <></>
                                : isClaimed
                                    ?
                                    <p className="text-red font-console text-center">
                                        You have already claimed your reward.
                                    </p>
                                    : lastTs?.toNumber() + claimPeriod?.toNumber() < (Date.now() / 1000)
                                        ?
                                        <p className="text-red font-console text-center">
                                            The time for you to claim your reward has expired. You can't claim it anymore.
                                        </p>
                                        :
                                        <WoodButton
                                            title={`You have won! Claim your ${transformNumberWithCommas(reward)} FFWOOL`}
                                            size={"small"}
                                            loading={loading}
                                            onClick={async () => await onClaim()}
                                            disabled={loading}
                                        />
                    }
                    <p className="text-red font-console text-center">{response || <><br /></>}</p>
                </EthereumInteraction>
                <LoadingModal
                    loadingScenes={[
                        {
                            message: "Processing...",
                            source: "/images/minting.gif",
                        },
                    ]}
                    modalIsOpen={!!transacting}
                />
                <OutcomeModal
                    outcomes={outcomes}
                    modalIsOpen={outcomes.length > 0}
                    closeModal={() => {
                        setOutcomes([]);
                    }}
                />
            </Container>
            <br />
            <div className="mt-5 mb-5 text-right">
                <a href="/tos" className="btn-frame btn-small">Terms of Service</a>
            </div>
        </div>
    )
}

export default SuperGrass;
