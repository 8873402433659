import gql from "graphql-tag";

export const QUERY_STAKED_OLD = gql`
  query fetch($wallet: String) {
    stakes(
      where: { owner: $wallet }
      first: 1000
      orderBy: id
      orderDirection: asc
    ) {
      id
      owner {
        id
      }
      token {
        number
        isSheep
        fur
        head
        ears
        eyes
        nose
        mouth
        neck
        feet
        alpha
        tokenURI
      }
      value
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const QUERY_GLOBAL = gql`
  query fetch($wallet: String) {
    stat(id: "global") {
      sheepMinted
      newSheepStaked
      wolvesMinted
      newWolvesStaked
      woolClaimed
      woolPerAlpha
      maxTokens
      lastClaimTimestamp
      woolStolen
      woolEarned
      totalSupply
      maxTokensWarchestCommon
      maxTokensWarchestEpic
      maxTokensWarchestLegendary
      warchestMintedCommon
      warchestMintedEpic
      warchestMintedLegendary
    }
    users(orderBy: claimedWool, orderDirection: desc, first: 10) {
      id
      claimedWool
      tokens {
        id
        isSheep
      }
      mintCount
      tokenCount
      newSheepStakedCount
      newWolvesStakedCount
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const QUERY = gql`
  query fetch($wallet: String, $first: Int, $skip: Int) {
    tokens(
      where: { owner: $wallet}
      orderBy: number
      orderDirection: asc
      first: $first
      skip: $skip
    ) {
      id
      owner {
        id
      }
      number
      isSheep
      fur
      head
      ears
      eyes
      nose
      mouth
      neck
      feet
      alpha
      tokenURI
    }
    user: users(where: {id: $wallet}) {
      id
      claimedWool
      mintCount
      tokenCount
      newSheepStakedCount
      newWolvesStakedCount
      warchestTokenCount
      warchestTokenCountCommon
      warchestTokenCountEpic
      warchestTokenCountLegendary
      warchestTokenCountOpen
      warchestTokenCountCommonOpen
      warchestTokenCountEpicOpen
      warchestTokenCountLegendaryOpen
      warSecretCount
      warchestTokens {
        id
        typeIndex
      }
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const QUERY_MOBILE = gql`
  query fetch($wallet: String, $first: Int, $skip: String) {
    tokens(
      where: { owner: $wallet, id_gt: $skip }
      orderBy: number
      orderDirection: asc
      first: $first
    ) {
      id
      owner {
        id
      }
      number
      isSheep
      fur
      head
      ears
      eyes
      nose
      mouth
      neck
      feet
      alpha
      tokenURI
    }
    user: users(where: {id: $wallet}) {
      id
      claimedWool
      mintCount
      tokenCount
      newSheepStakedCount
      newWolvesStakedCount
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const QUERY_STAKED = gql`
  query fetch($wallet: String) {
    newStakes(
      where: { owner: $wallet }
      first: 1000
      orderBy: id
      orderDirection: asc
    ) {
      id
      owner {
        id
      }
      token {
        number
        isSheep
        fur
        head
        ears
        eyes
        nose
        mouth
        neck
        feet
        alpha
        tokenURI
      }
      value
    }
    _meta {
      block {
        number
      }
    }
  }
`;


export const REST_QUERY_GLOBAL = gql`
  query fetch($wallet: String) {
    stat(id: "global") {
      restaurantsMinted
      restaurantMaxMint
      restaurantMaxTokens
      restaurantTotalSupply
    }
    users(orderBy: restaurantMintCount, orderDirection: desc, first: 10) {
      id
      restaurantTokens {
        id
      }
      restaurantMintCount
      restaurantTokenCount
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const REST_QUERY = gql`
  query fetch($wallet: String, $first: Int, $skip: Int) {
    restaurantTokens (
      where: { owner: $wallet }
      orderBy: number
      orderDirection: asc
      first: $first
      skip: $skip
    ) {
      id
      owner {
        id
      }
      number
      sky
      ground
      building
      roof
      brand
      accessory
      starIndex
      tokenURI
    }
    user: users(where: {id: $wallet}) {
      id
      restaurantMintCount
      restaurantTokenCount
      newRestaurantStakedCount
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const REST_QUERY_STAKED = gql`
  query fetch($wallet: String) {
    restaurantStakes(
      where: { owner: $wallet }
      orderBy: id
      orderDirection: asc
      first: 1000
    ) {
      id
      owner {
        id
      }
      token {
        number
        sky
        ground
        building
        roof
        brand
        accessory
        starIndex
        tokenURI
      }
    }
    _meta {
      block {
        number
      }
    }
  }
`;

export const REST_QUERY_STAKED_NEW = gql`
  query fetch($wallet: String, $first: Int, $skip: Int) {
    newRestaurantStakes(
      where: { owner: $wallet }
      orderBy: id
      orderDirection: asc
      first: $first
      skip: $skip
    ) {
      id
      owner {
        id
      }
      token {
        number
        sky
        ground
        building
        roof
        brand
        accessory
        starIndex
        tokenURI
      }
    }
    _meta {
      block {
        number
      }
    }
  }
`;

// * OG
// export const QUERY = gql`
//   query fetch($wallet: String) {
//     tokens(
//       where: { owner: $wallet }
//       orderBy: number
//       orderDirection: asc
//       first: 1000
//     ) {
//       id
//       owner {
//         id
//       }
//       number
//       isSheep
//       fur
//       head
//       ears
//       eyes
//       nose
//       mouth
//       neck
//       feet
//       alpha
//       tokenURI
//     }
//     stakes(
//       where: { owner: $wallet }
//       first: 1000
//       orderBy: id
//       orderDirection: asc
//     ) {
//       id
//       owner {
//         id
//       }
//       token {
//         number
//         isSheep
//         fur
//         head
//         ears
//         eyes
//         nose
//         mouth
//         neck
//         feet
//         alpha
//         tokenURI
//       }
//       value
//     }
//     stat(id: "global") {
//       sheepMinted
//       sheepStaked
//       wolvesMinted
//       wolvesStaked
//       woolClaimed
//       woolPerAlpha
//       maxTokens
//       woolEarned
//       lastClaimTimestamp
//       woolStolen
//       woolTaxed
//       sheepStolen
//       wolvesStolen
//     }
//     users(orderBy: claimedWool, orderDirection: desc, first: 10) {
//       id
//       claimedWool
//       tokens {
//         id
//         isSheep
//       }
//     }
//     _meta {
//       block {
//         number
//       }
//     }
//   }
// `;

export const parseGraphObject = (woolfOrStake, stake) => {
  if (woolfOrStake.value || stake) {
    return { ...woolfOrStake, ...woolfOrStake.token };
  }
  return woolfOrStake;
};
