import Container from "./Container";
import ImagePhaseTwo from '../assets/images/ffwg_p2_live.jpeg';

const PhaseTwo = () => {

  return (
    <Container>
      <div className="flex flex-col items-center font-pixel gap-4">
        <div className="text-xl sm:text-3xl font-bold uppercase text-center">Phase 2: The Passage</div>
        <p>Fast Food Wolf Game Phase 2 is now Live. Read all about it in our <a href="/whitepaper">Whitepaper</a>.</p>
        <div className="text-center mb-4">
            <a href="/whitepaper"><img  src={ImagePhaseTwo} alt="" /></a>
            <a href="/whitepaper" className="btn-frame btn-small">Whitepaper: Phase 2</a>
        </div>
      </div>
    </Container>
  );
};

export default PhaseTwo;
