import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import REST_ABI from "./abi/rest.abi";

function getContract() {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const signer = provider.getSigner();
    return new Contract(process.env.REACT_APP_REST, REST_ABI, signer);
}

export const isPublicMintEnabled = async () => {
    const contract = getContract();
    return await contract.isPublicMintEnabled();
}

export const isGenZeroClaim = async () => {
    const contract = getContract();
    return await contract.isGenZeroClaim();
}

export const approve = async () => {
    const contract = getContract();
    return await contract.setApprovalForAll(process.env.REACT_APP_BARN_V3, true);
};

export const isApproved = async (wallet) => {
    const contract = getContract();
    return await contract.isApprovedForAll(wallet, process.env.REACT_APP_BARN_V3);
};

// TODO: keep up to date with mint price
const MINT_PRICE = "50";

export const mint = async (tokens, paid) => {
    const provider = _getProvider();
    if (!provider) throw new Error("Unable to connect to wallet");

    const contract = getContract();
    let ethCost = utils.parseUnits(MINT_PRICE, "ether").mul(BigNumber.from(tokens));
    var gasEstimate;
    var estimateError;
    await contract.estimateGas.mint(tokens, {
        value: paid ? ethCost : BigNumber.from(0),
    }).then(
        result => gasEstimate = result,
        error => estimateError = error);
    if (estimateError) return estimateError;
    return await contract.mint(tokens, {
        gasLimit: gasEstimate.mul(BigNumber.from(12)).div(BigNumber.from(10)),
        gasPrice: await provider.getGasPrice() * 2,
        value: paid ? ethCost : BigNumber.from(0),
    });
};

export const claim = async () => {
    const contract = getContract();

    var gasEstimate;
    var estimateError;
    await contract.estimateGas.claimGenZeroMint({
        value: BigNumber.from(0),
    }).then(
        result => gasEstimate = result,
        error => estimateError = error);
    if (estimateError) return estimateError;
    return await contract.claimGenZeroMint({
        gasLimit: gasEstimate.mul(BigNumber.from(12)).div(BigNumber.from(10)),
        value: BigNumber.from(0),
    });
};

export const parseMint = (receipt) => {
    const rest = new utils.Interface(REST_ABI);

    const results = {};

    for (let x in receipt.logs) {
        try {
            const log = rest.parseLog(receipt.logs[x]);
            results[log.args.tokenId.toString()] = {
                tokenId: log.args.tokenId,
                recipient: log.args.to,
                stake: false,
            };
        } catch (e) { }
    }

    return results;
};

export const tokenURI = async (tokenId) => {
    const contract = getContract();
    return await contract.tokenURI(tokenId);
};

export const tokenTraits = async (tokenId) => {
    const contract = getContract();
    return await contract.getTokenTraits(tokenId);
};

export const parseTokenTraits = (tokenTraits, tokenURI) => {
    return {
        number: tokenTraits.number,
        sky: tokenTraits.sky,
        ground: tokenTraits.ground,
        building: tokenTraits.building,
        roof: tokenTraits.roof,
        brand: tokenTraits.brand,
        accessory: tokenTraits.accessory,
        starIndex: tokenTraits.starIndex,
        tokenURI,
    };
};
